import React from 'react';
import styles from './DriverCheckin.module.css';
import Button from '../CustomButtons/Button';
import appStyles from '../App.module.css';

export default function CheckinButton(props) {
    const { driver, bol, checkin, load, isCheckout } = props;
    const buttonText = isCheckout ? 'checkout' : 'checkin';
    const buttonClasses = `${appStyles['primary-button']} ${
        isCheckout ? styles.checkout : styles.checkin
    }`;
    let bolDisabled = (bol && bol.checkin !== null) || driver === null;
    let loadDisabled = (load && load.checkin !== null) || driver === null;

    if (isCheckout) {
        loadDisabled = (load && load.checkout !== null) || driver === null;
        bolDisabled = false;
    }

    return (
        <Button
            disabled={bolDisabled || loadDisabled}
            onClick={() => checkin()}
            className={buttonClasses}>
            {buttonText}
        </Button>
    );
}
