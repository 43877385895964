import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function BolsPreviewModal({
    modalOpen,
    pdfViewer,
    docTitle,
    closeModal,
}) {
    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={modalOpen}
                onClose={closeModal}
                aria-labelledby="max-width-dialog-title">
                <DialogTitle id="max-width-dialog-title">
                    {docTitle}
                </DialogTitle>
                <DialogContent>{pdfViewer}</DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
