import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import LocalShipping from '@material-ui/icons/LocalShipping';
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';

import GridContainer from '../../Grid/GridContainer.js';
import GridItem from '../../Grid/GridItem.js';
import CustomInput from '../../CustomInput/CustomInput.js';
import { loadBeingEdited } from '../../../actions/loads-actions';
class Step1 extends Component {
    state = {
        pUNum: '',
        pUNumState: '',
        dateTime: '',
        dateTimeState: '',
    };

    subscription1 = loadBeingEdited.subscribe({
        next: load => {
            this.change({ target: { value: load.pUNum } }, 'pUNum', 'pUNum', 3);
            this.setState({ PUNum: load.pUNum });
            this.change(moment(load.pUDate), 'dateTime', 'dateTime', 3);
        },
    });

    componentWillUnmount() {
        this.subscription1.unsubscribe();
    }

    sendState() {
        return this.state;
    }

    addShrink() {
        document
            .querySelector('label#dateTime-label')
            .classList.add('MuiInputLabel-shrink');
    }

    removeShrink() {
        document
            .querySelector('label#dateTime-label')
            .classList.remove('MuiInputLabel-shrink');
    }

    componentDidUpdate() {
        if (this.state.dateTime && this.state.dateTimeState === 'success') {
            this.addShrink();
        } else if (
            this.state.dateTime &&
            this.state.dateTimeState === 'error'
        ) {
            this.addShrink();
        }
    }

    verifyLengthOfString(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    validateDate = (event, stateName) => {
        if (typeof event !== 'object') {
            this.setState({ [stateName + 'State']: 'error' });
            return false;
        }
        if (event && event.isValid()) {
            const input = document.querySelector(
                'div#dateTime-wrapper div.rdt'
            );
            input.classList.add('makeStyles-underlineSuccess-49');
            input.classList.add('Mui-focused');
            this.addShrink();
            this.setPropStateSuccess(stateName);
            this.setState({ [stateName]: event });
        } else {
            this.setState({ [stateName + 'State']: 'error' });
        }
    };

    setPropStateSuccess(stateName) {
        this.setState({ [stateName + 'State']: 'success' });
    }

    setPropStateError(stateName) {
        this.setState({ [stateName + 'State']: 'error' });
    }

    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'pUNum':
                this.verifyLengthOfString(event.target.value, stateNameEqualTo)
                    ? this.setPropStateSuccess(stateName)
                    : this.setPropStateError(stateName);
                break;
            case 'dateTime':
                return this.validateDate(event, stateName);
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }

    isValidated() {
        if (
            this.state.pUNumState === 'success' &&
            this.state.dateTimeState === 'success'
        ) {
            return true;
        } else {
            if (this.state.pUNumState !== 'success') {
                this.setState({ pUNumState: 'error' });
            }
            if (this.state.dateTimeState !== 'success') {
                this.setState({ dateTimeState: 'error' });
            }
        }
        return false;
    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer justify={'center'}>
                <GridItem xs={12} sm={12}>
                    <h4 className={classes.infoText}>
                        Let's start with the basic information (with validation)
                    </h4>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <CustomInput
                        value={this.state.pUNum}
                        success={this.state.pUNumState === 'success'}
                        error={this.state.pUNumState === 'error'}
                        labelText={
                            <span>
                                P/U Number <small>(required)</small>
                            </span>
                        }
                        id={'pUNum'}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            onChange: event =>
                                this.change(event, 'pUNum', 'pUNum', 3),
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    className={classes.inputAdornment}>
                                    <LocalShipping
                                        className={classes.inputAdornmentIcon}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <CustomInput
                        value={this.state.dateTime}
                        labelText={
                            <span>
                                P/U Date & Time <small>(required)</small>
                            </span>
                        }
                        success={this.state.dateTimeState === 'success'}
                        error={this.state.dateTimeState === 'error'}
                        id={'dateTime'}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        onChange={moment => {
                            if (typeof moment !== 'object') {
                                return false;
                            }
                            this.change(moment, 'dateTime', 'dateTime', 3);
                        }}
                        inputProps={{
                            id: 'dateTime',
                            required: true,
                            onOpen: () => this.addShrink(),
                            onClose: e => {
                                this.change(e, 'dateTime', 'dateTime', 3);
                                if (!e) {
                                    this.removeShrink();
                                }
                            },
                        }}
                        datePicker={true}
                        passedClasses={classes}
                    />
                </GridItem>
            </GridContainer>
        );
    }
}

Step1.propTypes = {
    classes: PropTypes.object,
};

const style = {
    infoText: {
        fontWeight: '300',
        margin: '10px 0 30px',
        textAlign: 'center',
    },
    inputAdornmentIcon: {
        color: '#555',
    },
    inputAdornment: {
        position: 'relative',
    },
};
//Todo: make underline match lable color
const mapStateToProps = state => {
    return {
        completedTotal: state.loadsReducer.completedTotal,
    };
};
const styledStep1 = withStyles(style)(Step1);
export default connect(mapStateToProps)(styledStep1);
