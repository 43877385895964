import React, { Component } from 'react';
import { connect } from 'react-redux';
import PDF from 'react-pdf-js';

class ReadOnlyPDF extends Component {
    state = {
        display: 'block',
        numPages: null,
        pageNumber: 1,
        file: null,
        imagePreviewUrl: null,
    };

    _handleImageChange = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {};
        reader.readAsDataURL(file);
    };
    onDocumentComplete = numPages => {
        this.setState({ pageNumber: 1, numPages });
    };

    onPageComplete = pageNumber => {
        this.setState({ pageNumber });
    };
    handlePrevious = () => {
        this.setState({ pageNumber: this.state.pageNumber - 1 });
    };

    handleNext = () => {
        this.setState({ pageNumber: this.state.pageNumber + 1 });
    };
    renderPagination = (pageNumber, numPages) => {
        let previousButton = (
            <li className="previous">
                <span
                    className="blue-link"
                    onClick={() =>
                        this.setState({ pageNumber: this.state.pageNumber - 1 })
                    }>
                    <i className="fa fa-arrow-left"></i> Previous
                </span>
            </li>
        );
        if (this.state.pageNumber === 1) {
            previousButton = (
                <li className="previous disabled">
                    <span>
                        <i className="fa fa-arrow-left"></i> Previous
                    </span>
                </li>
            );
        }

        let nextButton = (
            <li className="next">
                <span
                    className="blue-link"
                    onClick={() =>
                        this.setState({ pageNumber: this.state.pageNumber + 1 })
                    }>
                    Next <i className="fa fa-arrow-right"></i>
                </span>
            </li>
        );

        if (this.state.pageNumber === numPages) {
            nextButton = (
                <li className="next disabled">
                    <span>
                        Next <i className="fa fa-arrow-right"></i>
                    </span>
                </li>
            );
        }
        return (
            <nav>
                <ul className="pager">
                    {previousButton}
                    {nextButton}
                </ul>
            </nav>
        );
    };

    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages });
    };

    render() {
        let pdf;
        let pagination = null;
        let pdfViewer;
        let x = this.props;
        if (x.thisDoc) {
            pdf = this.props.thisDoc.docs.imagePreviewUrl;
            pagination = this.renderPagination(
                this.state.numPages,
                this.state.numPages
            );
            pdfViewer = (
                <PDF
                    file={pdf}
                    onDocumentComplete={this.onDocumentComplete}
                    onPageComplete={this.onPageComplete}
                    page={this.state.pageNumber}
                />
            );
        } else {
            pdfViewer = <span>Please select a BOL from above</span>;
        }
        return (
            <div className="pdf-container">
                <h4
                    className="blue-link"
                    onClick={() => {
                        if (this.state.display === 'block') {
                            this.setState({ display: 'none' });
                        } else {
                            this.setState({ display: 'block' });
                        }
                    }}>
                    Hide/show BOL
                </h4>
                <div
                    className="pdf-box"
                    style={{ display: this.state.display }}>
                    {pdfViewer}
                    {pagination}
                    <p>
                        Page {this.state.pageNumber} of {this.state.numPages}
                    </p>
                </div>
            </div>
        );
    }
}

export const mapStateToProps = state => {
    return {
        img: state.oneReducer.img,
        img2: state.fiveReducer.selectLoadForEdit,
        BOLtoEdit: state.fiveReducer.BOLtoEdit,
    };
};
export default connect(mapStateToProps)(ReadOnlyPDF);
