import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './OSDView.module.css';

export default function OSDTable(props) {
    const { rowData, deleteRow, showDelete } = props;
    const [tableElements, setTableElements] = useState([]);
    useEffect(() => {
        setTableElements(
            rowData.map((row, i) => (
                <StyledTableRow key={`${row.sku}-${i}`}>
                    <StyledTableCell component={'th'} scope={'row'}>
                        {row.sku}
                    </StyledTableCell>
                    <StyledTableCell align={showDelete ? 'center' : 'right'}>
                        {row.qty}
                    </StyledTableCell>
                    {showDelete && (
                        <StyledTableCell
                            align={showDelete ? 'center' : 'right'}>
                            <DeleteIcon
                                className={styles.delete}
                                onClick={() => deleteRow(row)}
                            />
                        </StyledTableCell>
                    )}
                </StyledTableRow>
            ))
        );
    }, [rowData]); //todo: investigate the console warning for this

    const classes = useStyles();
    const containerClasses = containerStyles();
    return (
        <TableContainer classes={containerClasses} component={Paper}>
            <Table
                className={classes.table}
                size={'small'}
                aria-label={'customized table'}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Sku/Desc</StyledTableCell>
                        <StyledTableCell
                            align={showDelete ? 'center' : 'right'}>
                            Qty (cases)
                        </StyledTableCell>
                        {showDelete && (
                            <StyledTableCell
                                align={
                                    showDelete ? 'center' : 'right'
                                }></StyledTableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>{tableElements}</TableBody>
            </Table>
        </TableContainer>
    );
}

const useStyles = makeStyles({
    table: {
        minWidth: 200,
    },
});

const containerStyles = makeStyles({ root: { maxHeight: 200 } });

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#00365b',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
