import React from 'react';
import moment from 'moment';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';
import Datetime from 'react-datetime';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';

import DateRange from '@material-ui/icons/DateRange';

// @material-ui/core components
import InputAdornment from '@material-ui/core/InputAdornment';

import styles from './customInputStyle.js';
import customInputStyle from './CustomInput.module.css';

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
    const classes = useStyles();
    const {
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        error,
        white,
        inputRootCustomClasses,
        success,
        helperText,
        passedClasses,
        onChange,
        datePicker,
        value,
        disabled,
        removePadding,
    } = props;

    const labelClasses = classNames({
        [' ' + classes.labelRootError]: error,
        [' ' + classes.labelRootSuccess]: success && !error,
    });
    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true,
        [classes.whiteUnderline]: white,
    });
    const marginTop = classNames({
        [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
    });
    const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: white,
    });

    let formControlClasses;
    if (formControlProps !== undefined) {
        formControlClasses = classNames(
            formControlProps.className,
            classes.formControl
        );
    } else {
        formControlClasses = classes.formControl;
    }
    const helpTextClasses = classNames({
        [classes.labelRootError]: error,
        [classes.labelRootSuccess]: success && !error,
    });
    const newInputProps = {
        maxLength:
            inputProps && inputProps.maxLength
                ? inputProps.maxLength
                : undefined,
        minLength:
            inputProps && inputProps.minLength
                ? inputProps.minLength
                : undefined,
    };

    const yesterday = moment().subtract(1, 'day');
    const valid = current => {
        return current.isAfter(yesterday);
    };

    return (
        <FormControl {...formControlProps} className={formControlClasses}>
            {labelText !== undefined ? (
                <InputLabel
                    id={`${id}-label`}
                    className={`${classes.labelRoot} ${labelClasses}`}
                    htmlFor={id}
                    {...labelProps}>
                    {labelText}
                </InputLabel>
            ) : null}
            {!datePicker ? (
                <Input
                    value={value}
                    classes={{
                        input: inputClasses,
                        root: marginTop,
                        disabled: classes.disabled,
                        underline: underlineClasses,
                    }}
                    id={id}
                    {...inputProps}
                    inputProps={newInputProps}
                />
            ) : (
                <div id={`${id}-wrapper`}>
                    <Datetime
                        id={id}
                        name={'dateTime-delivery'}
                        value={value}
                        isValidDate={valid}
                        onChange={onChange}
                        className={
                            removePadding
                                ? `${inputClasses} ${customInputStyle['zero-padding']}`
                                : inputClasses
                        }
                        inputProps={{ disabled }}
                        key={'dateTime-delivery' + value?.getDate}
                        {...inputProps}
                    />
                    <InputAdornment
                        position="end"
                        className={`${passedClasses.inputAdornment} ${customInputStyle.endAdornment}`}>
                        <DateRange
                            className={passedClasses.inputAdornmentIcon}
                        />
                    </InputAdornment>
                </div>
            )}
            {helperText !== undefined ? (
                <FormHelperText id={id + '-text'} className={helpTextClasses}>
                    {helperText}
                </FormHelperText>
            ) : null}
        </FormControl>
    );
}

CustomInput.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    helperText: PropTypes.node,
};
