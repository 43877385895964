import {
    SHOW_SNACKBAR,
    LOADER_DISPLAY_CHANGE,
    LOGOUT_DISPLAY_CHANGE,
} from '../actions/shared-actions';

const initialState = {
    loaderDisplay: 'none',
    logoutDisplay: 'none',
    showSnackbar: { open: false, message: '', severity: 'success' },
};

export default function sharedActionReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOADER_DISPLAY_CHANGE:
            return { ...state, loaderDisplay: payload };
        case LOGOUT_DISPLAY_CHANGE:
            return { ...state, logoutDisplay: payload };
        case SHOW_SNACKBAR:
            return { ...state, showSnackbar: payload };
        default:
            return state;
    }
}
