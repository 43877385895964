import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import {togglePicModal} from '../../../actions/index16';

class DriverSignUp extends React.Component {
    
    state = {
		content:''
	}
	
    render(){
    
      return (
        <div className='outter-box'>
			<div className='instructions'>
                <p>Once inside you will see options to either:</p>
                <ol> 
                    <li> "Create a driver profile"</li>
					<li>"Create a carrier profile"</li>
                    <li>Create a shipper profile"</li>
                </ol>
                <p>Choose "Create driver profile"</p>
            </div>

            <div className='instructions'>
                <img src='https://connex-imgs.s3.amazonaws.com/connexapp-images/connex-dashboard.png' alt='driver profile' 
                    className='link'
                    onClick={() => {
                    this.props.dispatch(togglePicModal())
                    this.setState({content:<img src='https://connex-imgs.s3.amazonaws.com/connexapp-images/connex-dashboard.png' alt='create-shipper'/>})
                }}/>
            </div>

            <p className='instructions'>
                You will be asked to enter a few more details and then 
                to enter the ConnexCarrierID of the Carrier you would like to 
                join. Enter that, click submit and you're ready! You will then
                be added to that carrier's list of Connex Drivers 
            </p>
            
            <div className='instructions'>
                <img src='https://connex-imgs.s3.amazonaws.com/connexapp-images/create-driver-profile.png' alt='driver profile' 
                    className='link'
                    onClick={() => {
                    this.props.dispatch(togglePicModal())
                    this.setState({content:<img src='https://connex-imgs.s3.amazonaws.com/connexapp-images/create-driver-profile.png' alt='create-shipper'/>})
                }}/>
            </div>

            <div className='instructions'>
                  
                <Link to='/driversignup2'><p style={{marginLeft:0}}>Continue</p></Link>
               
            </div>
        </div>
      )
  }
}

export default connect()(DriverSignUp)