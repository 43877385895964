import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cropper from 'react-easy-crop';

import Button from '../../CustomButtons/Button';
import { imageChanged, updateUser } from '../../../actions/user-actions';
import { updateCompany } from '../../../actions/companies-actions';
import getCroppedImg from '../Tabs/Profile/cropImage';

import imageUploadStyles from './imageUploadStyles';
import { withStyles } from '@material-ui/core/styles';
import InitialState from './InitialState';
import styles from './ImageUpload.module.css';

class ImageUpload extends Component {
    state = {
        mainState: 'initial', // initial, search, gallery, uploaded
        imageUploaded: 0,
        selectedFile: null,
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 4 / 3,
        croppedAreaPixels: null,
        newImage: null,
    };

    resetState = () => {
        const element = document.getElementById('image-upload-wrapper');
        element.classList.remove(styles['add-height']);
        this.setState({
            mainState: 'initial', // initial, search, gallery, uploaded
            imageUploaded: 0,
            selectedFile: null,
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 4 / 3,
            croppedAreaPixels: null,
        });
    };

    onCropChange = crop => {
        this.setState({ crop });
    };

    onCropComplete = (_croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels });
    };

    onZoomChange = zoom => {
        this.setState({ zoom });
    };

    handleUploadClick = event => {
        const file = event.target.files[0];
        const reader = new FileReader();
        // * if you don't do the following selectedFile will be an Array Buffer
        reader.readAsDataURL(file);

        reader.onloadend = function () {
            this.setState({
                selectedFile: reader.result,
            });
        }.bind(this);

        this.setState({
            mainState: 'uploaded',
            selectedFile: event.target.files[0],
            imageUploaded: 1,
        });
    };

    saveImage = () => {
        const { selectedFile, croppedAreaPixels } = this.state;
        const { dispatch, self } = this.props;
        getCroppedImg(
            {
                imageSrc: selectedFile,
                pixelCrop: croppedAreaPixels,
            },
            true
        ).then(img => {
            if (window.location.pathname.includes('company')) {
                dispatch(
                    updateCompany({ id: self.companyId, update: { img } })
                );
            } else {
                dispatch(updateUser({ id: self._id, update: { img } }));
                imageChanged.next(img);
            }
            this.setState({
                newImage: img,
                mainState: 'initial',
                selectedFile: null,
                imageUploaded: 0,
            });
            this.resetState();
        });
    };

    render() {
        let cropperOrPicture;
        const {
            company,
            classes,
            self,
            onDirectory,
            userBeingViewed,
            hideUpload,
            driverImg,
        } = this.props;
        const { mainState, newImage } = this.state;
        const element = document.getElementById('image-upload-wrapper');
        const showDriverImg =
            window.location.pathname.includes('driver-checkin') ||
            window.location.pathname.includes('pickup-checkout');
        if (mainState === 'initial') {
            let src;
            if (window.location.pathname.includes('company-profile')) {
                src = company.img;
            } else if (showDriverImg) {
                src = driverImg;
            } else {
                src =
                    onDirectory && userBeingViewed
                        ? userBeingViewed.img
                        : newImage || self.img;
            }
            cropperOrPicture = (
                <InitialState
                    hideUpload={hideUpload || onDirectory}
                    classes={classes}
                    src={src}
                    onClick={this.handleUploadClick}
                />
            );
        } else if (mainState === 'uploaded' && !onDirectory) {
            element.classList.add(styles['add-height']);
            cropperOrPicture = (
                <Cropper
                    image={this.state.selectedFile}
                    crop={this.state.crop}
                    zoom={this.state.zoom}
                    aspect={this.state.aspect}
                    onCropChange={this.onCropChange}
                    onCropComplete={this.onCropComplete}
                    onZoomChange={this.onZoomChange}
                />
            );
        }
        return (
            <>
                <div
                    id={'image-upload-wrapper'}
                    className={`${classes.root} ${styles['upload-wrapper']}`}>
                    {cropperOrPicture}
                </div>
                <Button
                    children={'Save'}
                    onClick={this.saveImage}
                    className={`${styles.save} ${
                        mainState !== 'uploaded' && styles.hide
                    }`}
                />
                <Button
                    children={'Cancel'}
                    onClick={this.resetState}
                    color={'dribbble'} // * color posibilities: ["primary","info","success","warning","danger","rose","white","twitter","facebook","google","linkedin","pinterest","youtube","tumblr","github","behance","dribbble","reddit","transparent"]
                    className={`${mainState !== 'uploaded' && styles.hide}`}
                />
            </>
        );
    }
}

const styledImageUpload = withStyles(imageUploadStyles, { withTheme: true })(
    ImageUpload
);

const mapStateToProps = state => ({
    self: state.userReducer.user,
    userBeingViewed: state.userReducer.userBeingViewed,
    company: state.companiesReducer.companyWithAllData,
});
export default connect(mapStateToProps)(styledImageUpload);
