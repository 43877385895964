import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    updateModalContent,
    toggleModalDisplay,
} from '../../actions/user-actions';
import '../login-page.css';

export class ShipperSignUp4 extends Component {
    render() {
        const { dispatch } = this.props;
        return (
            <div className="outter-box">
                <div className="instructions">
                    <p>
                        Now that you have a created and assigned a load to a
                        driver, you can also assign it to another another
                        "shipper". Which in this case will be your receiving
                        customer. Navigate back to update BOL and you will see
                        an input for their Connex Shipper ID. Here you will
                        enter the ID that your customer provided you and now you
                        are all connected!
                    </p>
                </div>

                <div className="instructions">
                    <p>
                        As a creator of the Shipper Profile you are granted
                        Administrative privileges. This means that you are able
                        to add other users to your company's profile and assign
                        access to them as you like.
                    </p>
                    <p>
                        Once you are ready to begin click Start below to be
                        taken back to our welcome screen.
                    </p>
                </div>
                <div className="instructions">
                    <img
                        src="https://connex-imgs.s3.amazonaws.com/connexapp-images/shpr-add-shpr.png"
                        className="link"
                        alt="Receiver assignment"
                        onClick={() => {
                            dispatch(toggleModalDisplay());
                            this.props.dispatch(
                                updateModalContent(
                                    <img
                                        src="https://connex-imgs.s3.amazonaws.com/connexapp-images/shpr-add-shpr.png"
                                        alt="Receiver assignment"
                                    />
                                )
                            );
                        }}
                    />
                    <br />
                    <Link to="/shippersignup3">
                        <p>Back</p>
                    </Link>
                    <Link to="/#start-now">
                        <p>Start</p>
                    </Link>
                </div>
            </div>
        );
    }
}

export default connect()(ShipperSignUp4);
