export const initialState = {
    currentUser:null,
    username:null,
    password:null,
    confirmPassword:null,
    phone:null,
    firstName:null,
    lastName:null,
    errorMessage:'Passwords do not match.',
    validate:null,
    valid:false,
    value:null,
    errorVisible:false,
    error:null,
    user:null,
    enterPass:null,
    enterUS:null, 
    jwt:null,
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_PASSWORD':
            return Object.assign({}, state, {
                password:action.password
            })
        case 'SET_CONFIRM_PASSWORD':
            return Object.assign({}, state, {
                confirmPassword:action.confirmPass
            })
        case 'VALIDATE':
            return Object.assign({}, state, {
                validate:(action.val === state.password),
                confirmPassword:action.val
            })
        case 'VALUE':
            return Object.assign({}, state, {
                value:action.num
            })
        case 'VALID':
            return Object.assign({}, state,{
                valid:action.q,
                errorVisible:action.b
            })
        case 'SET_USERNAME':
            return Object.assign({}, state,{
                username:action.username
            })
        case 'FIRSTNAME':
            return Object.assign({}, state, {
                firstName:action.fName
            })
        case 'LASTNAME':
            return Object.assign({}, state, {
                lastName:action.lName
            })
        case 'PHONE':
            return Object.assign({}, state,{
                phone:action.phone
            })  
        case 'CREATE_USER_REQUEST':
            return Object.assign({}, state, {
                error:null
            })
        case 'CREATE_USER_SUCCESS':
            return Object.assign({}, state, {
                user:action.user
            })    
        case 'CREATE_USER_ERROR':
            return Object.assign({}, state, {
                error:action.error
            })

        case 'ENTER_PASS':
            return Object.assign({}, state, {
                enterPass:action.pass
            })
        case 'ENTER_NAME':
            return Object.assign({}, state, {
                enterUS:action.name
            })
        case 'UPDATE_USER':
            return Object.assign({}, state, {
                user:action.user
            })
        default:
            return state 
    }
}

export default reducer;