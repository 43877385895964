import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setUsername, phone } from '../../actions/index';
import { carrierName, createCarrier } from '../../actions/index2';
import { loaderDisplayChange } from '../../actions/shared-actions';
import '../welcome';

export class CreateCarrierPage extends Component {
    state = {};

    render() {
        let x = this.props;
        const { loginErrorDisplay } = this.props;
        let error;
        if ((x.errorMessage !== '') & (x.errorMessage !== undefined)) {
            error = x.errorMessage.response.text;
            console.log('LOOK ERROR', error);
        }

        return (
            <div className="create-carrier-form">
                <h3>Create Carrier Profile</h3>
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        x.dispatch(loaderDisplayChange('block'));
                        x.dispatch(
                            createCarrier(
                                x.user._id,
                                x.carrierName,
                                x.username,
                                x.phone,
                                x.jwt
                            )
                        );
                    }}>
                    <div className=" row">
                        <div className="col-25">
                            <label htmlFor="admin">Admin: </label>
                        </div>
                        <div className="col-75">
                            <span>
                                {this.props.user.firstName}{' '}
                                {this.props.user.lastName}{' '}
                            </span>
                            <span className="admin-userId">
                                {this.props.user.connexUserId}
                            </span>
                        </div>
                    </div>

                    <div className=" row">
                        <div className="col-25">
                            <label htmlFor="carrier-name">Carrier Name: </label>
                        </div>
                        <div className="col-75">
                            <input
                                id="carrier-name"
                                type="carrier-name"
                                placeholder="ABC Company"
                                onChange={e =>
                                    x.dispatch(carrierName(e.target.value))
                                }
                            />
                        </div>
                    </div>

                    <div className=" row">
                        <div className="col-25">
                            <label htmlFor="email">Email:</label>
                        </div>
                        <div className="col-75">
                            <input
                                id="email"
                                type="email"
                                placeholder={`${this.props.user.username}`}
                                onChange={e =>
                                    x.dispatch(setUsername(e.target.value))
                                }
                            />
                        </div>
                    </div>

                    <div className=" row">
                        <div className="col-25">
                            <label htmlFor="phone">Phone: </label>
                        </div>
                        <div className="col-75">
                            <input
                                id="phone"
                                type="tel"
                                placeholder={`${this.props.user.phone}`}
                                onChange={e =>
                                    x.dispatch(phone(e.target.value))
                                }
                            />
                        </div>
                    </div>
                    <button type="submit" className="cre-car-btn all-buttons">
                        Submit
                    </button>
                </form>
                <div className="err-msg">
                    <p style={{ display: loginErrorDisplay, color: 'red' }}>
                        {error}
                    </p>
                </div>
            </div>
        );
    }
}
export const mapStateToProps = state => {
    return {
        user: state.dataReducer.user,
        jwt: state.dataReducer.jwt,
        phone: state.dataReducer.phone,
        username: state.dataReducer.username,
        carrierName: state.oneReducer.carrierName,
        loginErrorDisplay: state.userReducer.loginErrorDisplay,
        errorMessage: state.sixReducer.errorMessage,
    };
};
export default connect(mapStateToProps)(CreateCarrierPage);
