import React from 'react';
import history from '../../history';

import { H2 } from '../helpers/Headings';

import styles from './BolListAndItem.module.css';
import appStyles from '.././App.module.css';

export default function BolItem({ bols }) {
    const hasBols = bols && bols.length;
    const list = hasBols
        ? bols.map((b, i) => <NewItem key={`item-${i}`} b={b} i={i} />)
        : [];

    return (
        <>
            <H2 className={styles['no-margin-bottom']} heading={'bol list'} />
            <hr className={appStyles.rule} />
            <div className={`${styles['bol-list-wrapper']}`}>{list}</div>
        </>
    );
}

function NewItem({ b, i }) {
    const route = e => {
        e.preventDefault();
        e.stopPropagation();
        history.push(`${window.location.pathname}/${b._id}`);
    };
    return (
        <p onClick={route} key={b.file.name} className={styles['bol-name']}>
            {`${i + 1}. ${b.file.name}`}
        </p>
    );
}
