import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import formatPhoneNumber from './helpers/formatPhoneNumber';
const useStyles = makeStyles({
    root: {
        maxWidth: 275,
        margin: '15px auto 0',
        backgroundColor: '#e0e0e0',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function CompanyDetailView({ companySelected }) {
    const classes = useStyles();
    const display = !!companySelected ? 'block' : 'none';

    return (
        <Card className={classes.root} style={{ display }}>
            {companySelected && (
                <CardContent>
                    <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom>
                        Company Profile
                    </Typography>
                    <Typography variant="h6" component="h2">
                        {companySelected.name}
                    </Typography>

                    <Typography variant="body2" component="p">
                        {`
                            ${companySelected.address.line1}
                            ${companySelected.address.line2}
                            ${companySelected.address.line3}
                            ${companySelected.address.city}, ${companySelected.address.state} ${companySelected.address.zip}
                        `}
                    </Typography>

                    <Typography variant="body2" component="p">
                        {`${
                            formatPhoneNumber(companySelected.phone)
                                ? formatPhoneNumber(companySelected.phone)
                                : ''
                        }`}
                    </Typography>
                </CardContent>
            )}
        </Card>
    );
}
