import React, { Component } from 'react';
import {connect} from 'react-redux'; 
import Form from './form';
import ImgProp from './imgProp';  
import {addImg} from '../../../actions/index2';
import defaultImg from './linkedin-default.png';
import './driver.css';
import '../../welcome.css';

export class DriverEditProfile extends Component{
    state = {
        img:null,
    }
    componentDidMount(){
    }
    render() {
        let x = this.props;
        let imagePreviewUrl;
        if (this.state.img) {
            imagePreviewUrl = this.state.img
            
        }
        else if (x.info2) {
            if (x.info2.driverLicensePic) {
                imagePreviewUrl = x.info2.driverLicensePic
            }
            
        }
        else{imagePreviewUrl = defaultImg}
        let $imagePreview = null;
        if (imagePreviewUrl) {
            console.log(imagePreviewUrl)
            $imagePreview = (<img alt='file to upload' className='driver-photo' src={imagePreviewUrl.imagePreviewUrl} />);
        }
        let moveImg2 = img => this.setState({img:img})
        return (
            <div className='add-edit-driver'>
                <h2>Edit Driver Profile</h2>
                <div className='driver-pic-div'>{$imagePreview}</div> 
                
                <ImgProp moveImg2={moveImg2} />
                <button className='remove-img-button all-buttons' onClick={() => {
                    console.log(this.state.img)
                    this.setState({img:null})
                    x.dispatch(addImg({}))}}>Remove Image</button>
                <Form /> 
                
            </div>
        )       
    }
}
export const mapStateToProps = state => {
    return {
        index:state.thirdReducer.index,
        img:state.oneReducer.img,
        info:state.thirdReducer.mySelectedCarrier,
        jwt:state.dataReducer.jwt,
        d_Id:state.thirdReducer.driverToAdd,
        info2:state.fourReducer.mySelectedDriver
    }
}
export default connect(mapStateToProps)(DriverEditProfile)