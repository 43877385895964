import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { updateBol } from '../../actions/bol-actions';

function ConfirmationDialogRaw(props) {
    const companyName = useSelector(
        state => state.companiesReducer.companyWithBasicData.name
    );
    const load = useSelector(state => state.loadsReducer.loadBeingEdited);
    const loadId = load && load.load && load.load._id;
    const dispatch = useDispatch();
    const iconStyles = useIconStyle();
    const { bolId, onClose, open, ...other } = props;
    const contentStyles = useContentStyle();

    const handleNo = () => {
        onClose();
    };

    const handleYes = () => {
        dispatch(
            updateBol(
                {
                    loadId,
                    companyName,
                    completed: true,
                    checkout: new Date(),
                },
                bolId
            )
        );
        onClose();
    };

    return (
        <Dialog
            maxWidth={'sm'}
            aria-labelledby={'confirmation-dialog-title'}
            open={open}
            {...other}>
            <DialogTitle id={'confirmation-dialog-title'}>
                Complete Load & Checkout
            </DialogTitle>
            <DialogContent classes={contentStyles}>
                <WarningRoundedIcon classes={iconStyles}></WarningRoundedIcon>
                <h4 style={{ margin: 0 }}>
                    <strong>
                        This action cannot be undone. Do you want to continue?
                    </strong>
                </h4>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleNo} color={'primary'}>
                    No
                </Button>
                <Button onClick={handleYes} color={'primary'}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};
const useIconStyle = makeStyles({ root: { color: 'red', marginRight: 10 } });
const useContentStyle = makeStyles({
    root: { display: 'flex', alignItems: 'center' },
});

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        width: '80%',
        maxHeight: 435,
    },
}));

export default function ConfirmDialog({ bolId, open, onClose }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <ConfirmationDialogRaw
                bolId={bolId}
                classes={{
                    paper: classes.paper,
                }}
                id={'complete-load-dialog'}
                open={open}
                onClose={onClose}
            />
        </div>
    );
}
