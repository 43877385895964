import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styles from './DriverCheckin.module.css';
import Checkbox from '@material-ui/core/Checkbox';
import CheckinCheckoutButton from './CheckinCheckoutButton';

export default class Load extends Component {
    state = {
        checked: false,
        disabled: false,
    };
    componentDidMount() {
        const { checked, bol, load, isCheckout } = this.props;
        if (isCheckout) {
            this.setState({
                disabled: load.checkout !== null,
            });
        } else {
            this.setState({
                checked,
                // * we may be using a bol/load
                disabled: (bol !== undefined ? bol : load).checkin !== null,
            });
        }
    }

    render() {
        const { id, showSeal, idDisplay } = this.props;
        const { checked, disabled } = this.state;
        const checkedVal =
            checked === undefined || checked === false ? false : true;
        return (
            <div className={styles['load-wrapper']}>
                <p>
                    <strong>ConnexId: </strong>
                    {idDisplay}
                </p>
                {showSeal && (
                    <FormControlLabel
                        id={id}
                        className={styles.label}
                        control={
                            <Checkbox
                                checked={checkedVal}
                                disabled={disabled}
                                onClick={() =>
                                    this.setState({ checked: !checked })
                                }
                                color={'primary'}
                            />
                        }
                        label={'seal intact'}
                        labelPlacement={'end'}
                    />
                )}

                <CheckinCheckoutButton {...this.props} />
            </div>
        );
    }
}
