import React, { Component } from 'react';
import {connect} from 'react-redux'; 
import ReadOnlyPDF from './readOnlyPDF';
import moment from 'moment';

export class BOLsInfo extends Component{
    state = {
        bill:null,
        bol:null
    }

    render() {
        let x = this.props;
        let bolOptions;
        if (x.driver ) {
            if (x.driver.currentLoad) {
                if (x.driver.currentLoad.BOLs.length > 0) {
                    bolOptions = x.driver.currentLoad.BOLs.map((bol,index) => {
                    return <option key={index} value={bol._id}
                    >{bol._id}, {bol._id.substring(19,24)}</option>
                    // <li key={index}><span className='link' onClick={() => {
                    //     console.log(bol)
                    //    this.setState({bill:bol}) }}>{bol._id}</span></li>
                    })  
                }
            }
        }
                
        let oneBill;
        let itemsForBill;
        let checkIn;
        let checkOut;
        let y = this.state;
        if (y.bill) {
            if (y.bill.items.length > 0) {
                itemsForBill = y.bill.items.map( (item,index) => <li key={index}>{item}</li>)
            }
            else{itemsForBill = <p>No OSD has been reported</p>}
            if (y.bill.checkIn) {
                checkIn = moment(y.bill.checkIn).format('MMMM Do YYYY, h:mm:ss a')
            }
            else{checkIn = 'n/a'}
            if (y.bill.checkOut) {
                checkOut = moment(y.bill.checkOut).format('MMMM Do YYYY, h:mm:ss a')
            }
            else{checkOut = 'n/a'}
            oneBill = 
                <div className='one-Bill'>
                    <ul>
                        <li>Status: {y.bill.status}</li>
                        <li>Receiver: {y.bill.receivedBy}</li>
                        <li>Check-In: {checkIn}</li>
                        <li>Check-Out: {checkOut}</li>
                    </ul>
                </div>
        }
        
        return (
            <div className='bol-box'>

                <h4>BOLs</h4>

                <input id='bol-input' type='text' list='bol-options' onChange={(e) => {
                    this.setState({bol:e.target.value.trim()})
                    // console.log('look bol' ,e.target.value)
                    x.driver.currentLoad.BOLs.find( bol => {
                        // if(bol._id === e.target.value) {    I REMOVED THIS BECAUSE IT WAS CAUSING A
                        //     console.log( bol)               WARNING IN THE CONSOLE  
                            
                        //     return this.setState({bill:bol})
                        // } 
                        return bol._id === e.target.value ? this.setState({bill:bol}) : null
                    })
                }}/>

                <datalist id='bol-options' > 
                    {bolOptions}
                </datalist>

                {/* <ul>
                    {bols}
                </ul> */}

                {oneBill} 

                < ReadOnlyPDF thisDoc={this.state.bill}/>

                <h5>OSD</h5>

                <ul>
                    {itemsForBill}
                </ul>
            </div>
        )        
    }
} 

export const mapStateToProps = state => {
    return {
        jwt:state.dataReducer.jwt,
        driver:state.fourReducer.mySelectedDriver
    }
}
export default connect(mapStateToProps)(BOLsInfo)
