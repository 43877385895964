import React from 'react';

import DateAndTime from './DateAndTime';

import { getOnTimeMessage, getLoadPickupData, formatDate } from './infoHelpers';

import styles from './InfoComponents.module.css';

export default function PickUpInfo(props) {
    const { load } = props;
    const { onTime, late, checkin, checkout } = getLoadPickupData(load);
    const pUDate = formatDate(load.pUDate);
    //TODO: Add detention, should be calculated on backend
    return (
        <>
            <DateAndTime text={'Pick-Up Date -- Time'} time={pUDate} />
            <p className={onTime ? styles['on-time'] : styles.late}>
                {getOnTimeMessage(onTime, late)}
                Pick-Up
            </p>
            <DateAndTime text={'Pick-Up Check-In'} time={checkin} />
            <DateAndTime text={'Pick-Up Check-Out'} time={checkout} />
        </>
    );
}
