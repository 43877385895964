import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '../CustomButtons/Button';
import CustomInput from '../CustomInput/CustomInput.js';

import { passwordRegEx } from '../helpers/regEx';
import { changePassword } from '../../actions/user-actions';
import styles from './ChangePassword.module.css';

export default function ChangePassword(props) {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');

    const { toggleChangePassword, userId: id } = props;
    const dispatch = useDispatch();
    const handleSubmit = () => {
        dispatch(
            changePassword({
                id,
                resetWithToken: true,
                newPassword,
                confirmedPassword,
            })
        );
        toggleChangePassword();
    };
    const isResetPassword = window.location.pathname.includes('reset');
    return (
        <>
            {!isResetPassword && (
                <CustomInput
                    value={oldPassword}
                    formControlProps={{
                        fullWidth: true,
                    }}
                    labelText={'old password'}
                    id={'oldPassword-input'}
                    inputProps={{
                        type: 'password',
                        onChange: e => setOldPassword(e.target.value.trim()),
                    }}
                />
            )}
            <CustomInput
                value={newPassword}
                formControlProps={{
                    fullWidth: true,
                }}
                labelText={'new password'}
                id={'newPassword-input'}
                error={newPassword !== '' && !newPassword.match(passwordRegEx)}
                success={newPassword.match(passwordRegEx)}
                inputProps={{
                    type: 'password',
                    onChange: e => setNewPassword(e.target.value.trim()),
                }}
            />
            <CustomInput
                value={confirmedPassword}
                formControlProps={{
                    fullWidth: true,
                }}
                labelText={'confirm password'}
                id={'confirmedPassword-input'}
                error={newPassword !== confirmedPassword}
                success={
                    newPassword === confirmedPassword &&
                    confirmedPassword !== ''
                }
                inputProps={{
                    type: 'password',
                    onChange: e => setConfirmedPassword(e.target.value.trim()),
                }}
            />
            <Button
                disabled={
                    (newPassword !== '' && !newPassword.match(passwordRegEx)) ||
                    confirmedPassword === '' ||
                    newPassword !== confirmedPassword
                }
                children={'change password'}
                onClick={handleSubmit}
                className={styles['submit-button']}
            />
        </>
    );
}
