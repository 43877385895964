import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';
import AddBOLs from '../../PDFComponents/AddBols';

import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';

import customSelectStyle from '../../customSelectStyle';
import customCheckboxRadioSwitch from '../../customCheckboxRadioSwitch';
import BolList from './BolList';
import CompaniesAutocmpleteInput from '../../AutocompleteInput/CompaniesAutocmpleteInput';
import CompanyDetailView from '../../CompanyDetailView';
import SubmitOneBolButton from './SubmitOneBolButton';

import stepsStyles from './Steps.module.css';
import { viewUploadedBol } from '../../../actions/bol-actions';
import {
    loadBeingEdited,
    setBolsAndCarrierSelected,
} from '../../../actions/loads-actions';
import CustomInput from '../../CustomInput/CustomInput.js';
import { getShprRcvrCrrsByIds } from '../../../actions/companies-actions';

class Step2 extends Component {
    state = {
        simpleSelect: '',
        desgin: false,
        code: false,
        develop: false,
        bols: [],
        currentBolDropDateTime: null,
        currentBolCompanyPicked: false,
        currentBolAddedDoc: false,
        currentBolName: '',
        currentCompanyPickedId: '',
        selectedCompanies: [],
    };

    sendState() {
        return this.state;
    }

    //todo: rewrite this to just be a callback
    //todo: not a real subscription
    subscription = {
        next: (val, stop) => {
            if (stop) return;

            const picked = val != null;
            const selectedCompanies = this.state.selectedCompanies;
            const companyNotFound = !selectedCompanies.find(
                c => c._id === (val && val._id)
            );

            this.setState(
                {
                    currentBolCompanyPicked: picked,
                    currentCompanyPickedId: picked ? val._id : '',
                    selectedCompanies:
                        picked && companyNotFound
                            ? [...selectedCompanies, val]
                            : selectedCompanies,
                },
                () => {
                    // * here we are updating the companyId on the corresponding
                    // * bol if we have a bol that has been uploaded
                    const { bols, currentBolName } = this.state;
                    const hasBols = bols.length > 0;
                    const hasName = currentBolName !== '';
                    if (hasBols && hasName) {
                        const updatedBolList = bols.map(b => {
                            if (b.file.name === currentBolName) {
                                if (picked) {
                                    b.receiverId = val._id;
                                } else {
                                    b.receiverId = null;
                                }
                            }
                            return b;
                        });
                        this.setState({
                            bols: updatedBolList,
                        });
                    }
                }
            );
        },
    };

    subscription2 = viewUploadedBol.subscribe({
        next: b => {
            if (!b.file) return;
            this.setState({
                currentBolName: b.file.name,
                currentBolAddedDoc: true,
                currentBolCompanyPicked: b.receiverId ? true : false,
                currentCompanyPickedId: b.receiverId ? b.receiverId : '',
                currentBolDropDateTime: moment(b.deliveryDate),
            });

            this.addShirnk();
        },
    });

    subscription3 = loadBeingEdited.subscribe({
        next: async load => {
            const bols = Array.isArray(load.bols) ? load.bols : [];
            this.setState({ bols: [...bols] });
            this.props.dispatch(setBolsAndCarrierSelected(bols));
            const params = bols.map(b => b.receiverId);
            if (params.length === 0) {
                this.setState({ selectedCompanies: [] });
                return;
            }
            await getShprRcvrCrrsByIds(params).then(list =>
                this.setState({ selectedCompanies: [...list] })
            );
        },
    });

    removeBol = name => {
        document.querySelector('#dateTime-delivery-wrapper div input').value =
            '';
        const listWithoutBol = this.state.bols.filter(
            b => b.file.name !== name
        );
        this.props.dispatch(setBolsAndCarrierSelected(listWithoutBol));
        this.setState({
            bols: listWithoutBol,
            currentBolAddedDoc: false,
            currentBolName: '',
            currentBolCompanyPicked: false,
            currentCompanyPickedId: '',
            currentBolDropDateTime: null,
        });
        this.removeShirnk();
    };

    addBol = bol => {
        const { currentCompanyPickedId, bols } = this.state;
        const updatedBol = {
            shipperId: this.props.shipper._id,
            receiverId: currentCompanyPickedId ? currentCompanyPickedId : null,
            ...bol,
        };
        this.setState({
            bols: [...bols, updatedBol],
            currentBolAddedDoc: true,
            currentBolName: updatedBol.file.name,
        });
    };

    handleSubmitOneBol = () => {
        //todo: figure out what to do once we have all the information
        //todo: that we need.

        //todo: reset the currentBol... props
        this.props.dispatch(setBolsAndCarrierSelected(this.state.bols));
        this.setState({
            currentBolCompanyPicked: false,
            currentBolAddedDoc: false,
            currentBolName: '',
            currentCompanyPickedId: '',
        });
    };

    isValidated() {
        return true;
    }

    componentWillUnmount() {
        this.subscription2.unsubscribe();
        this.subscription3.unsubscribe();
    }

    addShirnk() {
        document
            .querySelector('label#dateTime-delivery-label')
            .classList.add('MuiInputLabel-shrink');
    }

    removeShirnk() {
        document
            .querySelector('label#dateTime-delivery-label')
            .classList.remove('MuiInputLabel-shrink');
    }

    changeDeliveryDate(date) {
        let index;
        this.state.bols.forEach((b, i) => {
            if (b.file.name === this.state.currentBolName) {
                index = i;
            }
        });
        const bol = index !== undefined && this.state.bols[index];
        if (bol) {
            bol.dropDate = date.toISOString();
            const stateCopy = [...this.state.bols];
            stateCopy[index] = bol;
            this.setState({ bols: stateCopy, currentBolDropDateTime: date });
        }
    }

    closeBol = () =>
        this.setState({
            currentBolCompanyPicked: false,
            currentBolAddedDoc: false,
            currentBolName: '',
            currentCompanyPickedId: '',
        });

    render() {
        const { classes } = this.props;
        const {
            bols,
            currentBolCompanyPicked,
            currentBolAddedDoc,
            currentBolName,
            currentCompanyPickedId,
            currentBolDropDateTime,
        } = this.state;

        const addAnother =
            bols.length &&
            !currentBolCompanyPicked &&
            !currentBolAddedDoc &&
            !currentBolName &&
            !currentCompanyPickedId;

        const disabled = !(
            currentBolAddedDoc &&
            currentBolCompanyPicked &&
            currentBolDropDateTime
        );

        return (
            <div>
                <h4 className={classes.infoText}>Add the associated BOLs</h4>
                <GridContainer justify={'center'}>
                    <GridItem xs={12}>
                        <GridContainer justify={'center'}>
                            <GridItem
                                className={stepsStyles['margin-20']}
                                xs={12}
                                md={8}
                                lg={3}>
                                <div className={`${classes.choice} `}>
                                    <AddBOLs
                                        numOfBols={bols.length}
                                        addBol={this.addBol}
                                        addAnother={addAnother}
                                        closeBol={this.closeBol}
                                    />
                                </div>
                            </GridItem>
                            <GridItem
                                className={stepsStyles['margin-20']}
                                xs={12}
                                md={8}
                                lg={3}>
                                <CompaniesAutocmpleteInput
                                    type={1}
                                    onCompanySelected={this.subscription}
                                    disabled={!this.state.currentBolAddedDoc}
                                />
                                <CompanyDetailView
                                    companySelected={this.state.selectedCompanies.find(
                                        c =>
                                            c._id ===
                                            this.state.currentCompanyPickedId
                                    )}
                                />
                            </GridItem>

                            <GridItem
                                className={stepsStyles['margin-20']}
                                xs={12}
                                md={8}
                                lg={3}>
                                <div>
                                    Add Delivery Date:{' '}
                                    <strong>{this.state.currentBolName}</strong>
                                </div>
                                <CustomInput
                                    removePadding={true}
                                    value={currentBolDropDateTime}
                                    labelText={
                                        <span>
                                            Delivery Date & Time{' '}
                                            <small>(required)</small>
                                        </span>
                                    }
                                    success={
                                        this.state.dateTimeState === 'success'
                                    }
                                    error={this.state.dateTimeState === 'error'}
                                    id={'dateTime-delivery'} // * "-label" will be added to this id in ui
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    onChange={moment => {
                                        if (typeof moment !== 'object') {
                                            return false;
                                        }
                                        this.changeDeliveryDate(moment);
                                    }}
                                    disabled={!this.state.currentBolAddedDoc}
                                    inputProps={{
                                        id: 'dateTime-delivery', // * "-wrapper" will be added to this id in component
                                        onOpen: () => this.addShirnk(),
                                        onClose: e => {
                                            if (!e) {
                                                this.removeShirnk();
                                            }
                                        },
                                    }}
                                    datePicker={true}
                                    passedClasses={classes}
                                />
                            </GridItem>
                            <GridItem
                                className={stepsStyles['margin-20']}
                                xs={12}
                                md={8}
                                lg={3}>
                                <BolList
                                    title={'BOL List'}
                                    classes={classes.choice}
                                    bols={bols}
                                    removeBol={this.removeBol}
                                />
                            </GridItem>
                            <GridItem
                                xs={12}
                                className={`${stepsStyles['submit-one-bol']} ${stepsStyles['text-center']}`}>
                                <SubmitOneBolButton
                                    handleSubmitOneBol={this.handleSubmitOneBol}
                                    disabled={disabled}
                                />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const style = {
    infoText: {
        fontWeight: '300',
        margin: '10px 0 30px',
        textAlign: 'center',
    },
    inputAdornmentIcon: {
        color: '#555',
    },
    choice: {
        textAlign: 'center',
        cursor: 'pointer',
        marginTop: '20px',
    },
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
};

Step2.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        shipper: state.companiesReducer.companyWithBasicData,
    };
};

const styledStep2 = withStyles(style)(Step2);
export default connect(mapStateToProps)(styledStep2);
