import React from 'react';
import BecomeMemberForm from './becomeMemberForm'

export default class BecomeAMember extends React.Component {
    
   

  render(){
    
      return (
        <div className='outter-box'>
           <BecomeMemberForm />
        </div>
      )
  }
}
