import React, { Component } from 'react';
import { connect } from 'react-redux';

import GridContainer from '../Grid/GridContainer';
import style from './Dashboard.module.css';
import createWidgets, { defaultIconProps } from './DashboardWidgets/widgets';
import ReactTable from '../ReactTable/ReactTable';
import GridItem from '../Grid/GridItem';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardIcon from '../Card/CardIcon';
import CardHeader from '../Card/CardHeader';
import useStyles from './useStyles';
import createTableRows from './createTableRows';
import getTableColumns from '../ReactTable/getTableColumns';
import {
    getAllLoads,
    paginatedLoads,
    getCarrierLoads,
    getDriverLoads,
} from '../../actions/loads-actions';
import { loaderDisplayChange } from '../../actions/shared-actions';
import { Subject } from 'rxjs';

export const tableChanged = new Subject();
class Dashboard extends Component {
    state = {
        data: [],
        iconProps: {},
    };
    // * this was brought in when I was having trouble
    // * getting the ReactTable component to update
    // * as it should have on the first click.
    // * you might ask why not just pass the loads
    // * through the subject...well I want the redux store
    // * to be the source of truth. Hence, we grab from there.
    subscription = paginatedLoads.subscribe({
        next: l => {
            this.setState({ data: createTableRows(this.props[l]) });
            this.props.dispatch(loaderDisplayChange('none'));
        },
    });

    componentDidMount() {
        const {
            driverView,
            carrierView,
            dispatch,
            companyId,
            user,
        } = this.props;
        if (driverView) {
            this.setState({
                iconProps: defaultIconProps({ driverView: true }),
            });
            dispatch(getDriverLoads(user._id));
        } else if (carrierView) {
            this.setState({
                iconProps: defaultIconProps({ carrierView: true }),
            });
            dispatch(getCarrierLoads(companyId));
        } else {
            dispatch(getAllLoads());
            this.setState({ iconProps: defaultIconProps({}) });
        }
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    updateState = tableName => {
        this.setState({
            data: createTableRows(this.props[tableName]),
        });
    };

    render() {
        const { iconProps, data } = this.state;
        const { carrierView, driverView } = this.props;
        const changeTable = info => {
            tableChanged.next();
            this.setState({ data: createTableRows(info.loads) });
            this.setState({
                iconProps: {
                    icon: info.icon,
                    color: info.color,
                    background: info.background,
                    category: info.category,
                },
            });
        };

        const DashboardWidgets = createWidgets(
            changeTable,
            this.props,
            this.updateState
        );

        const classes = useStyles(this);
        //todo: fix why the rows aren't being rendered on the...
        //todo: first render. you have to press a widget
        return (
            <div className={style['dashboard-wrapper']}>
                <div className={style['widget-container']}>
                    {DashboardWidgets}
                </div>
                {/* 
                    <div>
                        //Todo: create a search box
                        //todo: search box should allow you to
                        //todo: search for loads based on
                        //todo: loadId, company Name, and pUNumber
                    </div> 
                */}
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader
                                color={iconProps.color || 'primary'}
                                icon>
                                <CardIcon
                                    color={iconProps.color}
                                    style={iconProps.background}>
                                    {iconProps.icon}
                                </CardIcon>
                                <h4
                                    className={`${classes.cardIconTitle} ${style['no-underline']}`}
                                    style={{ color: '#999' }}>
                                    {iconProps.category}
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <ReactTable
                                    tableName={iconProps.category}
                                    columns={getTableColumns()}
                                    data={data}
                                    pageCount={(data && data.length) || 0}
                                    carrierView={carrierView || driverView}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        inbound: state.loadsReducer.inbound,
        user: state.userReducer.user,
        outbound: state.loadsReducer.outbound,
        unsubmitted: state.loadsReducer.unsubmitted,
        completed: state.loadsReducer.completed,
        inboundTotal: state.loadsReducer.inboundTotal,
        outboundTotal: state.loadsReducer.outboundTotal,
        unsubmittedTotal: state.loadsReducer.unsubmittedTotal,
        completedTotal: state.loadsReducer.completedTotal,
        companyId: state.companiesReducer.companyWithBasicData._id,
    };
};
export default connect(mapStateToProps)(Dashboard);
