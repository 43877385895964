import request from 'superagent';

import { findMyDriverAccounts } from './index3';

export const OPTIONS_OF_SHIPPERS = 'OPTIONS_OF_SHIPPERS';
export const optionsOfShippers = options => ({
    type: OPTIONS_OF_SHIPPERS,
    options,
});

export const OPTIONS_OF_CARRIERS = 'OPTIONS_OF_CARRIERS';
export const optionsOfCarriers = options => ({
    type: OPTIONS_OF_CARRIERS,
    options,
});

export const getShippersByLastSix = jwt => dispatch => {
    request
        .get(`/api/connex/getShippersByLastSix`)

        .then(res => {
            if (res.ok) {
                dispatch(optionsOfShippers(res.body));
            } else {
            }
        })
        .catch(err => console.log(err));
};

export const getCarrierByLastSix = jwt => dispatch => {
    request
        .get(`/api/connex/getCarrierByLastSix`)

        .then(res => {
            if (res.ok) {
                dispatch(optionsOfCarriers(res.body));
            }
        })
        .catch(err => console.log(err));
};

export const deleteDvrFromCarrier = (
    jwt,
    carrierId,
    driverId,
    dvrArr,
    user
) => dispatch => {
    let obj;
    let index = dvrArr.indexOf(driverId);
    if (index === -1) {
        alert('load not found');
        return;
    }
    dvrArr.splice(index, 1);
    obj = { drivers: dvrArr };
    return new Promise((resolve, reject) => {
        return request
            .put(`/api/connex/deleteDriverFromCarrier/${carrierId}`)

            .set('Accept', 'application/json')
            .set('Content-type', 'application/json')
            .send(JSON.stringify(obj))
            .then(res => {
                if (res.ok) {
                    resolve();
                } else {
                    reject(res);
                }
            })
            .then(res => {
                if (res) {
                    console.log(`Response from server: ${res}`);
                } else {
                    console.log("Lookin' good! 👨🏾‍");
                }
            })
            .catch(err => {
                console.log(err);
            });
    });
};

const deleteFromDriverCollection = (driverId, user, jwt) => dispatch => {
    return new Promise((resolve, reject) => {
        return request
            .delete(`/api/connex/deleteDriverFromDriversCollection/${driverId}`)

            .set('Accept', 'application/json')
            .set('Content-type', 'application/json')
            .then(res => {
                if (res.status === 204) {
                    resolve();
                } else {
                    reject(res);
                }
            })
            .then(res => {
                if (res) {
                    console.log(`Response from server: ${res}`);
                } else {
                    console.log("Lookin' good! 👨🏾‍");
                }
            })
            .catch(err => {
                console.log(err);
            });
    });
};

export const deleteDriverFromCarrier = (
    jwt,
    carrierId,
    driverId,
    dvrArr,
    user
) => dispatch => {
    Promise.all([
        dispatch(deleteDvrFromCarrier(jwt, carrierId, driverId, dvrArr, user)),
        dispatch(deleteFromDriverCollection(driverId, user, jwt)),
    ])
        .then(() => {
            return Promise.all([dispatch(findMyDriverAccounts(user, jwt))])
                .then(() => {
                    return (window.location = `/home/${user}`);
                })
                .catch(err => {
                    console.error(err);
                });
        })
        .catch(err => {
            console.error(err);
        });
};
