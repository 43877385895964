import React, { Component } from 'react';
import {connect} from 'react-redux'; 
import PasswordBoxes from '../../becomeMember/password-boxes'
import {updateDriverAndUserProfiles} from '../../../actions/index11';
import './driver.css';
import '../../welcome.css';

export class Form extends Component{
    state = {
        companyId:null,
        driverLicense:null,
        truckNum:null,
        trlNum:null,
        driverLicensePic:null,
        phone:null,
        firstName:null,
        lastName:null,
        username:null,
    }

    _handleChange(e) {
        const {name,value} = e.target;
        this.setState({[name]:value})
    }
    render() {
        let x = this.props;
        let y = this.state;
        let companyId = y.companyId ? y.companyId : x.info2.carrierId._id;
        let driverLicense = y.driverLicense ? y.driverLicense : x.info2.driverLicense;
        let truckNum = y.truckNum ? y.truckNum : x.info2.truckNum;
        let trlNum = y.trlNum ? y.trlNum : x.info2.trlNum;
        let driverLicensePic = Object.keys(x.img).length === 0 ? x.info2.driverLicensePic  : x.img;
        let phone = y.phone ? y.phone : x.info2.userId.phone;
        let firstName = y.firstName ? y.firstName : x.info2.userId.firstName;
        let lastName = y.lastName ? y.lastName : x.info2.userId.lastName;
        let username = y.username ? y.username : x.info2.userId.username;
        let options
        if (x.carriers) {
            options = x.carriers.map((option,index) => <option key={index} value={option.carrId}
                >{option.name}, {option.carrId.substring(19,24)}</option>)
        }
        
        return (
            <div className='form-box'>
                <form className="create-driver-form" onSubmit={e => {
                    e.preventDefault()
                    x.dispatch(updateDriverAndUserProfiles(x.info2._id,x.info2.userId._id,
                    x.jwt,phone,lastName,firstName,username,
                    companyId,driverLicense,truckNum,trlNum,
                    driverLicensePic,companyId))
                }}>
                    <div className="row">
                        <div className='col-25'>
                            <label htmlFor="phone">Phone: </label>
                        </div>
                        <div className='col-75'>
                            <input id="phone" name='phone' type="text" defaultValue={x.info2.userId.phone}
                            onChange={(e) => this._handleChange(e)}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-25'>
                            <label htmlFor="email">Email: </label>
                        </div>
                        <div className='col-75'>
                            <input id="email" name='username' type="text" defaultValue={x.info2.userId.username}
                            onChange={(e) =>  this._handleChange(e)}/>
                        </div>
                    </div>  
 
                    <div className="row">
                        <div className='col-25'>
                            <label htmlFor="company-id">Carrier/Company Id: </label>
                        </div>
                        <div className='col-75'>
                            <input id="company-id" name='companyId' type="text" list='options' placeholder={x.info2.carrierId._id}
                            onChange={(e) =>  this._handleChange(e)}/>
                             <datalist id='options' > 
                                    {options}
                                </datalist>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-25'>
                            <label htmlFor="drivers-license">Driver's License: </label>
                        </div>
                        <div className='col-75'>
                            <input id="drivers-license" name='license' type="text" defaultValue={x.info2.driverLicense}
                            onChange={(e) =>  this._handleChange(e)}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-25'>
                            <label htmlFor="first-name">First Name: </label>
                        </div>
                        <div className='col-75'>
                            <input id="first-name" name='firstName' type="text" defaultValue={x.info2.userId.firstName}
                            onChange={(e) =>  this._handleChange(e)}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-25'>
                            <label htmlFor="last-name">Last Name: </label>
                        </div>
                        <div className='col-75'>
                            <input id="last-name" name='lastName' type="text" defaultValue={x.info2.userId.lastName}
                            onChange={(e) =>  this._handleChange(e)}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-25'>
                            <label htmlFor="truck-num">Truck Num: </label>
                        </div>
                        <div className='col-75'>
                            <input id="truck-num" name='truckNum' type="text" defaultValue={x.info2.truckNum}
                            onChange={(e) =>  this._handleChange(e)}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-25'>
                            <label htmlFor="trl-num">Trailer Num: </label>
                        </div>
                        <div className='col-75'>
                            <input id="trl-num" name='trlNum' type="text" defaultValue={x.info2.trlNum}
                            onChange={(e) =>  this._handleChange(e)}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-25'>
                            <label htmlFor='chg-pswd'>Change Password:</label>
                        </div>
                        <div className='col-75'>
                            <PasswordBoxes />
                        </div>
                    </div>
                    
                    <div className="submit-btn-box">
                        <button type="submit" className="all-buttons">Submit</button>
                    </div>
                </form>  
            </div>
        )        
    }
} 

export const mapStateToProps = state => {
    return {
        img:state.oneReducer.img,
        jwt:state.dataReducer.jwt,
        info2:state.fourReducer.mySelectedDriver,
        info:state.dataReducer,
        carriers:state.sixReducer.carrierOptions
    }
}
export default connect(mapStateToProps)(Form)


