import request from 'superagent';
import { Subject } from 'rxjs';
import history from '../history';
import { loginSubject } from './user-actions';

export const LOADER_DISPLAY_CHANGE = 'loader/displayChange';
export const loaderDisplayChange = display => ({
    type: LOADER_DISPLAY_CHANGE,
    payload: display,
});

export const LOGOUT_DISPLAY_CHANGE = 'logout/displayChange';
export const logoutDisplayChange = display => ({
    type: LOGOUT_DISPLAY_CHANGE,
    payload: display,
});
export const USER_LOGOUT = 'USER_LOGOUT';
export const userLogout = () => ({
    type: USER_LOGOUT,
});

export const LOGOUT_CLEAN_STORE = 'logout/cleanStore';

export const cleanLocalStorage = () => {
    window.sessionStorage.removeItem('companyId');
    window.localStorage.removeItem('persist:root');
    window.localStorage.removeItem('persist:companies');
    window.localStorage.removeItem('persist:loads');
    window.localStorage.removeItem('persist:sharedActions');
    window.localStorage.removeItem('persist:user');
};

//todo: this method should do more than just kick
//todo: you out of the app. Make it handle other
//todo: errors
export const errorHandler = e => dispatch => {
    const { status, response } = e;
    switch (status) {
        case 403:
            if (window.location.pathname === '/reset-password') {
                history.push('/forgot-password');
                dispatch(
                    showSnackbar({
                        open: true,
                        severity: 'warning',
                        message:
                            'Your reset token has expired! Submit your email for a new one.',
                    })
                );
                break;
            }
            const isApiGatewayError =
                response &&
                response.body &&
                response.body.message === 'Missing Authentication Token';
            if (isApiGatewayError) {
                console.error(
                    'APIGateway issue. Please check URL and back-end service'
                );
            }
            cleanLocalStorage();

            history.push('/login');
            dispatch(logoutDisplayChange('none'));
            break;
        case 409:
            console.log(e.response);
            dispatch(
                showSnackbar({
                    open: true,
                    severity: 'error',
                    message: e.response.body,
                })
            );
            break;
        case 500:
            console.error(e);
            dispatch(
                showSnackbar({
                    open: true,
                    severity: 'error',
                    message: 'There was an error. See developer console.',
                })
            );
            dispatch(logoutDisplayChange('none'));
            break;
        default:
            break;
    }
    dispatch(loaderDisplayChange('none'));
    return []; //todo: figure out what needs this??
};

export const SHOW_SNACKBAR = 'error/SHOW_SNACKBAR';
export const showSnackbar = payload => ({
    type: SHOW_SNACKBAR,
    payload,
});

export const logout = () => dispatch => {
    request
        .get('/api/auth/logout')
        .then(() => {
            dispatch(userLogout());
            dispatch(loginSubject());
            logoutDisplayChange('none');
            document.getElementById('other-nav').style.display = 'none';
        })
        .catch(e => {
            console.log(e);
        });
};

export const HAMBURGER_DISPLAY_CHANGE = 'hamburger/displayChange';
export const hamburgerDisplayChange = display => ({
    type: HAMBURGER_DISPLAY_CHANGE,
    payload: display,
});

/**
 * * to dispatch via redux dev tools if
 * * spinner gets stuck
 * {
type: 'loader/displayChange',
payload: 'none'
}
 */

export const clearInput = new Subject();

/**
 * used for validating token for reset password
 * if token isn't valid it user will be routed
 * to /forgot-password
 */
export const validateToken = token => dispatch => {
    request
        .get(`/api/auth/password/validate-token?token=${token}`)
        .then(() => {})
        .catch(e => dispatch(errorHandler(e)));
};
