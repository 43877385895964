import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import GridItem from '../Grid/GridItem';
import { H1 } from '../helpers/Headings';
import Button from '../CustomButtons/Button';
import GridContainer from '../Grid/GridContainer.js';
import CustomInput from '../CustomInput/CustomInput.js';

import { emailRegEx } from '../helpers/regEx';
import forgotPasswordCopy from '../copy/forgot-password.json';
import checkSpam from '../copy/check-spam.json';
import { getPasswordResetEmail } from '../../actions/user-actions';

import styles from './ChangePassword.module.css';

export default function ForgotPassword() {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const handleClick = () => {
        dispatch(getPasswordResetEmail(email));
        setEmail('');
    };
    return (
        <GridContainer
            className={styles['grid-container']}
            alignItems={'center'}
            justify={'center'}>
            <GridItem className={`${styles['text-align']} ${styles.margin}`}>
                <H1 heading={'Forgot Password'} />
                <p className={styles.instruction}>{forgotPasswordCopy}</p>
                <p>
                    <strong>{checkSpam}</strong>
                </p>
                <CustomInput
                    formControlProps={{
                        fullWidth: true,
                    }}
                    value={email}
                    labelText={'email'}
                    id={'email-input'}
                    error={!email.match(emailRegEx) && email !== ''}
                    success={email.match(emailRegEx)}
                    inputProps={{
                        type: 'email',
                        onChange: e => setEmail(e.target.value.trim()),
                    }}
                />
                <Button
                    className={styles['reset-button']}
                    disabled={!email.match(emailRegEx)}
                    children={'Reset'}
                    onClick={handleClick}
                />
            </GridItem>
        </GridContainer>
    );
}
