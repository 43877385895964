import React from 'react';

export function H1(props) {
    const { heading } = props;
    return <h1 {...props}>{heading}</h1>;
}

export function H2(props) {
    const { heading } = props;
    return <h2 {...props}>{heading}</h2>;
}

export function H3(props) {
    const { heading } = props;
    return <h3 {...props}>{heading}</h3>;
}

export function H4(props) {
    const { heading } = props;
    return <h4 {...props}>{heading}</h4>;
}

export function H5(props) {
    const { heading } = props;
    return <h5 {...props}>{heading}</h5>;
}
