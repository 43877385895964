import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import Button from '@material-ui/core/Button';
import customSelectStyle from '../../customSelectStyle';
import stepsStyles from './Steps.module.css';
import { Alert } from '@material-ui/lab';
import { loadBeingEdited } from '../../../actions/loads-actions';

class Step4 extends Component {
    state = {
        simpleSelect: '',
        desgin: false,
        code: false,
        develop: false,
        ship: false,
        showShip: false,
        showSave: false,
        save: false,
    };

    subscription1 = loadBeingEdited.subscribe({
        next: load => {
            if (load.ready) {
                this.setState({ showShip: true, ship: true });
            }
            this.setState({});
        },
    });

    componentWillUnmount() {
        this.subscription1.unsubscribe();
    }

    sendState() {
        return this.state;
    }

    isValidated() {
        return true;
    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer justify={'center'}>
                <GridItem xs={12} sm={12}>
                    <h4 className={classes.infoText}>Ship or save this load</h4>
                </GridItem>
                <GridItem xs={12} sm={7} className={stepsStyles['text-center']}>
                    <Button
                        className={stepsStyles['ship-save']}
                        variant={'contained'}
                        onClick={() =>
                            this.setState({
                                showSave: true,
                                showShip: false,
                                ship: false,
                                save: true,
                            })
                        }>
                        Save
                    </Button>
                    <Button
                        className={stepsStyles['ship-save']}
                        // Todo: look deeper into this. it is not working when you
                        // Todo: add a carrier then remove the carrier after
                        // Todo: arriving to step4. redux store is updating but
                        // Todo: the value here for disabled isn't.
                        // * we have a fail safe in BuildEditOutboundLoad that will
                        // * just save the load and not mark it as ready but that
                        // * isn't ideal because the user doesn't know that that is
                        // * happening
                        disabled={
                            this.props.bols.length === 0 &&
                            this.props.carrierSelected === null
                        }
                        variant={'contained'}
                        onClick={() =>
                            this.setState({
                                showSave: false,
                                showShip: true,
                                ship: true,
                                save: false,
                            })
                        }>
                        Ship
                    </Button>
                    <Alert
                        severity={'info'}
                        style={{
                            display: this.state.showSave ? 'flex' : 'none',
                        }}>
                        <strong>
                            You have chosen to save this load for later
                        </strong>
                    </Alert>
                    <Alert
                        severity={'success'}
                        style={{
                            display: this.state.showShip ? 'flex' : 'none',
                        }}>
                        <strong>You have chosen to ship this load</strong>
                    </Alert>
                </GridItem>
            </GridContainer>
        );
    }
}

Step4.propTypes = {
    classes: PropTypes.object,
};

const style = {
    infoText: {
        fontWeight: '300',
        margin: '10px 0 30px',
        textAlign: 'center',
    },
    ...customSelectStyle,
};

const mapStateToProps = state => ({
    bols: state.loadsReducer.bols,
    carrierSelected: state.loadsReducer.carrierSelected,
});
const styledStep4 = withStyles(style)(Step4);
export default connect(mapStateToProps)(styledStep4);
