import request from 'superagent';
import history from '../history';
import { errorHandler, loaderDisplayChange } from './shared-actions';
import { Subject } from 'rxjs';

export const FETCHED_UNSUBMITTED_LOADS = 'loads/fetchedUnsubmitted';
export const fetchedUnsubmittedLoads = (loads, total) => ({
    type: FETCHED_UNSUBMITTED_LOADS,
    payload: { total, loads },
});

export const FETCHED_OUTBOUND_LOADS = 'loads/fetchedOutbound';
export const fetchedOutboundLoads = (loads, total) => ({
    type: FETCHED_OUTBOUND_LOADS,
    payload: { total, loads },
});

export const FETCHED_INBOUND_LOADS = 'loads/fetchedInbound';
export const fetchedInboundLoads = (loads, total) => ({
    type: FETCHED_INBOUND_LOADS,
    payload: { total, loads },
});

export const FETCHED_COMPLETED_LOADS = 'loads/fetchedCompleted';
export const fetchedCompletedLoads = (loads, total) => ({
    type: FETCHED_COMPLETED_LOADS,
    payload: { total, loads },
});

export const FETCHED_PAGINATION_LOADS = 'loads/fetchedPagination';
export const fetchedPaginationLoads = (loads, total, stateOfLoad) => {
    return {
        type: FETCHED_PAGINATION_LOADS,
        payload: { total, loads, stateOfLoad },
    };
};

/**
 * @typedef {import('../declarations/Load').Load}
 * @param {Load.Load} load
 */
export const editLoad = load => dispatch => {
    const id = load._id;
    delete load._id;
    request
        .put(`/api/loads/${id}`)

        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .send(load)
        .then(res => {
            if (res.ok) {
                console.log('Look res.body editLoad ', res.body);
                // dispatch(a.findShpRcrPopulate(shipperId));
            }
        })
        .catch(e => {
            console.log(e);
            history.push('/');
        });
};

export const getAllLoads = (completedProps, skipPagination) => dispatch => {
    dispatch(loaderDisplayChange('block'));
    request
        .get('/api/companies/loads/outbound/inbound/complete/unsubmitted')
        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .then(res => {
            const {
                outbound,
                inbound,
                unsubmitted,
                completed,
                outboundTotal,
                inboundTotal,
                completedTotal,
                unsubmittedTotal,
            } = res.body;
            dispatch(fetchedCompletedLoads(completed, completedTotal));
            // * in order to route the user to the completed
            // * page after completing the load we needed
            // * to call this method and add the logic here
            // * so that in the completed page we have
            // * all the variables that we need when the
            // * component mounts
            if (completedProps) {
                history.push(
                    `/shipper-account/${completedProps.companyName}/completed/${completedProps.loadId}`
                );
            }
            dispatch(fetchedInboundLoads(inbound, inboundTotal));
            dispatch(fetchedOutboundLoads(outbound, outboundTotal));
            dispatch(fetchedUnsubmittedLoads(unsubmitted, unsubmittedTotal));
            // * if there are no completed props we are doing a regular load
            // * so we need to next paginatedLoads else we skip over this
            // * kill the loader display,
            // * this will show the loads associated with the default widget

            if (!completedProps && !skipPagination) {
                return paginatedLoads.next('inbound');
            }
            dispatch(loaderDisplayChange('none'));
        })
        .catch(e => dispatch(errorHandler(e)));
};

export const paginatedLoads = new Subject();
export const loadsPagination = (page, loadState, qs) => dispatch => {
    dispatch(loaderDisplayChange('block'));
    request
        .get(`/api/loads/pagination?page=${page}&${qs}`)
        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .then(res => {
            const loadMap = {
                outbound: {
                    method: fetchedOutboundLoads,
                    total: 'outboundTotal',
                },
                completed: {
                    method: fetchedCompletedLoads,
                    total: 'completedTotal',
                },
                inbound: { method: fetchedInboundLoads, total: 'inboundTotal' },
                unsubmitted: {
                    method: fetchedUnsubmittedLoads,
                    total: 'unsubmittedTotal',
                },
            };

            const total = res.body[loadMap[loadState].total];
            const loads = res.body[loadState];
            dispatch(fetchedPaginationLoads(loads, total, loadState));
            paginatedLoads.next(loadState);
        })
        .catch(e => dispatch(errorHandler(e)));
};

export const CHANGED_TABLE = 'loads/changedTable';
export const changedTable = (iconProps, tableRows) => ({
    type: CHANGED_TABLE,
    payload: { iconProps, tableRows },
});

export const createLoad = load => dispatch => {
    dispatch(loaderDisplayChange('block'));
    request
        .post(`/api/loads/create`)
        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .send(load)
        .then(res => {
            if ('newLoad' in res.body) {
                dispatch(loaderDisplayChange('none'));
                history.push('/home');
            }
        })
        .catch(e => dispatch(errorHandler(e)));
};

export const updateLoad = (load, transaction) => dispatch => {
    const loadId = load._id;
    if (!transaction) {
        delete load._id;
    }

    request
        .put(`/api/loads/${transaction ? 'update' : loadId}`)
        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .send(load)
        .then(res => {
            dispatch(getAllLoads(null, true));
            if ('updatedLoad' in res.body) {
                dispatch(loaderDisplayChange('none'));
                history.push('/home');
            }
        })
        .catch(e => dispatch(errorHandler(e)));
    dispatch(loaderDisplayChange('none'));
};

export const CHANGE_LOAD_BEING_EDITED = 'loads/changeLoadBeingEditeded';

export const loadBeingEdited = new Subject();
export const selectedLoadToEdit = (id, loads, type) => dispatch => {
    let loadToBeEdited;
    if (type !== 'inbound') {
        loadToBeEdited = loads.find(l => l._id === id);
        loadBeingEdited.next(loadToBeEdited);
        return;
    }
    loadToBeEdited = loads.find(l => l.loadId === id);
    loadBeingEdited.next(loadToBeEdited);
};

export const CHANGE_INBOUND_LOAD_EDIT = 'loads/changeLoadBeingEdited';
export const changeInboundEditLoad = load => ({
    type: CHANGE_INBOUND_LOAD_EDIT,
    payload: load,
});

export const SET_BOLS_CARRIER_SELECTED = 'loads/setBolsCarrierSelected';
export const setBolsAndCarrierSelected = (bols, carrierSelected = false) => {
    const actionObject = {
        type: SET_BOLS_CARRIER_SELECTED,
        payload: {},
    };
    if (Array.isArray(bols)) {
        actionObject.payload = { bols };
    }

    if (carrierSelected !== false) {
        actionObject.payload = { ...actionObject.payload, carrierSelected };
    }
    return actionObject;
};

export const getCarrierLoads = (carrierId, completedProps) => dispatch => {
    dispatch(loaderDisplayChange('block'));
    request
        .get(`/api/loads/carrier/${carrierId}`)
        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .then(res => {
            const {
                shipping,
                shippingTotal,
                completed,
                completedTotal,
            } = res.body;
            dispatch(fetchedCompletedLoads(completed, completedTotal));
            dispatch(fetchedOutboundLoads(shipping, shippingTotal));
            if (!completedProps) {
                // * the name of this widget is shipping (in the UI)
                // * but we are reusing the "outbound" widget hence,
                // * why we next "outbound"
                return paginatedLoads.next('outbound');
            }
            dispatch(loaderDisplayChange('none'));
        })
        .catch(e => dispatch(errorHandler(e)));
};

export const getDriverLoads = (driverId, completedProps) => dispatch => {
    dispatch(loaderDisplayChange('block'));
    request
        .get(`/api/loads/driver/${driverId}`)
        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .then(res => {
            const {
                shipping,
                shippingTotal,
                completed,
                completedTotal,
            } = res.body;
            dispatch(fetchedCompletedLoads(completed, completedTotal));
            dispatch(fetchedOutboundLoads(shipping, shippingTotal));
            if (!completedProps) {
                // * the name of this widget is shipping (in the UI)
                // * but we are reusing the "outbound" widget hence,
                // * why we next "outbound"
                return paginatedLoads.next('outbound');
            }
            dispatch(loaderDisplayChange('none'));
        })
        .catch(e => dispatch(errorHandler(e)));
};
