import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { loginSubject } from '../../actions/user-actions';

import styles from './Sidenav.module.css';
//Todo: all these history.push() should be Links from react-router-dom
export class Sidenav extends Component {
    state = {
        showWrite: false,
    };

    componentDidMount() {
        this.setShowWrite();
    }

    setShowWrite = () => {
        const { user, company } = this.props;
        const showWrite =
            user.role !== 3 &&
            user.role !== undefined &&
            company.type !== 0 &&
            company.type !== undefined;
        this.setState({
            showWrite,
        });
    };

    componentDidUpdate(prevProps) {
        const { company: c } = this.props;
        const { company: prevCompany } = prevProps;
        if (c.type !== prevCompany.type) {
            this.setShowWrite();
        }
    }

    loginSubscription = loginSubject.subscribe({
        next: () => this.setShowWrite(),
    });

    closeNav() {
        document.getElementById('my-sidenav').style.width = '0';
        document.getElementById('main-container').style.marginLeft = '0';
        document.getElementById('other-nav').style.marginLeft = '0';
        document.getElementById('hamburger-menu').style.display = 'initial';
        document.getElementById('footer').style.marginLeft = '0';
    }

    render() {
        const { company, user } = this.props;
        document.getElementById('body').addEventListener('click', function () {
            const el = document.getElementById('my-sidenav');
            if (el && el.style.width === '250px') {
                document.getElementById('other-nav').style.marginLeft = '0';
                document.getElementById('my-sidenav').style.width = '0';
                document.getElementById('main-container').style.marginLeft =
                    '0';
                document.getElementById('footer').style.marginLeft = '0';
                document.getElementById('hamburger-menu').style.display =
                    'initial';
            }
        });
        const isAuthenticated = user != null && user._id;
        const { showWrite } = this.state;
        return (
            <div id="my-sidenav" className={styles['side-nav']}>
                <span
                    onClick={this.closeNav}
                    className={`${styles['close-btn']} link`}>
                    &times;
                </span>
                <Link
                    onClick={this.closeNav}
                    to={user != null && user._id ? '/home' : '/'}>
                    Home
                </Link>
                <Link onClick={this.closeNav} to={'/FAQs'}>
                    FAQs
                </Link>

                {isAuthenticated && (
                    <>
                        {showWrite && (
                            <>
                                <Link
                                    onClick={this.closeNav}
                                    to={'/driver-checkin'}>
                                    Driver Checkin
                                </Link>
                                <Link
                                    onClick={this.closeNav}
                                    to={`/shipper-account/${company.name}/build-outbound-load`}>
                                    Create a Load
                                </Link>
                                <Link
                                    onClick={this.closeNav}
                                    to={`/pickup-checkout`}>
                                    Pickup Checkout
                                </Link>
                            </>
                        )}
                        <Link
                            onClick={this.closeNav}
                            to={'/settings/directory'}>
                            Directory
                        </Link>
                        <Link
                            onClick={this.closeNav}
                            to={'/settings/my-profile'}>
                            My Profile
                        </Link>
                        <Link
                            onClick={this.closeNav}
                            to={'/settings/company-profile'}>
                            Company Profile
                        </Link>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user,
        shipperAccts: state.thirdReducer.shipperAcct,
        driverAcct: state.thirdReducer.driverAcct,
        company: {
            name: state.companiesReducer.companyWithBasicData.name,
            id: state.companiesReducer.companyWithAllData._id,
            type: state.companiesReducer.companyWithAllData.type,
        },
    };
};

export default connect(mapStateToProps)(Sidenav);
