/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTable, useFilters, useSortBy, usePagination } from 'react-table';
import { tableChanged } from '../Dashboard/Dashboard';
import classnames from 'classnames';

import history from '../../history';
import { loadsPagination } from '../../actions/loads-actions';
import { bolsPagination } from '../../actions/bol-actions';

// core components
import tableStyles from './ReactTable.module.css';
import { DefaultColumnFilter, fuzzyTextFilterFn } from './ReactTableFilters';

function ReactTable({
    columns,
    data,
    tableName,
    pageCount: controlledPageCount,
    carrierView,
}) {
    const myCompany = useSelector(
        state => state.companiesReducer.companyWithBasicData
    );
    const lowerCasedTableName = tableName && tableName.toLowerCase();
    const currCategoryTotal = useSelector(
        state => state.loadsReducer[`${lowerCasedTableName}Total`]
    );
    const myCompanyName = myCompany.name;
    const dispatch = useDispatch();

    const [pageSelect, setPageSelect] = useState(2);
    let subscription;
    useEffect(() => {
        subscription = tableChanged.subscribe({
            next: () => setPageSelect(2),
        });
        return () => subscription.unsubscribe();
    });

    const filterTypes = React.useMemo(() => {
        return {
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                              .toLowerCase()
                              .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        };
    }, []);

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        page,
        prepareRow,
        setPageSize,
        headerGroups,
        getTableProps,
        getTableBodyProps,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            initialState: { sortBy: [{ id: 'id', desc: false }], pageIndex: 0 }, // Pass our hoisted table state
            manualPagination: true,
            pageCount: controlledPageCount,
        },
        useFilters, // useFilters!
        useSortBy,
        usePagination
    );

    const mainTableStyle = {
        width: '100%',
        textAlign: 'left',
        minWidth: '900px',
    };

    const headerStyles = key =>
        `rt-resizable-header-content ${
            key === 0 ? tableStyles['min-width-188'] : ''
        }`;

    const styleHeader = column =>
        classnames('rt-th rt-resizable-header -cursor-pointer', {
            '-sort-asc': column.isSorted && !column.isSortedDesc,
            '-sort-desc': column.isSorted && column.isSortedDesc,
        });

    const tableRowStyles = i =>
        `${classnames(
            'rt-tr',
            { ' -odd': i % 2 === 0 },
            { ' -even': i % 2 === 1 }
        )} ${tableStyles['table-row']}`;

    const seeMoreLoads = e => {
        e.preventDefault();
        const qs = `type=${lowerCasedTableName}`;
        if (lowerCasedTableName === 'completed') {
            dispatch(bolsPagination(pageSelect));
        } else {
            dispatch(loadsPagination(pageSelect, lowerCasedTableName, qs));
        }
        setPageSize(currCategoryTotal);
        setPageSelect(pageSelect + 1);
    };

    const selectLoad = (id, routeParam, company) => {
        const paramPrefix = carrierView ? 'carrier' : 'shipper';
        history.push(
            `/${paramPrefix}-account/${company.replace(
                ' ',
                ''
            )}/${routeParam}/${id}`
        );
    };

    const createRowElements = row => {
        return row.cells.map(cell => (
            <td {...cell.getCellProps()} className={'rt-td'}>
                {cell.render('Cell')}
            </td>
        ));
    };
    const getRoutingValues = row => {
        const { id, companyName } = row.values;
        const routeParam = lowerCasedTableName;
        const company = companyName ? companyName : myCompanyName;
        return { id, company, routeParam };
    };
    //Todo: correct height of the table
    //todo: table should take up remaining space on page
    return (
        <>
            <div
                className={'ReactTable -striped -highlight'}
                style={{ overflow: 'auto' }}>
                <table
                    {...getTableProps()}
                    className={'rt-table'}
                    //Todo: the following styles should
                    //Todo: come from a stylesheet
                    style={mainTableStyle}>
                    <thead className={'rt-thead -header'}>
                        {headerGroups.map(headerGroup => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                className={'rt-tr'}>
                                {headerGroup.headers.map((column, key) => (
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps()
                                        )}
                                        className={styleHeader(column)}>
                                        <div className={headerStyles(key)}>
                                            {column.render('Header')}
                                        </div>
                                        {/* Render the columns filter UI */}
                                        <div>{column.render('Filter')}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className={'rt-tbody'}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            const {
                                id,
                                routeParam,
                                company,
                            } = getRoutingValues(row);
                            return (
                                <tr
                                    onClick={() =>
                                        selectLoad(id, routeParam, company)
                                    }
                                    {...row.getRowProps()}
                                    className={tableRowStyles(i)}>
                                    {createRowElements(row)}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className={'pagination-bottom'}>
                    <div className={'pagination-top'}>
                        <div className={tableStyles.pagination}>
                            <h1 onClick={seeMoreLoads}>see more loads</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReactTable;
