import React from 'react';
import matchSorter from 'match-sorter';
import CustomInput from '../CustomInput/CustomInput.js';
// Define a default UI for filtering
export function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length;

    return (
        <CustomInput
            inputProps={{
                value: filterValue || '',
                onChange: e => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                },
                placeholder: `Search ${count} records...`,
            }}
        />
    );
}

export function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

// Define a custom filter filter function!
export function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number';
