import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './NavigationBar.module.css';
import { logoutDisplayChange, logout } from '../../actions/shared-actions';
import { imageChanged } from '../../actions/user-actions';
export class Navbar extends Component {
    state = {
        display: 'none',
        newImage: null,
    };
    openNav = e => {
        e.stopPropagation();
        e.preventDefault();
        document.getElementById('my-sidenav').style.width = '250px';
        document.getElementById('main-container').style.marginLeft = '250px';
        document.getElementById('other-nav').style.marginLeft = '250px';
        document.getElementById('other-nav').style.marginLeft = '250px';
        document.getElementById('hamburger-menu').style.display = 'none';
    };

    subscription = imageChanged.subscribe({
        next: image => this.setState({ newImage: image }),
    });

    componentDidMount() {
        document.getElementById('other-nav').style.display = 'none';
        const scrollY = 0;
        window.addEventListener('scroll', function () {
            if (window.scrollY < 98 || scrollY === 0) {
                document.getElementById('other-nav').style.display = 'none';
            } else {
                document.getElementById('other-nav').style.display = 'block';
            }
        });
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    render() {
        let x = this.props;
        const { user, logoutDisplay, dispatch } = this.props;
        const { newImage } = this.state;
        const imagePreviewUrl =
            newImage ||
            user.img ||
            'https://connex-imgs.s3.amazonaws.com/connexapp-images/linkedin-default.png';

        return (
            <div id={'header-container'} className={styles['header-container']}>
                <div id={'hamburger-menu'} className={styles['hamburger-menu']}>
                    <p className={styles['no-marg']}>
                        <span
                            onClick={this.openNav}
                            className={`${styles.hamburger} link`}>
                            &#8801;
                        </span>
                    </p>
                </div>
                <div id={'header-wrapper'} className={styles['header-wrapper']}>
                    <Link to={`${user._id ? '/home' : '/'}`}>
                        <img
                            src={
                                'https://connex-imgs.s3.amazonaws.com/connexapp-images/logoWhite.png'
                            }
                            alt="logo"
                            className={`${styles['connex-logo']} link`}
                        />
                    </Link>
                </div>

                <div
                    className={styles['avatar-box']}
                    style={{ display: logoutDisplay }}>
                    <Link to={'/settings/my-profile'}>
                        <img
                            //todo: change className to something else
                            className={styles['driver-img']}
                            alt="User avatar"
                            src={imagePreviewUrl}
                        />
                    </Link>
                </div>
                <Link
                    to="/"
                    onClick={() => {
                        dispatch(logout());
                        dispatch(logoutDisplayChange('none'));
                    }}>
                    <span
                        style={{ display: x.logoutDisplay }}
                        className={`${styles.logout} link`}>
                        Logout
                    </span>
                </Link>

                <div id="other-nav">
                    <Link
                        to="/"
                        onClick={() => {
                            dispatch(logout());
                        }}>
                        <span
                            style={{ display: x.logoutDisplay }}
                            className="link logout2">
                            Logout
                        </span>
                    </Link>
                    <div
                        className={styles['avatar-pic']}
                        style={{ display: x.logoutDisplay }}>
                        <img
                            //todo: change className to something else
                            className={styles['driver-img']}
                            alt="file to upload"
                            src={imagePreviewUrl}
                        />
                    </div>
                    <div
                        id="fixNav-hamburger-menu"
                        className={styles['fix-nav-hamburger-menu']}>
                        {' '}
                        <span
                            onClick={this.openNav}
                            className={`${styles.hamburger} link`}>
                            &#8801;
                        </span>{' '}
                    </div>
                    <div className="small-logo">
                        <Link to={`${user._id ? '/home' : '/'}`}>
                            <img
                                src={
                                    'https://connex-imgs.s3.amazonaws.com/connexapp-images/logoWhite.png'
                                }
                                alt="logo "
                                className="connex-lil-logo link"
                            />
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user,
        logoutDisplay: state.sharedActionReducer.logoutDisplay,
    };
};

export default connect(mapStateToProps)(Navbar);
