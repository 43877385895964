import request from 'superagent';

export const MARK_CHECK_BOX = 'MARK_CHECK_BOX';
export const markCheckBox = () => ({
    type: MARK_CHECK_BOX,
});

export const UPDATE_LICENSE_NUM = 'UPDATE_LICENSE_NUM';
export const updateLicNum = num => ({
    type: UPDATE_LICENSE_NUM,
    num,
});

export const GET_ALL_CARRIERS = 'GET_ALL_CARRIERS';
export const getAllCarriers = res => ({
    type: GET_ALL_CARRIERS,
    res,
});

export const SET_CARRIER_ID = 'SET_CARRIER_ID';
export const setCarrId = num => ({
    type: SET_CARRIER_ID,
    num,
});

export const DRIVERS_ACCT = 'DRIVERS_ACCT';
export const driversAcct = driver => ({
    type: DRIVERS_ACCT,
    driver,
});

export const findMyDriverAccounts = (userId, jwt) => dispatch => {
    return new Promise((resolve, reject) => {
        return request
            .get(`/api/connex/drivers/${userId}`)

            .then(res => {
                if (res.ok) {
                    /*res.body.length > 0 I had this first
                    but when I wanted to delete it caused problems.
                    if problems persist with delete or anything related 
                    to "my driver's accounts" revisit */
                    dispatch(driversAcct(res.body));
                    resolve();
                } else {
                    reject(res);
                }
            })
            .then(res => {
                if (res) {
                    console.log(`Response from server: ${res}`);
                } else {
                    console.log("Lookin' good! 👨🏾‍");
                }
            })
            .catch(err => {
                console.log(err);
            });
    });
};

export const addToDriverCarriersDrivers = (
    carrier,
    jwt,
    driver
) => dispatch => {
    let obj = {
        driver: driver,
    };

    return new Promise((resolve, reject) => {
        return request
            .put(`/api/connex/addToDriverCarriersDrivers/${carrier}`)

            .set('Accept', 'application/json')
            .set('Content-type', 'application/json')
            .send(JSON.stringify(obj))
            .then(res => {
                if (res.ok) {
                    resolve();
                } else {
                    reject(res);
                }
            })
            .then(res => {
                if (res) {
                    console.log(`Response from server: ${res}`);
                } else {
                    console.log("Lookin' good! 👨🏾‍");
                }
            })
            .catch(err => {
                console.log(err);
            });
    });
};

export const createDriverProfile = (
    name,
    lic,
    userId,
    img,
    jwt,
    carrierId
) => dispatch => {
    let driverObj = {
        driverLicense: lic,
        carrierId: carrierId,
        userId: userId,
        driverLicensePic: img,
        accountName: name,
    };

    request
        .post('/api/connex/drivers')

        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .send(JSON.stringify(driverObj))
        .then(res => {
            if (res.ok) {
                // console.log('res from createDriver',res)
                dispatch(findMyDriverAccounts(userId, jwt));
                window.location = `/home/${userId}`;
                dispatch(
                    addToDriverCarriersDrivers(
                        carrierId,
                        jwt,
                        res.body.connexDriverId
                    )
                );
            }
        })
        .catch(err => console.log(err));
};
