import React from 'react';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import Fab from '@material-ui/core/Fab';

import styles from './ImageUpload.module.css';

export default function InitialState(props) {
    const { classes, onClick, src, hideUpload } = props;
    return (
        <div>
            {!hideUpload && (
                <>
                    <input
                        accept={'image/*'}
                        className={`${classes.input} ${
                            hideUpload && styles.hide
                        }`}
                        id={'contained-button-file'}
                        multiple
                        type={'file'}
                        onChange={onClick}
                    />
                    <label
                        htmlFor={'contained-button-file'}
                        className={styles.label}>
                        <Fab component={'span'} className={classes.button}>
                            <AddPhotoAlternateIcon />
                        </Fab>
                    </label>
                </>
            )}
            <img
                className={styles['profile-pic']}
                src={
                    src ||
                    'https://connex-imgs.s3.amazonaws.com/connexapp-images/linkedin-default.png'
                }
                alt={'user avatar'}
            />
        </div>
    );
}
