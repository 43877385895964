import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import GridItem from '../Grid/GridItem';
import ChangePassword from './ChangePassword';
import GridContainer from '../Grid/GridContainer.js';

import { validateToken } from '../../actions/shared-actions';
import { H1 } from '../helpers/Headings';
import resetPasswordCopy from '../copy/reset-password.json';

import styles from './ChangePassword.module.css';

export default function ChangePasswordView() {
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('userId');
    const token = urlParams.get('resetToken');
    useEffect(() => dispatch(validateToken(token)));

    return (
        <GridContainer
            className={styles['grid-container']}
            alignItems={'center'}
            justify={'center'}>
            <GridItem
                className={`${styles['text-align']} ${styles['max-width']} ${styles.margin}`}>
                <H1 heading={'Reset Password'} />
                <p className={styles.instruction}>{resetPasswordCopy}</p>
                <ChangePassword
                    userId={userId}
                    toggleChangePassword={() => {}}
                />
            </GridItem>
        </GridContainer>
    );
}
