import * as d from '../actions/index5';

const state3 = {
    hamburgerDisplay: 'none',
    displayCreateAProfile: 'initial',
};

const reducer3 = (state = state3, action) => {
    switch (action.type) {
        case d.HAMBURGER_DISPLAY:
            if (state.hamburgerDisplay === 'none') {
                return Object.assign({}, state, {
                    hamburgerDisplay: 'block',
                });
            }
            return Object.assign({}, state, {
                hamburgerDisplay: 'none',
            });
        default:
            return state;
    }
};

export default reducer3;
