import React from 'react';
import {connect} from 'react-redux'; 
import DriverLoadInfo from './driver/driverLoadInfo';
import BOLsInfo from './driver/bolsInfo';
import DriverInfo from './driver/driverInfo';

export class DriverOverview extends React.Component{
    render() {
    //   let x = this.props;
     
        return (
            <div className='driver-overview'>
                <DriverInfo />
                <DriverLoadInfo />
                <BOLsInfo />
            </div>
        )       
    }
}
export const mapStateToProps = state => {
    return {
        index:state.thirdReducer.index,
        info:state.thirdReducer.mySelectedCarrier,
        jwt:state.dataReducer.jwt,
        d_Id:state.thirdReducer.driverToAdd
    }
}
export default connect(mapStateToProps)(DriverOverview)