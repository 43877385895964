import blue from '@material-ui/core/colors/blue';

export default theme => ({
    root: {
        position: 'relative',
        margin: '10px auto 0',
        backgroundColor: theme.palette.background.paper,
        width: 300,
    },
    input: {
        display: 'none',
    },
    button: {
        color: blue[900],
        margin: 10,
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
});
