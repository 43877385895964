import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {togglePicModal} from '../../actions/index16';
import './carrier.css';

export class CarrierSignUp3 extends React.Component {
    
   state = {
       content:''
   }

  render(){
    
      return (
        <div className='outter-box'>
			<div className='instructions'>
				<p>
                    If you are not already a register Connex member you will 
                    first need to create your Member Profile. And guess what,
                    we don't ask for much.
                </p>
            </div>
            <div className='instructions'>
            <   img src='https://connex-imgs.s3.amazonaws.com/connexapp-images/become-member.png'
                    className='link'
                    onClick={() => {
                        this.props.dispatch(togglePicModal())
                        this.setState({content:<img src='https://connex-imgs.s3.amazonaws.com/connexapp-images/become-member.png' alt='add load' />})
                    }}
                    alt='add load'
                />
                <p>It's short and painless.</p>
                
            </div>
            
            <div className='instructions'>
                <p>
                    Well, that's about all to being a Connex carrier.
                    If you are ready to begin click contiue to be routed back to 
                    our welcome screen and choose either Become a Member or Login.
                </p>
                <p>Ready to begin!? </p>
                <Link to='/carriersignup2'><p>Back</p></Link>
                
                <Link to='/'><p>Start</p></Link>
			</div>
        </div>
      )
  }
}

export default connect()(CarrierSignUp3) 