import React, { Component } from 'react';
import { connect } from 'react-redux';
import Edit from '@material-ui/icons/Edit';

import Button from '../CustomButtons/Button';
import CustomInput from '../CustomInput/CustomInput.js';

import { updateCompany } from '../../actions/companies-actions';
import { H2, H3 } from '../helpers/Headings';
import formatPhoneNumber from '../helpers/formatPhoneNumber';
import { phoneRegEx, stateRegEx, zipRegEx } from '../helpers/regEx';

import appStyles from '.././App.module.css';
import styles from './InfoComponents.module.css';

class CompanyInfo extends Component {
    //todo: show user image
    state = {
        editMode: false,
        name: '',
        phone: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        state: '',
        zip: '',
    };

    resetState = () =>
        this.setState({
            editMode: false,
            name: '',
            phone: '',
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            city: '',
            state: '',
            zip: '',
        });

    componentDidMount() {
        const { company } = this.props;
        this.setState({
            name: (company && company.name) || '',
            phone: (company && company.phone) || '',
            addressLine1: (company && company.address.line1) || '',
            addressLine2: (company && company.address.line2) || '',
            addressLine3: (company && company.address.line3) || '',
            city: (company && company.address.city) || '',
            zip: (company && company.address.zip) || '',
            state: (company && company.address.state) || '',
        });
    }

    toggleEdit = () => {
        this.setState({
            editMode: !this.state.editMode,
        });
    };

    handleSubmit = () => {
        const {
            state,
            name,
            phone,
            addressLine1,
            addressLine2,
            addressLine3,
            city,
            zip,
        } = this.state;
        this.props.dispatch(
            updateCompany({
                id: this.props.company._id,
                update: {
                    name,
                    phone,
                    zip,
                    city,
                    state,
                    address: {
                        line1: addressLine1,
                        line2: addressLine2,
                        line3: addressLine3,
                    },
                },
            })
        );
        this.toggleEdit();
    };

    render() {
        const {
            state,
            name,
            phone,
            addressLine1,
            addressLine2,
            addressLine3,
            city,
            zip,
            editMode,
        } = this.state;
        const { canEdit, type, company } = this.props;

        return (
            <>
                <div className={styles['compnay-heading-wrapper']}>
                    <H2 className={styles.header} heading={`${type} info`} />
                    {canEdit && (
                        <Edit
                            onClick={this.toggleEdit}
                            className={styles['company-edit-icon']}
                        />
                    )}
                </div>
                <hr className={appStyles.rule} />
                {!editMode && (
                    <div>
                        <H3 heading={company && company.name} />
                        <p>{formatPhoneNumber(company && company.phone)}</p>
                        <p>{company && company.address.line1}</p>
                        {company && company.address.line2 && (
                            <p>company && company.address.line2</p>
                        )}
                        {company && company.address.line2 && (
                            <p>company.address.line2</p>
                        )}
                        <p>{`${company && company.city}, ${
                            company && company.state
                        } ${company && company.zip}`}</p>
                    </div>
                )}
                {editMode && (
                    <div className={styles['edit-user-info']}>
                        <CustomInput
                            value={name}
                            labelText={'company name'}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            id={'company-name-input'}
                            error={name === ''}
                            success={name !== '' && name.length > 2}
                            inputProps={{
                                onChange: e =>
                                    this.setState({ name: e.target.value }),
                            }}
                        />
                        <CustomInput
                            value={phone}
                            labelText={'company phone'}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            id={'company-phone-input'}
                            error={!phone.match(phoneRegEx)}
                            success={phone.match(phoneRegEx)}
                            inputProps={{
                                onChange: e =>
                                    this.setState({ phone: e.target.value }),
                            }}
                        />
                        <CustomInput
                            value={addressLine1}
                            labelText={'address line 1'}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            id={'line-1-input'}
                            error={addressLine1 === ''}
                            success={
                                addressLine1 !== '' && addressLine1.length > 4
                            }
                            inputProps={{
                                onChange: e =>
                                    this.setState({
                                        addressLine1: e.target.value,
                                    }),
                            }}
                        />
                        <CustomInput
                            value={addressLine2}
                            labelText={'address line 2'}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            id={'line-2-input'}
                            error={false}
                            success={false}
                            inputProps={{
                                onChange: e =>
                                    this.setState({
                                        addressLine2: e.target.value,
                                    }),
                            }}
                        />
                        <CustomInput
                            value={addressLine3}
                            labelText={'address line 3'}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            id={'line-3-input'}
                            error={false}
                            success={false}
                            inputProps={{
                                onChange: e =>
                                    this.setState({
                                        addressLine3: e.target.value,
                                    }),
                            }}
                        />
                        <CustomInput
                            value={city}
                            labelText={'city'}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            id={'city-input'}
                            error={city === ''}
                            success={city.trim().length >= 4}
                            inputProps={{
                                onChange: e =>
                                    this.setState({
                                        city: e.target.value,
                                    }),
                            }}
                        />
                        <CustomInput
                            value={state}
                            labelText={'state'}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            id={'state-input'}
                            error={!state.match(stateRegEx) || state === ''}
                            success={state.match(stateRegEx)}
                            inputProps={{
                                onChange: e =>
                                    this.setState({
                                        state: e.target.value.toUpperCase(),
                                    }),
                            }}
                        />
                        <CustomInput
                            value={zip}
                            labelText={'zip'}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            id={'zip-input'}
                            error={!`${zip}`.match(zipRegEx)}
                            success={`${zip}`.match(zipRegEx)}
                            inputProps={{
                                onChange: e =>
                                    this.setState({
                                        zip: e.target.value,
                                    }),
                            }}
                        />

                        <Button
                            children={'save'}
                            onClick={this.handleSubmit}
                            className={styles['submit-button']}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default connect()(CompanyInfo);
