import { withStyles } from '@material-ui/core/styles';
import { cardTitle } from '../material-dashboard-pro-react';
const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
    },
};

const useStyles = withStyles(styles);

export default useStyles;
