import React from 'react';
import {connect} from 'react-redux'; 

export class DriverLoadInfo extends React.Component{
    
    render() {
        let x = this.props;
        let cL;
        let currentLoad;
        if (x.driver) {
            if (x.driver.currentLoad) {
                cL = x.driver.currentLoad
                currentLoad =
                    <ul className='load-specs'>
                        <li>LoadId:             {cL._id}</li>
                        <li>Stops:              {cL.stops}</li>
                        <li>Load Num:           {cL.loadNum}</li>
                        <li>P/U Num:            {cL.pUNum}</li>
                        <li>P/U Date:           {cL.pUDate}</li>
                        <li>P/U Time:           {cL.pUTime}</li>
                        <li>P/U Location        {cL.pULocation}</li>
                        <li>P/U Destination:    {cL.destination}</li>
                        <li>Drop Date:          {cL.dropDate}</li>
                        <li>Drop Time:          {cL.dropTime}</li>
                        <li></li>
                    </ul>
            }
            
        }
        return (
            <div className='button-box'>
                <h4>Current Load</h4>
                {currentLoad}
           
            </div>
        )        
    }
} 

export const mapStateToProps = state => {
    return {
        jwt:state.dataReducer.jwt,
        driver:state.fourReducer.mySelectedDriver
    }
}
export default connect(mapStateToProps)(DriverLoadInfo)
