import React from 'react';
import Load from './Load';
import { updateBol } from '../../actions/bol-actions';
import { updateLoad } from '../../actions/loads-actions';
import styles from './DriverCheckin.module.css';

export default function handleSelectDriver(dependencies) {
    const {
        driver,
        inbound,
        dispatch,
        outbound,
        setPickUp,
        isCheckout,
        setDeliveries,
    } = dependencies;

    // * did this ol' skool for...loop to avoid unwanted undefined that gives array length
    // * and to avoid subsequent loops to remove the undefined values
    let pickUps = [];
    for (let i = 0; i < outbound.length; i++) {
        const load = outbound[i];
        const driverAssociatedBol = load.bols.find(
            b => b.driver._id === (driver && driver._id)
        );
        const loadId = load._id.substring(18, 24);
        if (driverAssociatedBol) {
            pickUps = [
                ...pickUps,
                <li className={styles.li} key={load._id}>
                    <Load
                        id={`l${loadId}`}
                        driver={driver}
                        idDisplay={loadId}
                        load={load}
                        isCheckout={isCheckout}
                        noCheckin={load.checkin !== null}
                        checkin={() => {
                            const prop = isCheckout ? 'checkout' : 'checkin';
                            dispatch(
                                updateLoad({
                                    [prop]: new Date(),
                                    _id: load._id,
                                })
                            );
                        }}
                    />
                </li>,
            ];
        }
    }
    setPickUp(pickUps);

    if (isCheckout) return;

    let deliveries = [];
    for (let i = 0; i < inbound.length; i++) {
        const bol = inbound[i];
        if (bol.driverId === (driver && driver._id)) {
            const bolId = bol._id.substring(18, 24);
            deliveries = [
                ...deliveries,
                <li key={bol._id} className={styles.li}>
                    <Load
                        id={`a${bolId}`}
                        driver={driver}
                        idDisplay={bol.load._id.substring(18, 24)}
                        bol={bol}
                        showSeal={true}
                        noCheckin={bol.checkin !== null}
                        onCheck={() => {
                            bol.sealIntact = !bol.sealIntact;
                        }}
                        checked={bol.sealIntact ? true : false}
                        checkin={() => {
                            //! the reason we add the 'a' is so that the id won't have a value that starts with an integer'
                            const { checked } = document.querySelector(
                                `#a${bolId} > span > span > input`
                            );
                            dispatch(
                                updateBol(
                                    {
                                        sealIntact: checked,
                                        checkin: new Date(),
                                    },
                                    bol._id
                                )
                            );
                        }}
                    />
                </li>,
            ];
        }
    }
    setDeliveries(deliveries);
}
