import request from 'superagent';
import { Subject } from 'rxjs';

import { errorHandler, loaderDisplayChange } from './shared-actions';
import { fetchedDrivers } from './user-actions';

export const COMPANY_FETCHED_All_DATA = 'company/fetchedAllData';
export const companyFetchedAllData = company => ({
    type: COMPANY_FETCHED_All_DATA,
    payload: company,
});

export const COMPANY_FETCHED_ADMIN = 'company/fetchedAdmin';
export const companyFetchedAdmin = admin => ({
    type: COMPANY_FETCHED_ADMIN,
    payload: admin,
});

export const aggregateCompanyUser = () => dispatch => {
    dispatch(loaderDisplayChange('block'));
    request
        .get('/api/companies/users/loads?getAllLoads=false')
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json')
        .then(res => {
            const { drivers, admin } = res.body;
            dispatch(companyFetchedAllData(res.body));
            dispatch(fetchedDrivers(drivers));
            //todo: take the rest of the admin and save them for a different
            //todo: component maybe companyDetails page
            dispatch(companyFetchedAdmin(admin[0]));
            // dispatch(loaderDisplayChange('none')); // * bring this back if necessary
            // history.push(`/shipper-account/${res.body.name}`);
        })
        .catch(e => dispatch(errorHandler(e)));
};

export const COMPANY_FETCHED_BASIC_DATA = 'company/fetchedBasicData';
export const companyFetchedBasicData = company => ({
    type: COMPANY_FETCHED_BASIC_DATA,
    payload: company,
});

//todo: consider renaming and rethinking the session storage peice
// * this is when the app initially loads
// * it gets the company data for the company of
// * the user logging in
export const getCompany = () => dispatch => {
    const companyId = window.sessionStorage.getItem('companyId');
    return request
        .get(`/api/companies/${companyId}`)
        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .then(res => {
            if (res.ok) {
                dispatch(companyFetchedBasicData(res.body));
                // dispatch(loaderDisplayChange('none')); // * bring this back if necessary
            } else {
            }
        })
        .catch(err => dispatch(errorHandler(err)));
};

export const SHIPPER_OF_LOAD = 'companies/ShipperOfLoad';
export const openShipperOfLoad = company => ({
    type: SHIPPER_OF_LOAD,
    payload: company,
});

/**
 * @description this gets all companies that contain characters in the searchTerm
 * @param {string} searchTerm
 * @param {number} type
 */
export const getAllCompanies = (searchTerm, type) => async dispatch => {
    try {
        const res = await request
            .get(
                `/api/companies/search?name=${searchTerm.toLowerCase()}&companyType=${type}&deleted=false`
            )
            .set('Accept', 'application/json')
            .set('Content-type', 'application/json');

        const results = res.body.length ? res.body : [];
        companiesReceived.next(results);
    } catch (e) {
        dispatch(errorHandler(e));
        companiesReceived.next([]);
    }
};

export const companiesReceived = new Subject();

/**
 * @description get shippers, receivers, or carriers by their ids
 * @param {*} param
 */
export const getShprRcvrCrrsByIds = async param => {
    try {
        const response = await request
            .get(
                `/api/companies/shippers-receivers-carriers?objectIds=${param}`
            )
            .set('Accept', 'application/json')
            .set('Content-type', 'application/json');

        if (Array.isArray(response.body)) {
            return response.body;
        }
        return [];
    } catch (e) {
        console.error(e);
        return [];
    }
};

export const updateCompany = data => async dispatch => {
    dispatch(loaderDisplayChange('block'));
    const { id, update } = data;
    delete update.id;
    try {
        const response = await request
            .put(`/api/companies/${id}`)
            .set('Accept', 'application/json')
            .set('Content-type', 'application/json')
            .send(update);

        if (response.ok) {
            dispatch(companyFetchedAllData(response.body));
        }
    } catch (e) {
        console.error(e);
    } finally {
        dispatch(loaderDisplayChange('none'));
    }
};
