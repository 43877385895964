import request from 'superagent';
import * as b from './index8';
import * as c from './index3';
import * as d from './index16';

export const addReceiverToBOLsReceiver = (
    receiverId,
    bolId,
    loadId,
    jwt
) => dispatch => {
    let obj = { receiver: receiverId };
    return new Promise((resolve, reject) => {
        return request
            .put(`/api/connex/loads/addReceiverToBOLsReceiver/${bolId}`)

            .set('Accept', 'application/json')
            .set('Content-type', 'application/json')
            .send(JSON.stringify(obj))
            .then(res => {
                if (res.ok) {
                    dispatch(b.showAddedMessage2());
                    setTimeout(function () {
                        dispatch(b.showAddedMessage2());
                    }, 3000);
                    resolve();
                } else {
                    reject(res);
                }
            })
            .then(res => {
                return res
                    ? console.log(res)
                    : console.log("Lookin' good! 👨🏾‍");
            })
            .catch(err => {
                console.error(err);
            });
    });
};

export const BOLS_FOR_THIS_RCVR = 'BOLS_FOR_THIS_RCVR';
export const BOLsForThisRcvr = bolArr => ({
    type: BOLS_FOR_THIS_RCVR,
    bolArr,
});

export const SHOW_CREATE_DRIVER_ERROR = 'SHOW_CREATE_DRIVER_ERROR';
export const showDrvError = () => ({
    type: SHOW_CREATE_DRIVER_ERROR,
});

export const findCarrier = (
    name,
    dlNum,
    userId,
    img,
    jwt,
    carrierId
) => dispatch => {
    if (carrierId && dlNum) {
        request
            .get(`/api/connex/carriers/${carrierId}`)

            .then(res => {
                if (res.ok) {
                    dispatch(
                        c.createDriverProfile(
                            name,
                            dlNum,
                            userId,
                            img,
                            jwt,
                            carrierId
                        )
                    );
                } else {
                    return Promise.reject(res.statusText);
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(d.togglePicModal());
            });
    } else {
        dispatch(d.togglePicModal());
    }
};

export const MY_UNSUBMITTED_LOADS = 'MY_UNSUBMITTED_LOADS';
export const myUnsubmittedLoads = loads => ({
    type: MY_UNSUBMITTED_LOADS,
    loads,
});

export const getUnsubmittedLoads = (createdBy, jwt) => dispatch => {
    return new Promise((resolve, reject) => {
        return request
            .get(`/api/connex/getUnsubmittedLoads/${createdBy} `)

            .then(res => {
                if (res.ok) {
                    dispatch(myUnsubmittedLoads(res.body));
                    resolve(res.body);
                } else {
                    return "Something happened but we don't know what.";
                }
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
    });
};
