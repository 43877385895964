import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import { isLoggedIn } from '../actions/shared-actions';

import './welcome.css';

export class Welcome extends React.Component {
    componentDidMount() {
        // this.props.dispatch(isLoggedIn());
    }

    render() {
        return (
            <div className="welcome-box">
                <div className="signLgn-box">
                    <h1 className="welcome-header">
                        The best way to handle your bills of lading.
                    </h1>
                    <h2 className="welcome-message">
                        <em>
                            --We offer secure file sharing for serving,
                            updating, and live tracking of BOLs and loads that
                            are always available to users.
                        </em>
                    </h2>
                    <div className="about-box"></div>
                    <div className="signLgn-links">
                        <Link className="login-link" to="/login">
                            <button className="login-button">Login</button>
                        </Link>
                    </div>
                </div>
                <div className="border-box"></div>
                <div className="badges-container">
                    <h1>Learn how Connex can serve you. Are you a...</h1>
                    <div className="badge-box">
                        <Link to="/shipper-signup">
                            <div className="badge shipper"></div>
                        </Link>
                        <Link to="/shipper-signup">Shipper</Link>
                    </div>
                    <div className="badge-box">
                        <Link to="/carrier-signup">
                            <div className="badge carrier"></div>
                        </Link>
                        <Link to="/carrier-signup">Carrier</Link>
                    </div>
                    <div className="badge-box">
                        <Link to="/driver-signup">
                            <div className="badge driver"></div>
                        </Link>
                        <Link to="/driver-signup">Driver</Link>
                    </div>

                    <h1 id="start-now">Start using Connex Now </h1>
                    <button className="member-button">
                        <Link className="sign-up-link" to="/become-a-member">
                            Become a Member
                        </Link>
                    </button>
                </div>
            </div>
        );
    }
}

export default connect()(Welcome);
