import * as a from '../actions/index2';
import * as b from '../actions/index3';
import * as c from '../actions/index16';

const state2 = {
    enterPass: null,
    enterUS: null,
    jwt: null,
    display: 'none',
    img: {},
    carrier: null,
    carrierName: null,
    checkbox: null,
    driverLicNum: null,
    connexCarrierId: null,
    display2: 'none',
    message: '',
};

const reducer2 = (state = state2, action) => {
    switch (action.type) {
        case a.SHOW_MENU:
            return Object.assign({}, state, {
                display: action.display,
            });
        case a.ADD_IMG:
            return Object.assign({}, state, {
                img: action.img,
            });
        case a.SET_CARRIER:
            return Object.assign({}, state, {
                carrier: action.carrier,
            });
        case a.CARRIER_NAME:
            return Object.assign({}, state, {
                carrierName: action.name,
            });
        case a.GET_MY_CARRIER:
            return Object.assign({}, state, {
                carrier: action.carrierInfo,
            });
        case b.MARK_CHECK_BOX:
            if (state.checkbox === null) {
                return Object.assign({}, state, {
                    checkbox: 'checked',
                });
            }
            return Object.assign({}, state, {
                checkbox: null,
            });

        case b.UPDATE_LICENSE_NUM:
            return Object.assign({}, state, {
                driverLicNum: action.num,
            });
        case b.SET_CARRIER_ID:
            return Object.assign({}, state, {
                connexCarrierId: action.num,
            });
        case c.TOGGLE_MISSING_FIELD:
            if (state.display2 === 'none') {
                return Object.assign({}, state, {
                    display2: 'block',
                    message: action.message,
                });
            } else {
                return Object.assign({}, state, {
                    display2: 'none',
                });
            }
        default:
            return state;
    }
};

export default reducer2;
