import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import inboundStyles from '../Inbound.module.css';
import { makeStyles } from '@material-ui/core/styles';

export default function OSDInput(props) {
    const { hideAdd, update, type } = props;
    const itemStyles = useItemStyles();
    const qtyStyles = useQtyStyles();
    const [skuVal, setSkuVal] = useState('');
    const [qtyVal, setQtyVal] = useState('');

    return (
        !hideAdd && (
            <div>
                <FormControl classes={itemStyles}>
                    <InputLabel htmlFor={'my-input'}>
                        SKU/Description:
                    </InputLabel>
                    <Input
                        value={skuVal}
                        onChange={e => setSkuVal(e.target.value)}
                        id={`sku-input-${type}`}
                        aria-describedby={'my-helper-text'}
                    />
                </FormControl>
                <FormControl classes={qtyStyles}>
                    <InputLabel htmlFor={'my-input'}>Qty:</InputLabel>
                    <Input
                        type={'number'}
                        value={qtyVal}
                        onChange={e => setQtyVal(e.target.value)}
                        id={`quantity-input-${type}`}
                        aria-describedby={'my-helper-text'}
                    />
                </FormControl>
                <h5
                    onClick={() => {
                        if (skuVal !== '' && qtyVal !== '') {
                            update({ sku: skuVal, qty: qtyVal });
                            setQtyVal('');
                            setSkuVal('');
                        }
                    }}
                    className={`${inboundStyles.osd} link`}>
                    Add
                </h5>
            </div>
        )
    );
}

const useItemStyles = makeStyles({
    root: { width: '200px', marginLeft: '10px', marginRight: '10px' },
});

const useQtyStyles = makeStyles({
    root: { width: '50px', marginLeft: '10px', marginRight: '10px' },
});
