import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Wizard from '../Wizard/Wizard';
import Step1 from '../Wizard/WizardSteps/Step1';
import Step2 from '../Wizard/WizardSteps/Step2';
import Step3 from '../Wizard/WizardSteps/Step3';
import Step4 from '../Wizard/WizardSteps/Step4';
import {
    createLoad,
    updateLoad,
    selectedLoadToEdit,
} from '../../actions/loads-actions';
import history from '../../history';

import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import getLoadIdFromUrl from '../helpers/getLoadIdFromUrl';
import getLoadTypeFromUrl from '../helpers/getLoadTypeFromUrl';
import { build, edit } from '../../strings';

class BuildEditOutboundLoad extends Component {
    state = {
        buildOrEdit: '',
        loadId: undefined,
    };

    componentDidMount() {
        const willBuild = window.location.pathname.includes(
            build.toLowerCase()
        );
        if (willBuild) {
            this.setState({ buildOrEdit: build });
        } else {
            this.setState({ buildOrEdit: edit });
            const loadId = getLoadIdFromUrl();
            this.setState({ loadId });
            const loadType = getLoadTypeFromUrl();
            //todo: decide if you want to do it this way or use a subscription
            //todo: and bypass the redux store for unsubmitted and outbound
            //todo: loads
            this.props.dispatch(
                selectedLoadToEdit(loadId, this.props[loadType])
            );
        }
    }
    render() {
        const { dispatch, shipper, creator } = this.props;
        const { buildOrEdit } = this.state;

        const handleClick = data => {
            //todo: if (buildOrEdit === 'Edit') {
            // * do one set of actions
            // return
            //todo: }
            // console.log('SEE DATA ', data);
            // return;
            const { about, bols, carrier, 'ship-save': shipSave } = data;
            //todo: build if save selected but no carrier selected or bol doesn't have a
            //todo: receiver, then only save the doc DONT mark as shipped
            if (!shipSave.save && !shipSave.ship) {
                return history.push('/home');
            }

            const carrierId = carrier.selectedCompany
                ? carrier.selectedCompany._id
                : undefined;
            //todo: normalize data structure of load
            //todo: remove unnecessary fields
            const load = {
                _id: this.state.loadId,
                shipperId: shipper._id,
                checkin: null,
                checkout: null,
                createdBy: creator._id,
                ready: shipSave.ship,
                deleted: false,
                stops: bols.bols.length,
                carrierId,
                pUDate: moment(about.dateTime).toISOString(),
                pUNum: about.pUNum,
                bols: bols.bols.map(b => {
                    delete b.shipper;
                    delete b.driver;
                    delete b.receiver;
                    return b;
                }),
            };

            // * if a load has a bol and that bol has no destination
            // * it is not ready or if any bols don't have a receiver that bol isn't ready
            if (
                (load.ready && !carrierId) ||
                (load.ready && bols.bols.some(b => b.receiverId == null))
            ) {
                load.ready = false;
                //todo: we might want to pop a modal here letting the user know that they are about
                //todo: to create a load but that it has missing data and will not be
                //todo: marked as ready and placed in their outbound loads
            }

            if (buildOrEdit === build) {
                return dispatch(createLoad(load));
            }
            // * shippers and receivers should not be able to augment these props
            delete load.createdDate;
            delete load.createdBy;
            delete load.drivers;

            return dispatch(updateLoad(load, true));
        };

        return (
            <GridContainer justify={'center'}>
                <GridItem xs={12} sm={10} md={10} lg={10} xl={8}>
                    <Wizard
                        validate
                        steps={[
                            {
                                stepName: 'About',
                                stepComponent: Step1,
                                stepId: 'about',
                            },
                            {
                                stepName: 'BOLs',
                                stepComponent: Step2,
                                stepId: 'bols',
                            },
                            {
                                stepName: 'Carrier',
                                stepComponent: Step3,
                                stepId: 'carrier',
                            },
                            {
                                stepName: 'Ship/Save',
                                stepComponent: Step4,
                                stepId: 'ship-save',
                            },
                        ]}
                        title={`${buildOrEdit} your Outbound Load`}
                        subtitle={'Tell us the basic info about your load'}
                        finishButtonClick={handleClick}
                    />
                </GridItem>
            </GridContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        shipper: state.companiesReducer.companyWithBasicData,
        creator: state.userReducer.user,
        unsubmitted: state.loadsReducer.unsubmitted,
        outbound: state.loadsReducer.outbound,
    };
};

export default connect(mapStateToProps)(BuildEditOutboundLoad);
