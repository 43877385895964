import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import {togglePicModal} from '../../../actions/index16';

class DriverSignUp2 extends React.Component {
	
	state = {
		content:''
	}
	
 	render(){
    
      	return (
			<div className='outter-box'>
			
				<div className='instructions'>
					<p>
						As a Connex Driver you will be connected to 
						your Connex carrier, Connex shippers and your BOLs in real
						time. You are granted read privelages to all of the BOLs
						shared with you by Connex shippers. This means  
						that your bills always safe from loss and readly accessible anytime you 
						need them! Never loose a BOL again.
					</p>
				</div>
				<div className='instructions'>
					<img src='https://connex-imgs.s3.amazonaws.com/connexapp-images/driver-profile.png' alt='driver profile' 
						className='link'
						onClick={() => {
						this.props.dispatch(togglePicModal())
						this.setState({content:<img src='https://connex-imgs.s3.amazonaws.com/connexapp-images/driver-profile.png' alt='create-shipper'/>})
					}}/>
				</div>
				<div className='instructions'> 
					<p>
						As a Connex driver you will be 
						able to update your profile info: truck and trailer number, 
						driver license, etc. You will also be able to print your bills whenever
						you need to!
						<br/>
						So if you're ready to begin click continue below to return to
						return to the Welcome screen where you can choose to 
						become a Connexmember or login.
					</p>
					<Link to='/driversignup'><p style={{marginLeft:0}}>Back</p></Link>
               		<Link to='/#start-now'><p>Continue</p></Link>
				</div>
			</div>
      	)
  	}
}

export default connect()(DriverSignUp2)
