import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import oneReducer from './reducer/index2';
import dataReducer from './reducer';
import thirdReducer from './reducer/index3';

import companiesReducer from './reducer/companies-reducer';
import userReducer from './reducer/user-reducer';
import loadsReducer from './reducer/loads-reducer';
import sharedActionReducer from './reducer/shared-reducer';
import { USER_LOGOUT } from './actions/shared-actions';
import { cleanLocalStorage } from './actions/shared-actions';

const companiesPersistConfig = {
    key: 'companies',
    storage,
};
const userPersistConfig = {
    key: 'user',
    storage,
};
const loadsPersistConfig = {
    key: 'loads',
    storage,
};
const sharedActionsPersistConfig = {
    key: 'sharedActions',
    storage,
};

export const combinedReducer = combineReducers({
    companiesReducer: persistReducer(companiesPersistConfig, companiesReducer),
    userReducer: persistReducer(userPersistConfig, userReducer),
    loadsReducer: persistReducer(loadsPersistConfig, loadsReducer), //! Error storing data DOMException: Failed to execute 'setItem' on 'Storage': Setting the value of 'persist:loads' exceeded the quota. We should fix this some time soon right now the work around will be to call get all loads when the completed view loads if completed array is empty
    sharedActionReducer: persistReducer(
        sharedActionsPersistConfig,
        sharedActionReducer
    ),
    dataReducer,
    oneReducer,
    thirdReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootPersistConfig = {
    key: 'root',
    storage,
};

/**
 * * why https://stackoverflow.com/a/35641992/7693351
 */
function rootReducer(state, action) {
    if (action.type === USER_LOGOUT) {
        cleanLocalStorage();
        state = undefined;
    }
    return combinedReducer(state, action);
}

const presistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = createStore(
    presistedReducer,
    composeEnhancers(applyMiddleware(thunk))
);

export const presistor = persistStore(store);
