import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { togglePicModal } from '../../actions/index16';

import styles from './Shipper.module.css';

export class ShipperSignUp2 extends React.Component {
    state = {
        content: '',
    };

    render() {
        return (
            <div className={styles['outter-box']}>
                <div className={styles.instructions}>
                    <p>
                        To begin using a Connex shipper profile you must first
                        be an active member of the Connex community. If you are
                        not yet a member of the Connex community you will be
                        given the opportunity to become one later.
                    </p>
                    <p>
                        Once inside you will see options to either "Join a
                        team", "Create a carrier profile", "Create a driver
                        profile", or " Create a shipper profile".
                    </p>

                    <p>Choose the Create shipper profile option.</p>
                </div>

                <div className={styles.instructions}>
                    <img
                        src="https://connex-imgs.s3.amazonaws.com/connexapp-images/create-shipper.png"
                        alt="create shipper"
                        className={styles.link}
                        onClick={() => {
                            this.props.dispatch(togglePicModal());
                            this.setState({
                                content: (
                                    <img
                                        src="https://connex-imgs.s3.amazonaws.com/connexapp-images/create-shipper.png"
                                        alt="create-shipper"
                                    />
                                ),
                            });
                        }}
                    />
                </div>
                <div className={styles.instructions}>
                    <p>
                        You will then be asked to enter some basic information
                        pertaining to your company.
                    </p>

                    <p>Once you have completed that you are all set.</p>
                    <Link to="/shippersignup">
                        <p>Back</p>
                    </Link>
                    <Link to="/shippersignup3">
                        <p>Continue</p>
                    </Link>
                </div>
            </div>
        );
    }
}

export default connect()(ShipperSignUp2);
