import React from 'react';
import {connect} from 'react-redux'; 
import DriverLoadInfo from './driverLoadInfo';
import BOLsInfo from './bolsInfo';
import DeleteDriverAccount from './deletedriver'
import {Link} from 'react-router-dom';
import './driver.css'
import avatar from './linkedin-default.png'
import {setIndex} from '../../../actions/index4';
import {getCarrierByLastSix} from '../../../actions/index15';

export class DriverAccountPage extends React.Component{
    componentDidMount() {
        this.props.dispatch(getCarrierByLastSix(this.props.jwt))
    }
    render() {
        let x = this.props;
        let picture;
        let truckCompany;
        let driverInfo;
        let imagePreviewUrl;
        let driverAcctName;
        if (x.info){
            driverAcctName = x.info.accountName
            if  ( x.info.driverLicensePic ) {
                imagePreviewUrl =  x.info.driverLicensePic.imagePreviewUrl;
            }else{imagePreviewUrl = avatar}

            
            driverInfo =
                <div>
                    <h2 className='driver-name'>{x.info.accountName}</h2>
                    <div className="border-box"></div>
                    <h3 className='driver_info'>Driver Info</h3>
                    <ul>
                        <li>Connex Driver Id: <span className='driver_id'>{x.info._id}</span></li>
                        <li>Phone: {x.info.userId.phone}</li>
                        <li>Email: <span className='blue_email'>{x.info.userId.username}</span></li>
                        <li>Truck #: {x.info.truckNum}</li>
                        <li>Trailer #: {x.info.trlNum}</li>
                    </ul>
                </div>
            if (x.info.carrierId) {
               truckCompany = 
                    <div className='truck-company-info'>
                        <h3>Carrier Info</h3>
                        <ul>
                            <li>{x.info.carrierId.carrierName}</li>
                            <li>{x.info.carrierId.phone}</li>
                            <li>{x.info.carrierId.email}</li>
                        </ul>
                        <div className='border-box'></div>
                    </div> 
            }
            else{  truckCompany = <h5>No Carrier Info Available</h5>}
        }
        
        let $imagePreview = null;
        if (imagePreviewUrl) {
          $imagePreview = (<img alt='file to upload' className='driver-photo' src={imagePreviewUrl} />);
        
            picture = 
                <div className='driver-pic-div'>
                    
                    {$imagePreview} 
                </div>
        }
        return (
            <div className='driver-info-box'>
                {driverInfo}
                {picture}
                <div className="border-box picture-border"></div>
                {truckCompany}
                <Link to={`/driverAcct/${driverAcctName}/edit-profile`}><span>Edit Profile </span></Link>
                <div className="border-box edit-border"></div>
                <DriverLoadInfo />
                <BOLsInfo />
                <DeleteDriverAccount />
            </div>
        )    
    }

    componentWillUnmount() {
            this.props.dispatch(setIndex(null))
    }

}
export const mapStateToProps = state => {
    return { 
        jwt:state.dataReducer.jwt,
        info:state.fourReducer.mySelectedDriver
    }
}
export default connect(mapStateToProps)(DriverAccountPage)

