import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import styles from './Wizard.module.css';
import Button from '../CustomButtons/Button';
import Card from '..//Card/Card';

import wizardStyle from './wizardStyle';
import { loaderDisplayChange } from '../../actions/shared-actions';
import { setBolsAndCarrierSelected } from '../../actions/loads-actions';

class Wizard extends Component {
    state = {
        currentStep: 0,
        isFinished: false,
        color: this.props.color,
        nextButton: this.props.steps.length > 1 ? true : false,
        previousButton: false,
        finishButton: this.props.steps.length === 1 ? true : false,
        width: '100%',
        movingTabStyle: {
            transition: 'transform 0s',
        },
        allStates: {},
    };

    wizard = createRef();

    componentDidMount() {
        this.setState({ width: this.getWidth(this.props.steps) });
        this.refreshAnimation(0);
        window.addEventListener('resize', this.updateWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWidth);
        this.props.dispatch(setBolsAndCarrierSelected([], null));
    }

    getWidth = steps => {
        let width;
        const lengthOfSteps = steps.length;
        if (lengthOfSteps === 1) {
            width = '100%';
        } else {
            if (window.innerWidth < 600) {
                if (lengthOfSteps !== 3) {
                    width = '50%';
                } else {
                    width = 100 / 3 + '%';
                }
            } else {
                if (lengthOfSteps === 2) {
                    width = '50%';
                } else {
                    width = 100 / 3 + '%';
                }
            }
        }
        return width;
    };

    updateWidth = () => {
        this.refreshAnimation(this.state.currentStep);
    };

    navigationStepChange = key => {
        if (this.props.steps) {
            var validationState = true;
            if (key > this.state.currentStep) {
                for (let i = this.state.currentStep; i < key; i++) {
                    if (
                        this[this.props.steps[i].stepId].sendState !== undefined
                    ) {
                        this.setState({
                            allStates: {
                                ...this.state.allStates,
                                ...this.props.steps.reduce((p, curr, i) => {
                                    p[curr.stepId] = this[
                                        curr.stepId
                                    ].sendState();
                                    return p;
                                }, {}),
                                // [this.props.steps[i].stepId]: this[
                                //     this.props.steps[i].stepId
                                // ].sendState(),
                            },
                        });
                    }
                    if (
                        this[this.props.steps[i].stepId].isValidated !==
                            undefined &&
                        this[this.props.steps[i].stepId].isValidated() === false
                    ) {
                        validationState = false;
                        break;
                    }
                }
            }
            if (validationState) {
                this.setState({
                    currentStep: key,
                    nextButton:
                        this.props.steps.length > key + 1 ? true : false,
                    previousButton: key > 0 ? true : false,
                    finishButton:
                        this.props.steps.length === key + 1 ? true : false,
                });
                this.refreshAnimation(key);
            }
        }
    };

    nextButtonClick = () => {
        const currentStepId = this.props.steps[this.state.currentStep].stepId;
        const currentStepIdIsValidated = this[currentStepId].isValidated;
        if (
            (this.props.validate &&
                ((currentStepIdIsValidated !== undefined &&
                    this[currentStepId].isValidated()) ||
                    currentStepIdIsValidated === undefined)) ||
            this.props.validate === undefined
        ) {
            if (this[currentStepId].sendState !== undefined) {
                this.setState({
                    allStates: {
                        ...this.state.allStates,
                        [this.props.steps[this.state.currentStep].stepId]: this[
                            this.props.steps[this.state.currentStep].stepId
                        ].sendState(),
                    },
                });
            }
            const key = this.state.currentStep + 1;
            this.setState({
                currentStep: key,
                nextButton: this.props.steps.length > key + 1 ? true : false,
                previousButton: key > 0 ? true : false,
                finishButton:
                    this.props.steps.length === key + 1 ? true : false,
            });
            this.refreshAnimation(key);
        }
    };

    previousButtonClick = () => {
        if (
            this[this.props.steps[this.state.currentStep].stepId].sendState !==
            undefined
        ) {
            this.setState({
                allStates: {
                    ...this.state.allStates,
                    [this.props.steps[this.state.currentStep].stepId]: this[
                        this.props.steps[this.state.currentStep].stepId
                    ].sendState(),
                },
            });
        }
        var key = this.state.currentStep - 1;
        if (key >= 0) {
            this.setState({
                currentStep: key,
                nextButton: this.props.steps.length > key + 1 ? true : false,
                previousButton: key > 0 ? true : false,
                finishButton:
                    this.props.steps.length === key + 1 ? true : false,
            });
            this.refreshAnimation(key);
        }
    };

    finishButtonClick = () => {
        this.props.dispatch(loaderDisplayChange('block'));
        const isNotValidated = this.props.validate === false;
        const finishedNotClicked = this.props.finishButtonClick !== undefined;
        const notValidatedFinishedNotClicked =
            isNotValidated && finishedNotClicked;
        const currentStepId = this.props.steps[this.state.currentStep].stepId;
        const curStepValidatedMethod = this[currentStepId].isValidated;
        const currentStepIsValidated =
            curStepValidatedMethod !== undefined && curStepValidatedMethod();
        const propValidate = this.props.validate;
        const curStepNoValidateMethod = curStepValidatedMethod === undefined;
        const currStepIsValidatedOrHasNoValidateMethod =
            currentStepIsValidated || curStepNoValidateMethod;
        if (
            notValidatedFinishedNotClicked ||
            (propValidate &&
                currStepIsValidatedOrHasNoValidateMethod &&
                finishedNotClicked)
        ) {
            this.setState(
                {
                    allStates: {
                        ...this.state.allStates,
                        [currentStepId]: this[currentStepId].sendState(),
                    },
                },
                () => {
                    this.props.finishButtonClick(this.state.allStates);
                }
            );
        }
    };

    refreshAnimation = index => {
        var total = this.props.steps.length;
        var li_width = 100 / total;
        var total_steps = this.props.steps.length;
        var move_distance =
            this.wizard.current.children[0].offsetWidth / total_steps;
        var index_temp = index;
        var vertical_level = 0;

        var mobile_device = window.innerWidth < 600 && total > 3;

        if (mobile_device) {
            move_distance = this.wizard.current.children[0].offsetWidth / 2;
            index_temp = index % 2;
            li_width = 50;
        }

        this.setState({ width: li_width + '%' });

        var step_width = move_distance;
        move_distance = move_distance * index_temp;

        var current = index + 1;

        if (current === 1 || (mobile_device === true && index % 2 === 0)) {
            move_distance -= 8;
        } else if (
            current === total_steps ||
            (mobile_device === true && index % 2 === 1)
        ) {
            move_distance += 8;
        }

        if (mobile_device) {
            vertical_level = parseInt(index / 2, 10);
            vertical_level = vertical_level * 38;
        }
        var movingTabStyle = {
            width: step_width,
            transform:
                'translate3d(' +
                move_distance +
                'px, ' +
                vertical_level +
                'px, 0)',
            transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)',
        };
        this.setState({ movingTabStyle: movingTabStyle });
    };

    render() {
        const { classes, title, subtitle, steps } = this.props;
        return (
            <div className={classes.wizardContainer} ref={this.wizard}>
                <Card className={classes.card}>
                    <div className={classes.wizardHeader}>
                        <h3 className={classes.title}>{title}</h3>
                        <h5 className={classes.subtitle}>{subtitle}</h5>
                    </div>
                    <div className={classes.wizardNavigation}>
                        <ul className={classes.nav}>
                            {steps.map((prop, key) => {
                                return (
                                    <li
                                        className={classes.steps}
                                        key={key}
                                        style={{ width: this.state.width }}>
                                        <a
                                            href="#pablo"
                                            className={classes.stepsAnchor}
                                            onClick={e => {
                                                e.preventDefault();
                                                this.navigationStepChange(key);
                                            }}>
                                            {prop.stepName}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                        <div
                            className={`${classes.movingTab} ${styles['wizard-slider']}`}
                            style={this.state.movingTabStyle}>
                            {steps[this.state.currentStep].stepName}
                        </div>
                    </div>
                    <div className={classes.content}>
                        {steps.map((prop, key) => {
                            const stepContentClasses = cx({
                                [classes.stepContentActive]:
                                    this.state.currentStep === key,
                                [classes.stepContent]:
                                    this.state.currentStep !== key,
                            });
                            return (
                                <div className={stepContentClasses} key={key}>
                                    <prop.stepComponent
                                        innerRef={node =>
                                            (this[prop.stepId] = node)
                                        }
                                        allStates={this.state.allStates}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className={classes.footer}>
                        <div className={classes.left}>
                            {this.state.previousButton ? (
                                <Button
                                    className={this.props.previousButtonClasses}
                                    onClick={() => this.previousButtonClick()}>
                                    {this.props.previousButtonText}
                                </Button>
                            ) : null}
                        </div>
                        <div className={classes.right}>
                            {this.state.nextButton ? (
                                <Button
                                    className={`${this.props.nextButtonClasses} ${styles['next-button']}`}
                                    onClick={() => this.nextButtonClick()}>
                                    {this.props.nextButtonText}
                                </Button>
                            ) : null}
                            {this.state.finishButton ? (
                                <Button
                                    // disabled={this.state.isFinished}
                                    className={`${this.finishButtonClasses} ${styles['finish-button']}`}
                                    onClick={() => this.finishButtonClick()}>
                                    {this.props.finishButtonText}
                                </Button>
                            ) : null}
                        </div>
                        <div className={classes.clearfix} />
                    </div>
                </Card>
            </div>
        );
    }
}

Wizard.defaultProps = {
    color: 'rose',
    title: 'Here should go your title',
    subtitle: 'And this would be your subtitle',
    previousButtonText: 'Previous',
    previousButtonClasses: '',
    nextButtonClasses: '',
    nextButtonText: 'Next',
    finishButtonClasses: '',
    finishButtonText: 'Finish',
};

Wizard.propTypes = {
    classes: PropTypes.object.isRequired,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            stepName: PropTypes.string.isRequired,
            stepComponent: PropTypes.object.isRequired,
            stepId: PropTypes.string.isRequired,
        })
    ).isRequired,
    color: PropTypes.oneOf([
        'primary',
        'warning',
        'danger',
        'success',
        'info',
        'rose',
    ]),
    title: PropTypes.string,
    subtitle: PropTypes.string,
    previousButtonClasses: PropTypes.string,
    previousButtonText: PropTypes.string,
    nextButtonClasses: PropTypes.string,
    nextButtonText: PropTypes.string,
    finishButtonClasses: PropTypes.string,
    finishButtonText: PropTypes.string,
    finishButtonClick: PropTypes.func,
    validate: PropTypes.bool,
};

const styledWizard = withStyles(wizardStyle)(Wizard);

export default connect()(styledWizard);
