import React from 'react';
import Button from '@material-ui/core/Button';
import styles from './Steps.module.css';
import { viewUploadedBol } from '../../../actions/bol-actions';
import { clearInput } from '../../../actions/shared-actions';

export default function SubmitOneBolButton({ disabled, handleSubmitOneBol }) {
    const emptyBol = {
        file: null,
        imagePreviewUrl: null,
    };
    return (
        //! this button should not reappear if
        //! a user clicks on a submitted bol that,
        //! has NOT been edited.
        //! it should only appear on bols that haven't been
        //! submitted or that have ben edited since they
        //! were submitted.
        //Todo: fix the above
        <Button
            disabled={disabled}
            variant={'contained'}
            className={`${styles['margin-auto']}`}
            onClick={() => {
                handleSubmitOneBol();
                viewUploadedBol.next(emptyBol);
                clearInput.next();
            }}>
            Submit This BOL
        </Button>
    );
}
