import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchUsers, usersReceived } from '../../actions/user-actions';
import { clearInput } from '../../actions/shared-actions';

class UsersAutocompleteInput extends Component {
    state = {
        open: false,
        options: [],
        searchableText: '',
        loading: false,
    };

    clearInputSubscription = clearInput.subscribe({
        next: () => {
            const selector =
                'div.MuiAutocomplete-endAdornment > button.MuiAutocomplete-clearIndicatorDirty';
            const element = document.querySelector(selector);
            element && element.click();
        },
    });

    usersReceivedSubscription = usersReceived.subscribe({
        next: options => {
            this.setState({
                options,
                loading: false,
            });
        },
    });

    componentDidMount() {
        const input = fromEvent(
            document.getElementById(`async-input-${this.props.id}`),
            'keyup'
        );
        const searchText = input.pipe(debounceTime(500));
        searchText.subscribe(e => {
            this.setState({
                open: true,
                loading: true,
                searchableText: e.target.value,
                options: [],
            });
            this.fetchUsers();
        });
    }

    fetchUsers = () => {
        const searchText = this.state.searchableText;
        if (searchText === '')
            return this.setState({ options: [], loading: false });
        this.props.dispatch(
            fetchUsers(searchText, this.props.role, this.props.searchAll)
        );
    };

    componentWillUnmount() {
        this.props.onUserSelected(null);
        this.clearInputSubscription.unsubscribe();
        this.usersReceivedSubscription.unsubscribe();
    }

    render() {
        const { open, options, loading } = this.state;
        return (
            <Autocomplete
                id={`async-input-${this.props.id}`}
                disabled={this.props.disabled}
                style={{ width: 300, margin: 'auto' }}
                open={open}
                onOpen={() => {
                    this.setState({ open: true });
                }}
                onChange={(e, value) => {
                    e.stopPropagation();
                    this.props.onUserSelected(value);
                }}
                onClose={() => {
                    this.setState({ open: false });
                }}
                getOptionSelected={(option, value) =>
                    option.name === value.name
                }
                getOptionLabel={option =>
                    `${option.name} -- ${option.connexId.toUpperCase()}`
                }
                filterOptions={o => o} // * this solved the issue of the options not "refreshing"
                options={options}
                loading={loading}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={this.props.label}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? (
                                        <CircularProgress
                                            color={'inherit'}
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        );
    }
}

export default connect()(UsersAutocompleteInput);
