import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';

import { H2, H5 } from '../helpers/Headings';
import UsersAutocompleteInput from '../AutocompleteInput/UsersAutoCompleteInput';
import { assignDriverToAll } from '../../actions/bol-actions';
import styles from './CarrierLoad.module.css';
import appStyles from '../App.module.css';

class AssignDriver extends Component {
    state = {
        driver: null,
    };

    selectDriver = driver => this.setState({ driver });

    render() {
        const {
            bols,
            all,
            dispatch,
            companyId,
            changeDriver,
            single,
        } = this.props;
        const { driver } = this.state;
        let heading = 'assign driver';
        let subhead = 'assign driver to this bol';
        if (all) {
            subhead =
                '*assigning a driver here will assign the driver to all bols';
        }

        if (changeDriver) {
            subhead = single
                ? 'change driver for this bol'
                : 'change driver for all';
            heading = 'change driver';
        }

        return (
            <>
                <H2 className={styles['no-margin-bottom']} heading={heading} />
                <H5 heading={subhead} />
                <hr className={appStyles.rule} />
                <UsersAutocompleteInput
                    role={3}
                    label={'Drivers'}
                    disabled={false}
                    id={'carrier-users'}
                    onUserSelected={this.selectDriver}
                />
                <Button
                    disabled={driver === null}
                    onClick={e => {
                        if (driver === null) return; // * extra layer of protection
                        dispatch(
                            assignDriverToAll(
                                bols.map(b => b._id),
                                {
                                    driverId: driver._id,
                                },
                                companyId
                            )
                        );
                    }}>
                    assign to driver
                </Button>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.companiesReducer.companyWithBasicData._id,
    };
};
export default connect(mapStateToProps)(AssignDriver);
