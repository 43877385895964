import React, { useEffect } from 'react';

import { Router, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { routes, redirects } from './routes';

import history from '../history';

import Loader from './loader';
import NotFound from './NotFound';
import Footer from './Footer/Footer';
import Sidenav from './Sidenav/Sidenav';
import SnackBar from './SnackBar/SnackBar';
import ScrollTop from './ScrollTop';
import DeveloperBar from './Footer/DeveloperBar';
import GlobalModal from './GlobalModal/GlobalModal';
import NavigationBar from './NavigationBar/NavigationBar';

import { loaderDisplayChange } from '../actions/shared-actions';

import './scss/material-dashboard-pro-react.scss?v=1.9.0';
import './App.module.css';

export default function App() {
    const user = useSelector(state => state.userReducer.user);
    const dispatch = useDispatch();
    const loaderDisplay = useSelector(
        state => state.sharedActionReducer.loaderDisplay
    );

    useEffect(() => {
        if (loaderDisplay === 'block' && window.location.pathname === '/') {
            dispatch(loaderDisplayChange('none'));
        }
    });
    const company = useSelector(
        state => state.companiesReducer.companyWithBasicData
    );
    return (
        <Router history={history}>
            <ScrollTop />
            <div id={'app'} className={'App'} style={{ position: 'relative' }}>
                <Sidenav />
                <NavigationBar />

                <Switch>
                    {redirects(user.role, company.type)}
                    {routes(user.role, company.type)}
                    <Route component={NotFound} />
                </Switch>

                <Loader />
                <Footer />
                <DeveloperBar />
                <GlobalModal />
                <SnackBar />
            </div>
        </Router>
    );
}
