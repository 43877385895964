import React, { Component } from 'react';
import { connect } from 'react-redux';
import Edit from '@material-ui/icons/Edit';

import { H2, H3 } from '../helpers/Headings';
import { phoneRegEx, emailRegEx } from '../helpers/regEx';
import { updateUser } from '../../actions/user-actions';

import Button from '../CustomButtons/Button';
import CustomInput from '../CustomInput/CustomInput.js';
import formatPhoneNumber from '../helpers/formatPhoneNumber';
import ChangePassword from '../Password/ChangePassword';

import appStyles from '.././App.module.css';
import styles from './InfoComponents.module.css';

class UserInfo extends Component {
    //todo: show user image
    state = {
        editMode: false,
        firstName: '',
        lastName: '',
        phone: '',
        username: '',
        showChangePassword: false,
    };

    componentDidMount() {
        const { user } = this.props;
        this.setState({
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            phone: user.phone || '',
            username: user.username,
        });
    }

    componentDidUpdate(prevProps) {
        const { user } = this.props;
        if (user.firstName !== prevProps.user.firstName) {
            this.setState({
                firstName: user.firstName,
                lastName: user.lastName,
                phone: user.phone,
                username: user.username,
            });
        }
    }

    submit = () => {
        this.props.dispatch(
            updateUser({
                id: this.props.user._id,
                update: {
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    phone: this.state.phone,
                },
            })
        );
        this.toggleEdit();
    };

    toggleShowChangePassword = () =>
        this.setState({ showChangePassword: !this.state.showChangePassword });

    toggleEdit = () =>
        this.setState({
            editMode: !this.state.editMode,
        });

    render() {
        const { user, heading, showEdit, onDirectory } = this.props;
        const {
            editMode,
            firstName,
            lastName,
            phone,
            username,
            showChangePassword,
        } = this.state;

        let connexId = '';
        let name = '';
        let email = '';

        if (user) {
            const { _id, name: n, email: e, connexId: cId } = user;
            email = e;
            if (cId) {
                //todo: consoder doing this on the backend
                connexId = cId; //* sometimes our backend will send this back for us
            } else {
                connexId = (_id || '').substring(18, 24); //* you need this cause you can't take .substring(...) of undefined
            }

            if (n) {
                name = n;
            } else {
                name = `${firstName} ${lastName}`;
            }
        }
        //Todo: find which one of these links is throwing a warining
        //todo: warning is invalid prop `success` of type `array` supplied to `CustomInput`
        return (
            <>
                <div className={styles['heading-wrapper']}>
                    <H2 heading={heading} />
                    {!onDirectory && showEdit && (
                        <Edit
                            onClick={this.toggleEdit}
                            className={styles['edit-icon']}
                        />
                    )}
                    <hr className={appStyles.rule} />
                </div>
                {!editMode && (
                    <div>
                        <H3 heading={name} />
                        <p>{`ConnexId: ${connexId}`}</p>
                        <p>{formatPhoneNumber(phone)}</p>
                        <p>{email}</p>
                    </div>
                )}
                {editMode && (
                    <div className={styles['edit-user-info']}>
                        <CustomInput
                            value={firstName}
                            labelText={'first name'}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            id={'first-name-input'}
                            error={this.state.firstName.trim() === ''}
                            success={this.state.firstName.trim().length >= 2}
                            inputProps={{
                                onChange: e => {
                                    this.setState({
                                        firstName: e.target.value.trim(),
                                    });
                                },
                            }}
                        />
                        <CustomInput
                            value={lastName}
                            labelText={'last name'}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            id={'last-name-input'}
                            error={this.state.lastName.trim() === ''}
                            success={this.state.lastName.trim().length >= 2}
                            inputProps={{
                                onChange: e => {
                                    this.setState({
                                        lastName: e.target.value.trim(),
                                    });
                                },
                            }}
                        />
                        <CustomInput
                            value={phone}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            labelText={'phone'}
                            id={'phone-input'}
                            error={!phone.match(phoneRegEx)}
                            success={phone.match(phoneRegEx)}
                            inputProps={{
                                onChange: e => {
                                    this.setState({
                                        phone: e.target.value.trim(),
                                    });
                                },
                            }}
                        />
                        <CustomInput
                            value={username}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            labelText={'email'}
                            id={'email-input'}
                            error={!username.match(emailRegEx)}
                            success={username.match(emailRegEx)}
                            inputProps={{
                                onChange: e => {
                                    this.setState({
                                        username: e.target.value.trim(),
                                    });
                                },
                            }}
                        />
                        <Button
                            children={'save'}
                            onClick={this.submit}
                            className={styles['submit-button']}
                        />
                        <p
                            onClick={this.toggleShowChangePassword}
                            className={`${styles.block} ${styles.link} ${styles['change-password']}`}>
                            Change Password
                        </p>
                        {showChangePassword && (
                            <ChangePassword
                                userId={this.props.user._id}
                                toggleChangePassword={
                                    this.toggleShowChangePassword
                                }
                            />
                        )}
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = state => ({
    userBeingViewed: state.userReducer.userBeingViewed,
});
export default connect(mapStateToProps)(UserInfo);
