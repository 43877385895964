import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import CardFooter from '../../Card/CardFooter';
import GridItem from '../../Grid/GridItem';
import styles from '../dashboardStyle';
import widgetCssModule from './DashboardWidget.module.css';

const useStyles = makeStyles(styles);

const DashboardWidget = props => {
    const classes = useStyles();

    const {
        color,
        footerText,
        icon,
        loads,
        category,
        background,
        changeTable,
        totalLoads,
    } = props;

    return (
        <GridItem
            className={widgetCssModule['widget']}
            onClick={e => {
                e.preventDefault();
                changeTable({
                    icon,
                    color,
                    background,
                    category,
                    loads,
                });
            }}>
            <Card>
                <CardHeader color={color} stats icon>
                    <CardIcon color={color} style={background}>
                        {icon}
                    </CardIcon>
                    <p className={classes.cardCategory}>{category}</p>
                    <h3 className={classes.cardTitle}>{totalLoads}</h3>
                </CardHeader>
                <CardFooter stats>
                    <div className={classes.stats}>{footerText}</div>
                </CardFooter>
            </Card>
        </GridItem>
    );
};

export default DashboardWidget;
