import React from 'react';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DoneIcon from '@material-ui/icons/Done';
import Store from '@material-ui/icons/Store';

import DashboardWidget from './DashboardWidget';

export const shipperWidgets = [
    {
        color: 'info',
        className: 'inbound-icon',
        footerText: 'What we are receiving',
        category: 'Inbound',
        title: '',
        icon: <Store />,
        background: { background: 'linear-gradient(60deg, #26c6da, #00365b)' },
    },
    {
        color: 'warning',
        className: 'outbound-icon',
        footerText: 'What we are shipping',
        category: 'Outbound',
        title: '',
        icon: <LocalShippingIcon />,
        background: { background: 'linear-gradient(60deg, #ffa726, #af6a13)' },
    },
    {
        color: 'danger',
        className: 'unsubmitted-icon',
        footerText: 'Loads not ready to be shipped',
        category: 'Unsubmitted',
        title: '',
        background: { background: 'linear-gradient(60deg, #ef5350, #9e1c19)' },
        icon: <ErrorOutlineIcon />,
    },
    {
        color: 'success',
        className: 'completed-icon',
        footerText: 'Completed Loads (can have duplicate ids)',
        category: 'Completed',
        title: '',
        background: { background: 'linear-gradient(60deg, #66bb6a, #145d17)' },
        icon: <DoneIcon />,
    },
];

const carrierWidgets = [
    {
        color: 'warning',
        className: 'outbound-icon',
        footerText: 'What we are carrying',
        category: 'Shipping',
        title: '',
        icon: <LocalShippingIcon />,
        background: { background: 'linear-gradient(60deg, #ffa726, #af6a13)' },
    },
    {
        color: 'success',
        className: 'completed-icon',
        footerText: 'Completed Loads',
        category: 'Completed',
        title: '',
        background: { background: 'linear-gradient(60deg, #66bb6a, #145d17)' },
        icon: <DoneIcon />,
    },
];

export const defaultIconProps = ({ carrierView, driverView }) => {
    let widgetProp;

    if (carrierView || driverView) {
        widgetProp = carrierWidgets[0];
    } else {
        widgetProp = shipperWidgets[0];
    }
    return {
        icon: widgetProp.icon,
        color: widgetProp.color,
        background: widgetProp.background,
        category: widgetProp.category,
    };
};

export default function createWidgets(changeTable, info) {
    let widgets;
    if (info.carrierView || info.driverView) {
        widgets = carrierWidgets;
    } else {
        widgets = shipperWidgets;
    }
    return widgets.map(w => {
        if (w.category === 'Unsubmitted') {
            return (
                <DashboardWidget
                    key={w.category}
                    loads={info.unsubmitted}
                    totalLoads={info.unsubmittedTotal}
                    footerText={w.footerText}
                    category={w.category}
                    icon={w.icon}
                    color={w.color}
                    background={w.background}
                    changeTable={changeTable}
                />
            );
        }

        if (w.category === 'Inbound') {
            return (
                <DashboardWidget
                    key={w.category}
                    loads={info.inbound}
                    totalLoads={info.inboundTotal}
                    footerText={w.footerText}
                    category={w.category}
                    icon={w.icon}
                    color={w.color}
                    background={w.background}
                    changeTable={changeTable}
                />
            );
        }

        if (w.category === 'Outbound' || w.category === 'Shipping') {
            return (
                <DashboardWidget
                    key={w.category}
                    loads={info.outbound}
                    totalLoads={info.outboundTotal}
                    footerText={w.footerText}
                    category={w.category}
                    icon={w.icon}
                    color={w.color}
                    background={w.background}
                    changeTable={changeTable}
                />
            );
        }

        if (w.category === 'Completed') {
            return (
                <DashboardWidget
                    key={w.category}
                    loads={info.completed}
                    totalLoads={info.completedTotal}
                    footerText={w.footerText}
                    category={w.category}
                    icon={w.icon}
                    color={w.color}
                    background={w.background}
                    changeTable={changeTable}
                />
            );
        }

        return '';
    });
}
