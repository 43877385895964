import request from 'superagent';

export const SELECTED_MY_CARRIER = 'SELECTED_MY_CARRIER';
export const selectMyCarrier = carrierInfo => ({
    type: SELECTED_MY_CARRIER,
    carrierInfo,
});

export const findCarrierPopulate = (carrierId, jwt) => dispatch => {
    console.log('LOGGING FROM FROM findCarrierPopulate');
    // dispatch(loaderDisplayChange('block'));
    // request
    //     .get('/api/companies/users/loads')
    //     .set('Accept', 'application/json')
    //     .set('Content-Type', 'application/json')
    //     .then(res => {
    //         const { unsubmittedLoads } = res.body;
    //         dispatch(selectMyShpRcr(res.body));
    //         dispatch(myUnsubmittedLoads(unsubmittedLoads));
    //         dispatch(selectMyShpRcr2(res.body));
    //         dispatch(loaderDisplayChange('none'));
    //         window.location = `/shipper-account/${res.body.name}/`;
    //     })
    //     .catch();
};

export const addDriverToCarrier = (carrierId, jwt, driverId) => dispatch => {
    let obj = { drivers: driverId };

    request
        .put(`/api/connex/carriers/addDriver/${carrierId}`)

        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .send(JSON.stringify(obj))
        .then(res => {
            if (res.ok) {
                dispatch(findCarrierPopulate(carrierId));
            } else {
                Promise.reject(res.statusText);
            }
        })
        .catch(err => console.log(err));
};

export const DRIVER_TO_ADD = 'DRIVER_TO_ADD';
export const driverToAdd = id => ({
    type: DRIVER_TO_ADD,
    id,
});

export const HAMBURGER_DISPLAY = 'HAMBURGER_DISPLAY';
export const hamburgerDisplay = () => ({
    type: HAMBURGER_DISPLAY,
});

export const SELECTED_MY_DRIVER = 'SELECTED_MY_DRIVER';
export const selectMyDriver = driverInfo => ({
    type: SELECTED_MY_DRIVER,
    driverInfo,
});

export const findDriverPopulate = (driverId, jwt) => dispatch => {
    request
        .get(`/api/connex/drivers/populate/${driverId}`)

        .then(res => {
            if (res.ok) {
                dispatch(selectMyDriver(res.body));
            } else {
                Promise.reject(res.statusText);
            }
        })
        .catch(err => {
            console.error(err);
        });
};

export const SELECTED_MY_SHIPPER_RECEIVER = 'SELECTED_MY_SHIPPER_RECEIVER';
export const selectMyShpRcr = shpRcrInfo => ({
    type: SELECTED_MY_SHIPPER_RECEIVER,
    shpRcrInfo,
});

export const findShpRcrPopulate = (shpRcrId, jwt) => dispatch => {
    return new Promise((resolve, reject) => {
        return request
            .get(`/api/connex/shippers/populate/${shpRcrId}`)

            .then(res => {
                if (res.ok) {
                    dispatch(selectMyShpRcr(res.body));
                    resolve();
                } else {
                    reject(res);
                }
            })
            .then(res => {
                if (res) {
                    console.log(`Response from server: ${res}`);
                } else {
                    console.log("Lookin' good! 👨🏾‍");
                }
            })
            .catch(err => {
                console.log(err);
            });
    });
};

export const SELECTED_MY_SHIPPER_RECEIVER2 = 'SELECTED_MY_SHIPPER_RECEIVER2';
export const selectMyShpRcr2 = shpRcrInfo => ({
    type: SELECTED_MY_SHIPPER_RECEIVER2,
    shpRcrInfo,
});

export const findShpRcrPopulate2 = (shpRcrId, jwt) => dispatch => {
    return new Promise((resolve, reject) => {
        return request
            .get(`/api/connex/shippers/populate2/${shpRcrId}`)

            .then(res => {
                if (res.ok) {
                    dispatch(selectMyShpRcr2(res.body));
                    resolve();
                } else {
                    reject(res);
                }
            })
            .then(res => {
                if (res) {
                    console.log(`Response from server: ${res}`);
                } else {
                    console.log("Lookin' good! 👨🏾‍");
                }
            })
            .catch(err => {
                console.log(err);
            });
    });
};

export const shipperData = (shpRcrId, jwt, acct, userID) => dispatch => {
    console.log('LOGGING FROM SHIPPER DATA ');
    // dispatch(loaderDisplayChange('block'));
    // request
    //     .get('/api/companies/users/loads')
    //     .set('Accept', 'application/json')
    //     .set('Content-Type', 'application/json')
    //     .then(res => {
    //         const { unsubmittedLoads } = res.body;
    //         dispatch(selectMyShpRcr(res.body));
    //         dispatch(selectMyShpRcr2(res.body));
    //         dispatch(myUnsubmittedLoads(unsubmittedLoads));
    //         dispatch(loaderDisplayChange('none'));
    //         window.location = `/shipper-account/${acct}/`;
    //     });
};
