import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import GridContainer from './../../../Grid/GridContainer';
import GridItem from './../../../Grid/GridItem';

import Profile from '../Profile/Profile';

import styles from './Directory.module.css';
import { viewUser } from '../../../../actions/user-actions';

const useStyles = makeStyles(theme => {
    return {
        root: {
            margin: '0 20px',
            backgroundColor: theme.palette.grey.A100,
            maxHeight: 600,
            overflow: 'auto',
        },
    };
});

const useTextStyles = makeStyles(theme => {
    return {
        root: {
            maxWidth: '45%',
        },
    };
});

export default function Directory() {
    const dispatch = useDispatch();
    const { admin, drivers, clerks } = useSelector(
        state => state.companiesReducer.companyWithAllData
    );
    const { role } = useSelector(state => state.userReducer.user);

    const classes = useStyles();
    const textClasses = useTextStyles();
    const [checked, setChecked] = React.useState([]);
    const [chosenUser, setChosenUser] = useState(null);
    const [gridOneProps, setGridOneProps] = useState({ xs: 12, sm: 8, md: 6 });
    const [gridTwoProps, setGridTwoProps] = useState({
        className: styles.hide,
    });

    useEffect(() => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 650 && chosenUser) {
            setGridOneProps({ className: styles.hide });
            setGridTwoProps({ xs: 12 });
        }

        if (windowWidth >= 650 && chosenUser) {
            setGridOneProps({ sm: 6 });
            setGridTwoProps({ sm: 6 });
        }

        if (!chosenUser) {
            setGridOneProps({ xs: 12, sm: 8, md: 6 });
            setGridTwoProps({ className: styles.hide });
        }
    }, [chosenUser]);

    const handleChose = person => {
        dispatch(viewUser(person));
        setChosenUser(person);
    };

    const handleToggle = value => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleRemove = () => {
        console.log('feature coming soon!', checked);
    };

    return (
        <GridContainer justify={'center'}>
            <GridItem {...gridOneProps}>
                <div className={styles['header-box']}>
                    <div className={'MuiListItemAvatar-root'}></div>
                    <div className={'MuiListItemText-root'}>Name</div>
                    <div className={'MuiListItemText-root'}>Title</div>
                    <div
                        onClick={handleRemove}
                        className={`${styles['remove-header']} ${
                            role !== 1 && styles.hide
                        }`}>
                        Remove
                    </div>
                </div>
                <div className={`${classes.root}`}>
                    <List>
                        {[...admin, ...drivers, ...clerks].map((person, i) => {
                            const labelId = `checkbox-list-secondary-label-${i}`;
                            return (
                                <ListItem
                                    key={person._id}
                                    button
                                    onClick={e => {
                                        e.stopPropagation();
                                        handleChose(person);
                                    }}>
                                    <ListItemAvatar>
                                        <Avatar
                                            alt={`Avatar n°${person.image + 1}`}
                                            src={person.img}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        className={`${textClasses.root} ${styles['list-item']}`}
                                        id={person.name}
                                        primary={person.name}
                                    />
                                    <ListItemText
                                        id={'title'}
                                        primary={getTitle(person.role)}
                                    />
                                    <ListItemSecondaryAction
                                        className={
                                            role !== 1 ? styles.hide : ''
                                        }>
                                        <Checkbox
                                            edge="end"
                                            onChange={e => {
                                                e.stopPropagation();
                                                handleToggle(i);
                                            }}
                                            checked={checked.indexOf(i) !== -1}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                </div>
            </GridItem>

            <GridItem {...gridTwoProps}>
                <Profile
                    onDirectory={true}
                    user={chosenUser}
                    closeProfile={handleChose}
                />
            </GridItem>
        </GridContainer>
    );
}

function getTitle(role) {
    switch (role) {
        case 3:
            return 'driver';
        case 1:
            return 'admin';
        case 2:
            return 'clerk';
        default:
            return;
    }
}
