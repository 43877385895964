import React from 'react'
import styles from './Footer.module.css';

const DeveloperBar = () => (
    <div className={styles.developer}>
        <a className={styles['icon-link']} href='http://www.gtng.tech' target='_blank' rel='noopener noreferrer'><p className={styles['dev-credit']}>Designed by GTNG™</p></a>
        <p className={styles.owner}>GTNG™ <i className="fa fa-copyright" aria-hidden="true"></i> <span>{new Date().getFullYear()}</span></p>
    </div>
);

export default DeveloperBar;