import {
    CHANGED_TABLE,
    FETCHED_INBOUND_LOADS,
    FETCHED_OUTBOUND_LOADS,
    FETCHED_COMPLETED_LOADS,
    CHANGE_INBOUND_LOAD_EDIT,
    FETCHED_PAGINATION_LOADS,
    FETCHED_UNSUBMITTED_LOADS,
    SET_BOLS_CARRIER_SELECTED,
} from '../actions/loads-actions';

import { UPDATE_BOL, findAndUpdateBol } from '../actions/bol-actions';

const initialState = {
    iconProps: {},
    tableRows: [],
    outboundTotal: 0,
    inboundTotal: 0,
    completedTotal: 0,
    unsubmittedTotal: 0,
    outbound: [],
    inbound: [],
    unsubmitted: [],
    completed: [],
    loadBeingEdited: {},
    bols: [],
    carrierSelected: null,
};

export default function loadsReducer(state = initialState, { type, payload }) {
    const total = payload && payload.total !== undefined ? payload.total : 0;
    switch (type) {
        case FETCHED_OUTBOUND_LOADS:
            return {
                ...state,
                outbound: payload.loads,
                outboundTotal: total,
            };
        case FETCHED_COMPLETED_LOADS:
            return {
                ...state,
                completed: payload.loads,
                completedTotal: total,
            };
        case FETCHED_INBOUND_LOADS:
            return {
                ...state,
                inbound: payload.loads,
                inboundTotal: total,
            };
        case FETCHED_UNSUBMITTED_LOADS:
            return {
                ...state,
                unsubmitted: payload.loads,
                unsubmittedTotal: total,
            };
        case FETCHED_PAGINATION_LOADS:
            return {
                ...state,
                [payload.stateOfLoad]: [
                    ...payload.loads,
                    ...state[payload.stateOfLoad],
                ],
                [`${payload.stateOfLoad}Total`]: total,
            };
        case CHANGED_TABLE:
            return {
                ...state,
                iconProps: payload.iconProps,
                tableRows: payload.tableRows,
            };
        case CHANGE_INBOUND_LOAD_EDIT:
            return {
                ...state,
                loadBeingEdited: payload,
            };
        case UPDATE_BOL:
            const { update, bolId, prop } = payload;
            const updatedInbound = findAndUpdateBol(
                state.inbound,
                bolId,
                update,
                prop
            );
            return { ...state, inbound: updatedInbound };
        case SET_BOLS_CARRIER_SELECTED:
            return { ...state, ...payload };
        default:
            return state;
    }
}
