import React from 'react';
import { connect } from 'react-redux';
import { updateLicNum, setCarrId } from '../../../actions/index3';
import { findCarrier } from '../../../actions/index12';
import { getCarrierByLastSix } from '../../../actions/index15';
import './driver.css';

export class CreateDriverPage extends React.Component {
    componentDidMount() {
        this.props.dispatch(getCarrierByLastSix(this.props.jwt));
    }

    render() {
        let options;
        let x = this.props;
        let name = `${x.user.firstName} ${x.user.lastName}`;
        if (x.options) {
            options = x.options.map((option, index) => (
                <option key={index} value={option.carrId}>
                    {option.name}, {option.carrId.substring(19, 24)}
                </option>
            ));
        }
        // let content = (
        //     <p className="create-driver-error">
        //         In order to process your request you must first enter a valid
        //         ConnexCarrierId and Driver license number. Please check that you
        //         have done so to continue.
        //     </p>
        // );
        return (
            <div className="create-driver">
                <form
                    className="cre-dri-prfl"
                    onSubmit={e => {
                        e.preventDefault();
                        x.dispatch(
                            findCarrier(
                                name,
                                x.driverLicNum,
                                x.user._id,
                                x.img,
                                x.jwt,
                                x.connexCarrierId
                            )
                        );
                    }}>
                    <h3>Create Driver Profile</h3>
                    <div className=" row">
                        <div className="col-25">
                            <label htmlFor="email">Email: </label>
                        </div>

                        <div className="col-75">
                            <span id="email">{x.user.username}</span>
                        </div>
                    </div>

                    <div className=" row">
                        <div className="col-25">
                            <label htmlFor="first-name">First Name: </label>
                        </div>
                        <div className="col-75">
                            <span id="first-name">{x.user.firstName}</span>
                        </div>
                    </div>

                    <div className=" row">
                        <div className="col-25">
                            <label htmlFor="last-name">Last Name: </label>
                        </div>
                        <div className="col-75">
                            <span id="last-name">{x.user.lastName}</span>
                        </div>
                    </div>

                    <div className=" row">
                        <div className="col-25">
                            <label htmlFor="phone">Phone: </label>
                        </div>
                        <div className="col-75">
                            <span id="phone">{x.user.phone}</span>
                        </div>
                    </div>

                    <div className=" row">
                        <div className="col-25">
                            <label htmlFor="userId">Connex UserId: </label>
                        </div>
                        <div className="col-75">
                            <span className="userId">{x.user._id}</span>
                        </div>
                    </div>

                    <div className=" row">
                        <div className="col-25">
                            <label htmlFor="carrier-id">CarrierId: </label>
                        </div>

                        <div className="col-75">
                            <input
                                id="carrier-input"
                                type="text"
                                list="options"
                                onChange={e => {
                                    x.dispatch(setCarrId(e.target.value));
                                }}
                            />
                            <datalist id="options">{options}</datalist>
                        </div>
                    </div>

                    <div className=" row">
                        <div className="col-25">
                            <label htmlFor="drivers-license">
                                Drivers License #:{' '}
                            </label>
                        </div>
                        <div className="col-75">
                            <input
                                id="phone"
                                type="text"
                                placeholder="059541368"
                                onChange={e =>
                                    x.dispatch(updateLicNum(e.target.value))
                                }
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-25">
                            <label htmlFor="pic-upload">Photo Upload:</label>
                        </div>
                        <div className="col-75"></div>
                    </div>

                    <div className="submit-btn">
                        <button type="submit" className="all-buttons">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}
export const mapStateToProps = state => {
    return {
        user: state.dataReducer.user,
        jwt: state.dataReducer.jwt,
        driverLicNum: state.oneReducer.driverLicNum,
        connexCarrierId: state.oneReducer.connexCarrierId,
        img: state.oneReducer.img,
        display: state.sixReducer.showDrvError,
        options: state.sixReducer.carrierOptions,
    };
};
export default connect(mapStateToProps)(CreateDriverPage);
