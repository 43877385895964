// import request from "superagent";
// import {push} from '';

export const ERROR_MESSAGES = 'ERROR_MESSAGES';
export const errorMessages = msg => ({
    type:ERROR_MESSAGES,
    msg
})

export const SHOW_LOADER = 'SHOW_LOADER';
export const loaderDisplayChange = display => ({
    type:SHOW_LOADER,
    display
})

export const TOGGLE_PIC_MODAL = 'TOGGLE_PIC_MODAL'
export const togglePicModal = content => ({
    type:TOGGLE_PIC_MODAL,
    content
})

export const TOGGLE_MISSING_FIELD = 'TOGGLE_MISSING_FIELD';
export const toggleMissingField = message => ({
    type:TOGGLE_MISSING_FIELD,
    message
});
