import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import FAQs from './faqs';
import Home from './Home/Home';
import Login from './login-page';
import { Welcome } from './welcome';
import Inbound from './Inbound/Inbound';
import CarrierLoad from './CarrierLoad/CarrierLoad';
import ShipperSignUp from './Shipper/ShipperSignup';
import CarrierSignUp from './carrier/carrier-signup';
import ShipperSignUp2 from './Shipper/ShipperSignup2';
import CreateCarrierPage from './forms/createCarrier';
import CarrierSignUp2 from './carrier/carrier-signup2';
import ForgotPassword from './Password/ForgotPassword';
import CarrierSignUp3 from './carrier/carrier-signup3';
import BecomeAMember from './becomeMember/becomeamember';
import DriverCheckin from './DriverCheckinCheckout/DriverCheckin';
import PickupCheckout from './DriverCheckinCheckout/PickupCheckout';
import ShipperSignUp3 from './Shipper/ShipperSignup3.new';
import CarrierLoadBol from './CarrierLoad/CarrierLoadBol';
import ShipperSignUp4 from './Shipper/ShipperSignup4.new';
import DriverOverview from './accountPages/driverOverview';
import ChangePasswordView from './Password/ChangePasswordView';
import DriverSignUp from './accountPages/driver/driver-signup';
import DriverSignUp2 from './accountPages/driver/driver-signup2';
import CreateDriverPage from './accountPages/driver/createDriver';
import DriverAccountPage from './accountPages/driver/driverAcctPage';
import DriverEditProfile from './accountPages/driver/drivereditprofile';
import BuildEditOutboundLoad from './BuildEditOutboundLoad/BuildEditOutboundLoad';
import Settings from './Settings/Settings';

const routeTable = {
    1: [
        {
            exact: true,
            path: '/shipper-account/:companyName/inbound/:loadId',
            component: Inbound,
        },
        {
            exact: true,
            path: '/shipper-account/:companyName/completed/:loadId',
            component: Inbound,
        },
        {
            exact: true,
            path: '/shipper-account/:companyName/build-outbound-load',
            component: BuildEditOutboundLoad,
        },
        {
            exact: true,
            path: '/shipper-account/:companyName/unsubmitted/:loadId',
            component: BuildEditOutboundLoad,
        },
        {
            exact: true,
            path: '/shipper-account/:companyName/edit/:loadId',
            component: BuildEditOutboundLoad,
        },
        {
            exact: true,
            path: '/shipper-account/:companyName/outbound/:loadId',
            component: BuildEditOutboundLoad,
        },
        {
            exact: true,
            path: '/settings/:tab',
            component: Settings,
        },
        {
            exact: true,
            path: '/settings',
            component: Settings,
        },
        {
            exact: true,
            path: '/driver-checkin',
            component: DriverCheckin,
        },
        {
            exact: true,
            path: '/pickup-checkout',
            component: PickupCheckout,
        },
    ],
    2: [
        {
            exact: true,
            path: '/pickup-checkout',
            component: PickupCheckout,
        },
        {
            exact: true,
            path: '/driver-checkin',
            component: DriverCheckin,
        },
        {
            exact: true,
            path: '/shipper-account/:companyName/inbound/:loadId',
            component: Inbound,
        },
        {
            exact: true,
            path: '/shipper-account/:companyName/completed/:loadId',
            component: Inbound,
        },
    ],
    3: [
        {
            exact: true,
            path: '/shipper-account/:companyName/completed/:loadId',
            component: Inbound,
        },
    ],
    4: [
        {
            exact: true,
            path: '/settings',
            component: Settings,
        },
        {
            exact: true,
            path: '/settings/:tab',
            component: Settings,
        },
        {
            exact: true,
            path: '/carrier-account/:carrierName/shipping/:loadId',
            component: CarrierLoad,
        },
        {
            exact: true,
            path: '/carrier-account/:carrierName/completed/:loadId',
            component: CarrierLoad,
        },
        {
            exact: true,
            path: '/carrier-account/:carrierName/shipping/:loadId/:bolId',
            component: CarrierLoadBol,
        },
    ],
    basic: [
        {
            exact: true,
            path: '/login',
            component: Login,
        },
        {
            exact: true,
            path: '/forgot-password',
            component: ForgotPassword,
        },
        {
            path: '/',
            exact: true,
            component: Welcome,
        },
        {
            exact: false,
            path: '/faqs',
            component: FAQs,
        },
        {
            exact: true,
            path: '/home',
            component: Home,
        },
        {
            exact: true,
            path: '/become-a-member',
            component: BecomeAMember,
        },
        {
            path: '/reset-password',
            component: ChangePasswordView,
        },
    ],
};

// * if you have logged in you may be able to see these
const authenticatedRoutes = [
    '/settings',
    '/settings/:tab',
    '/carrier-account/:carrierName/shipping/:loadId/:bolId',
    '/carrier-account/:carrierName/completed/:loadId',
    '/carrier-account/:carrierName/shipping/:loadId',
    '/shipper-account/:companyName/completed/:loadId',
    '/shipper-account/:companyName/completed/:loadId',
    '/shipper-account/:companyName/inbound/:loadId',
    '/shipper-account/:companyName/outbound/:loadId',
    '/shipper-account/:companyName/edit/:loadId',
    '/shipper-account/:companyName/unsubmitted/:loadId',
    '/shipper-account/:companyName/build-outbound-load',
    '/shipper-account/:companyName/inbound/:loadId',
    '/driver-checkin',
    '/pickup-checkout',
];

const createRoutesForApp = set =>
    routeTable[set].map(({ exact, path, component }, i) => {
        if (exact)
            return (
                <Route exact path={path} component={component} key={path + i} />
            );
        return <Route exact path={path} component={component} key={path + i} />;
    });

export const routes = (userRole, companyType) => {
    if (userRole === 1 && companyType === 1) {
        return [createRoutesForApp('basic'), ...createRoutesForApp(1)];
    }

    if (userRole === 2 && companyType === 1) {
        return [...createRoutesForApp('basic'), ...createRoutesForApp(2)];
    }

    if (userRole === 1 && companyType === 0) {
        return [...createRoutesForApp('basic'), ...createRoutesForApp(4)];
    }

    if (userRole === 3 && companyType === 0) {
        return [...createRoutesForApp('basic'), ...createRoutesForApp(4)];
    }
    return createRoutesForApp('basic');
};

export const redirects = (userRole, companyType) => {
    if (userRole != null && companyType != null) return;
    return authenticatedRoutes.map((r, i) => (
        <Redirect key={`${i}-${r}`} from={r} to={'/login'} />
    ));
};

export const otherRoutes = [
    {
        exact: true,
        path: '/company-signup',
        component: CarrierSignUp,
    },
    {
        exact: true,
        path: '/company-signup-two',
        component: CarrierSignUp2,
    },
    {
        exact: true,
        path: '/company-signup-three',
        component: CarrierSignUp3,
    },
    {
        exact: true,
        path: '/driver-signup',
        component: DriverSignUp,
    },
    {
        exact: true,
        path: '/driver-signup-two',
        component: DriverSignUp2,
    },
    {
        exact: true,
        path: '/shipper-signup',
        component: ShipperSignUp,
    },
    {
        exact: true,
        path: '/shipper-signup-two',
        component: ShipperSignUp2,
    },
    {
        exact: true,
        path: '/shipper-signup-three',
        component: ShipperSignUp3,
    },
    {
        exact: true,
        path: '/shipper-signup-four',
        component: ShipperSignUp4,
    },
    {
        exact: true,
        path: '/:userId/create-driver',
        component: CreateDriverPage,
    },
    {
        exact: true,
        path: '/:userId/create-carrier',
        component: CreateCarrierPage,
    },

    {
        exact: true,
        path: '/driver-account/:accountName',
        component: DriverAccountPage,
    },
    {
        exact: true,
        path: '/driver-overview/:driverId',
        component: DriverOverview,
    },
    {
        // 061010220 routing number for citizens trust
        exact: true,
        path: '/:userId/create-driver',
        component: CreateDriverPage,
    },

    {
        exact: true,
        path: '/driver-account/:accountName/edit-profile',
        component: DriverEditProfile,
    },
    {
        exact: true,
        path: '/shipper-account/:companyName/outbound/:loadId',
        component: BuildEditOutboundLoad,
    },
];
