import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ReceiverInfo from '../InfoComponents/ReceiverInfo';
import DeliveryInfo from '../InfoComponents/DeliveryInfo';
import UserInfo from '../InfoComponents/UserInfo';
import PickUpInfo from '../InfoComponents/PickUpInfo';
import GridContainer from '../Grid/GridContainer';
import OSDView from '../Inbound/OSDView/OSDView';
import AddBols from '../PDFComponents/AddBols';
import AssignDriver from './AssignDriver';
import GridItem from '../Grid/GridItem';

import { loaderDisplayChange } from '../../actions/shared-actions';
import { viewUploadedBol } from '../../actions/bol-actions';

import getLoadTypeFromUrl from '../helpers/getLoadTypeFromUrl';
import getLoadIdFromUrl from '../helpers/getLoadIdFromUrl';
import getBolIdFromUrl from '../helpers/getBolIdFromUrl';
import { H2 } from '../helpers/Headings';

import styles from './CarrierLoad.module.css';

function CarrierLoadBol() {
    const [bol, setBol] = useState({});
    const [load, setLoad] = useState({});
    const [showAssignDriver, setShowAssignDriver] = useState(false);
    const dispatch = useDispatch();

    const outbound = useSelector(state => state.loadsReducer.outbound);
    const completed = useSelector(state => state.loadsReducer.completed);

    useEffect(() => {
        const loadType = getLoadTypeFromUrl();
        const loadId = getLoadIdFromUrl();
        const isShipping = loadType === 'shipping';
        let index;

        if (isShipping) {
            index = outbound.findIndex(l => l._id === loadId);
            setShowAssignDriver(true);
        } else {
            index = completed.findIndex(l => l._id === loadId);
        }

        const bolId = getBolIdFromUrl();
        const load = isShipping ? outbound[index] : completed[index];
        setLoad(load);
        setBol(load.bols.find(b => b._id === bolId));
        viewUploadedBol.next(bol);
        dispatch(loaderDisplayChange('none'));
    }, [bol, completed, outbound, dispatch]);

    return (
        <GridContainer>
            <GridItem xs={12} md={6} align={'center'}>
                <AddBols viewOnly={true} bol={bol} />
            </GridItem>
            <GridItem xs={12} md={6} align={'center'}>
                <ReceiverInfo receiver={bol.receiver} />
                <H2 heading={'bol info'} />
                <hr className={styles.rule} />
                <PickUpInfo bol={bol} load={load} />
                <hr className={styles.rule} />
                <DeliveryInfo bol={bol} />
                {bol && bol.driver && (
                    <UserInfo user={bol.driver} heading={'driver info'} />
                )}
                {showAssignDriver && (
                    <AssignDriver
                        bols={[bol]}
                        changeDriver={bol && bol.driver && bol.driver.name}
                        single={true}
                    />
                )}
                <H2 heading={'osd'} />
                <hr className={styles.rule} />
                <OSDView
                    osd={
                        bol.osd || { overages: [], shortages: [], damages: [] }
                    }
                    hideAdd={true}
                    open={true}
                    hideContentText={true}
                />
            </GridItem>
        </GridContainer>
    );
}

export default CarrierLoadBol;
