const createTableRows = (loads = []) => {
    return loads.map(l => {
        const noLoads = loads.length === 0;
        const hasBolsProp = 'bols' in loads[0];
        const dataMap = {};

        if (noLoads) {
            return {};
        }

        if (hasBolsProp) {
            // * in here is for all outbound, all unsubmitted and some completed loads
            const hasManyStops = l.stops > 1;
            const hasOneStop = l.stops === 1;
            const hasNoStops = l.bols.length === 0;

            dataMap.id = l._id;
            dataMap.pUNum = l.pUNum;
            dataMap.pUDate = new Date(l.pUDate).toLocaleDateString('en-US');

            if (hasOneStop) {
                dataMap.dropDate = new Date(
                    l.bols[0].dropDate
                ).toLocaleDateString('en-US');
                dataMap.companyName = l.bols[0].receiver
                    ? l.bols[0].receiver.name
                    : '';
            }

            if (hasManyStops) {
                dataMap.companyName = '***various***';
                dataMap.dropDate = '***various***';
            }

            if (hasNoStops) {
                dataMap.companyName = '***not-assigned***';
                dataMap.dropDate = '***not-assigned***';
            }
        }

        if (!hasBolsProp) {
            // * in here is for some completed and all inbound
            dataMap.pUNum = l.load.pUNum;
            dataMap.pUDate = new Date(l.load.pUDate).toLocaleDateString(
                'en-US'
            );
            dataMap.dropDate = new Date(l.dropDate).toLocaleDateString('en-US');
            dataMap.companyName = l.shipper.name;
            dataMap.id = l.load._id;
        }

        return dataMap;
    });
};

export default createTableRows;
