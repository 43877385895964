import React, { Component } from 'react';
import { connect } from 'react-redux';
import { H3 } from '../../../helpers/Headings';
import UserInfo from '../../../InfoComponents/UserInfo';

import ImageUpload from '../../ImageUpload/ImageUpload';
import styles from './Profile.module.css';
class Profile extends Component {
    render() {
        const { onDirectory, closeProfile, user, self } = this.props;
        return (
            <>
                {onDirectory && (
                    <H3
                        className={styles.close}
                        onClick={() => closeProfile(null)}
                        heading={'close'}
                    />
                )}
                <ImageUpload onDirectory={onDirectory} />
                {(user || self) && (
                    <UserInfo
                        onDirectory={onDirectory}
                        user={user || self}
                        heading={'profile'}
                        showEdit={true}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = state => ({
    self: state.userReducer.user,
    userBeingViewed: state.userReducer.userBeingViewed,
});
export default connect(mapStateToProps)(Profile);
