import {
    FETCH_USER,
    LOGIN_ERROR_DISPLAY,
    TOGGLE_MODAL_DISPLAY,
    UPDATE_MODAL_CONTENT,
    FETCHED_DRIVERS,
    VIEW_USER,
} from '../actions/user-actions';

const initialState = {
    user: {},
    loginErrorDisplay: 'none',
    content: '',
    modalDisplay: 'none',
    drivers: [],
    userBeingViewed: null,
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_USER:
            return { ...state, user: action.payload };
        case LOGIN_ERROR_DISPLAY:
            return { ...state, loginErrorDisplay: action.payload };
        case UPDATE_MODAL_CONTENT:
            return { ...state, content: action.payload };
        case TOGGLE_MODAL_DISPLAY:
            if (state.modalDisplay === 'none') {
                document.getElementById('body').style.overflow = 'hidden';
                return {
                    ...state,
                    modalDisplay: 'block',
                };
            } else {
                document.getElementById('body').style.overflow = 'scroll';
                document.getElementById('body').style.overflowX = 'hidden';
                return {
                    ...state,
                    modalDisplay: 'none',
                };
            }
        case FETCHED_DRIVERS:
            return { ...state, drivers: action.payload };
        case VIEW_USER:
            return { ...state, userBeingViewed: action.payload };
        default:
            return state;
    }
}
