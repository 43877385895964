import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { togglePicModal } from '../../actions/index16';
import '../welcome.css';
import styles from './Shipper.module.css';

export class ShipperSignUp3 extends React.Component {
    state = {
        content: '',
    };

    render() {
        const { dispatch } = this.props;

        return (
            <div className={styles['outter-box']}>
                <div className={styles.instructions}>
                    <p>
                        Now that you have a registered Shipper profile you can
                        begin to utilize the Connex machine.
                    </p>
                    <p>
                        Once your load is ready you can visit the tab to add a
                        load. Fill in the necessary load information and upload
                        the BOLs to the Connex platform. It will wait here to be
                        assigned to a customer and a driver.
                    </p>
                </div>
                <div className={styles.instructions}>
                    <img
                        src={
                            'https://connex-imgs.s3.amazonaws.com/connexapp-images/add-load.png'
                        }
                        className={'link'}
                        onClick={() => {
                            dispatch(togglePicModal());
                            this.setState({
                                content: (
                                    <img
                                        src={
                                            'https://connex-imgs.s3.amazonaws.com/connexapp-images/add-load.png'
                                        }
                                        alt={'add load'}
                                    />
                                ),
                            });
                        }}
                        alt={'add load'}
                    />
                </div>

                <div className={styles.instructions}>
                    <p>
                        When the driver comes to retrieve his/her bills, you
                        should ask them for them to provide you with their
                        Connex User ID. Find that load in your list of Current
                        Loads and click on it to update it. You should see an
                        input box that says <i>Find a driver</i>. This is where
                        you should enter the Connex User ID that the driver gave
                        you.
                    </p>
                </div>
                <div className={styles.instructions}>
                    <img
                        src={
                            'https://connex-imgs.s3.amazonaws.com/connexapp-images/add-driver.png'
                        }
                        className={styles.link}
                        onClick={() => {
                            dispatch(togglePicModal());
                            this.setState({
                                content: (
                                    <img
                                        src={
                                            'https://connex-imgs.s3.amazonaws.com/connexapp-images/add-driver.png'
                                        }
                                        alt={'add-driver'}
                                    />
                                ),
                            });
                        }}
                        alt={'add driver'}
                    />
                </div>
                <div className={styles.instructions}>
                    <p>
                        Upon entering their info you should see their full
                        Connex Driver profile. If they are the driver you want,
                        you can then click the
                        <button
                            style={{ marginBottom: 0 }}
                            className={styles['all-buttons']}>
                            Add Driver
                        </button>{' '}
                        to assign the load to them. They are now set to go with
                        a digital copy of their bills. It's that easy.
                    </p>

                    <Link to="/shippersignup2">
                        <p>Back</p>
                    </Link>
                    <Link to="/shippersignup4">
                        <p>Continue</p>
                    </Link>
                </div>
            </div>
        );
    }
}

export default connect()(ShipperSignUp3);
