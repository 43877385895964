import React from 'react';
import { connect } from 'react-redux';
import './loader.css';

export class Loader extends React.Component {
    render() {
        const { loaderDisplay, isPresisting } = this.props;
        const modalStyle = {
            display: `${
                isPresisting ? 'block' : loaderDisplay
            }` /* Hidden by default */,
            position: 'fixed' /* Stay in place */,
            zIndex: '1' /* Sit on top */,
            paddingTop: '100px' /* Location of the box */,
            left: '0',
            top: '0',
            width: '100%' /* Full width */,
            height: '100%' /* Full height */,
            overflow: 'auto' /* Enable scroll if needed */,
            backgroundColor: 'rgba(0,0,0,0.3)' /* Black w/ opacity */,
        };
        return (
            <div style={modalStyle}>
                <div className="loader"></div>
            </div>
        );
    }
}

export const mapStateToProps = state => {
    return {
        loaderDisplay: state.sharedActionReducer.loaderDisplay,
    };
};

export default connect(mapStateToProps)(Loader);
