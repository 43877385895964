import request from 'superagent';
import { loaderDisplayChange } from './shared-actions';
import { getCompany } from './companies-actions';

import { errorMessages } from './index16';

export const SHOW_MENU = 'SHOW_MENU';
export const showMenu = display => ({
    type: SHOW_MENU,
    display,
});

export const ADD_IMG = 'ADD_IMG';
export const addImg = img => ({
    type: ADD_IMG,
    img,
});

export const SET_CARRIER = 'SET_CARRIER';
export const setCarrier = carrier => ({
    type: SET_CARRIER,
    carrier,
});

export const CARRIER_NAME = 'CARRIER_NAME';
export const carrierName = name => ({
    type: CARRIER_NAME,
    name,
});

export const MY_CARRIERS = 'MY_CARRIER';
export const myCarriers = carriers => ({
    type: MY_CARRIERS,
    carriers,
});

export const GET_MY_CARRIER = 'GET_MY_CARRIER';
export const getMyCarrier = carrierInfo => ({
    type: GET_MY_CARRIER,
    carrierInfo,
});

export const createCarrier = (
    admin,
    carrierName,
    email,
    phone,
    jwt
) => dispatch => {
    let carrierObj = {
        admin,
        carrierName,
        email,
        phone,
    };

    return new Promise((resolve, reject) => {
        return request
            .post('/api/connex/carriers')
            .set('Accept', 'application/json')
            .set('Content-type', 'application/json')
            .send(JSON.stringify(carrierObj))
            .then(res => {
                if (res.ok) {
                    dispatch(getCompany(admin, jwt));
                    dispatch(setCarrier(res));
                    window.location = `/home/${admin}`;
                    dispatch(loaderDisplayChange('none'));
                    resolve();
                } else {
                    reject(res);
                }
            })
            .then(res => {
                if (res) {
                    console.log(`Response from server: ${res}`);
                } else {
                    console.log("Lookin' good! 👨🏾‍");
                }
            })
            .catch(err => {
                dispatch(errorMessages(err));
                console.log(err);
                dispatch(loaderDisplayChange('none'));
                // dispatch(a.loginErr('block'));
                // setTimeout(function () {
                //     dispatch(a.loginErr('none'));
                // }, 5000);
                setTimeout(function () {
                    dispatch(errorMessages(''));
                }, 5000);
            });
    });
};
