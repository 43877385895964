import React from 'react';
import { H2, H3 } from '../helpers/Headings';
import formatPhoneNumber from '../helpers/formatPhoneNumber';

import appStyles from '.././App.module.css';

export default function ReceiverInfo({ receiver }) {
    return (
        <>
            <H2 heading={'receiver info'} />
            <hr className={appStyles.rule} />
            <H3 heading={receiver?.name} />
            <p>{formatPhoneNumber(receiver?.phone)}</p>
            <p>{receiver?.address.line1}</p>
            {receiver?.address.line2 && <p>receiver?.address.line2</p>}
            {receiver?.address.line3 && <p>receiver?.address.line3</p>}
            <p>
                {`${receiver?.address.city}, ${receiver?.address.state} ${receiver?.address.zip}`}
            </p>
        </>
    );
}
