import React, { Component } from 'react';
import { connect } from 'react-redux';

import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import DeliveryInfo from '../InfoComponents/DeliveryInfo';
import UserInfo from '../InfoComponents/UserInfo';

import {
    changeInboundEditLoad,
    getAllLoads,
} from '../../actions/loads-actions';
import InboundViewer from '../PDFComponents/InboundViewer';
import OSDView from './OSDView/OSDView';
import { updateBol } from '../../actions/bol-actions';
import { Button } from '@material-ui/core';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import getLoadTypeFromUrl from '../helpers/getLoadTypeFromUrl';
import getLoadIdFromUrl from '../helpers/getLoadIdFromUrl';
import formatPhoneNumber from '../helpers/formatPhoneNumber';

import styles from './Inbound.module.css';
import appStyles from '.././App.module.css';
import PickUpInfo from '../InfoComponents/PickUpInfo';

class Inbound extends Component {
    state = {
        modalOpen: false,
        osdView: false,
        load: {
            osd: {
                damages: [],
                shortages: [],
                overages: [],
            },
        },
    };

    componentDidMount() {
        const loadId = getLoadIdFromUrl();
        const type = getLoadTypeFromUrl();
        const whichLoad =
            type === 'completed' ? 'completedLoads' : 'inboundLoads';

        const load = this.props[whichLoad].find(l => l.load._id === loadId);
        if (load) {
            this.setState({ load });
            this.props.changeInboundEditLoad(load);
        } else {
            this.props.getAllLoads(null, true);
        }
    }

    componentDidUpdate(prevProps) {
        const loadId = getLoadIdFromUrl();
        const type = getLoadTypeFromUrl();
        const whichLoad =
            type === 'completed' ? 'completedLoads' : 'inboundLoads';

        if (this.props[whichLoad].length !== prevProps[whichLoad].length) {
            const load = this.props[whichLoad].find(l => l.load._id === loadId);

            this.setState({ load });
            this.props.changeInboundEditLoad(load);
        }
    }

    toggleModal = () => this.setState({ modalOpen: !this.state.modalOpen });
    toggleOsdView = () => this.setState({ osdView: !this.state.osdView });

    componentWillUnmount() {
        this.props.changeInboundEditLoad({});
    }

    render() {
        const type = getLoadTypeFromUrl();
        const completed = type === 'completed';
        const { load } = this.state;
        const { company } = this.props;
        const shipper = load && load.shipper;
        let carrierName = '';
        let carrierPhone = '';
        let shipperName = '';
        let shipperPhone = '';
        let showPickupTimes = false;

        if (load) {
            if (load.carrier) {
                carrierName = load.carrier.name;
                carrierPhone = load.carrier.phone;
            }

            if (load.shipper) {
                shipperName = shipper.name;
                shipperPhone = shipper.phone;
                showPickupTimes = company._id === shipper._id;
            }
        }
        return (
            <GridContainer justify={'space-around'} wrap={'wrap-reverse'}>
                <GridItem
                    xs={12}
                    md={5}
                    className={`${styles['grid-item']} ${styles['pdf-grid']}`}>
                    <InboundViewer load={load} />
                </GridItem>
                <GridItem
                    xs={12}
                    md={7}
                    className={`${styles['info-wrapper']} ${styles['grid-item']}`}>
                    <h1>bol info</h1>
                    <h2>{shipperName}</h2>
                    <h3>{formatPhoneNumber(shipperPhone)}</h3>
                    <hr className={appStyles.rule} />
                    <h3>Carrier: {carrierName}</h3>
                    <p>{formatPhoneNumber(carrierPhone)}</p>
                    {load && load.driver && (
                        <UserInfo user={load.driver} heading={'driver info'} />
                    )}
                    <hr className={appStyles.rule} />
                    {showPickupTimes && <PickUpInfo load={load.load} />}
                    {load && <DeliveryInfo bol={load} />}
                    <>
                        <Button
                            className={styles['osd-button']}
                            disabled={load && load.checkin === null}
                            color={'primary'}
                            onClick={this.toggleOsdView}>
                            <h3>{completed ? 'OSD Report' : 'Report OSD'}</h3>
                        </Button>
                        {this.state.osdView && (
                            <OSDView
                                osd={load.osd}
                                getOsd={osd => {
                                    this.props.updateBol(osd, load._id, 'osd');
                                }}
                                hideAdd={completed}
                                open={this.state.osdView}
                                closeOsdView={this.toggleOsdView}
                            />
                        )}
                        {type !== 'completed' && (
                            <>
                                <Button
                                    disabled={
                                        (load && load.checkin === null) ||
                                        this.state.osdView
                                    }
                                    onClick={this.toggleModal}
                                    size={'large'}
                                    color={'primary'}>
                                    Complete Load
                                </Button>

                                <ConfirmDialog
                                    bolId={load._id}
                                    open={this.state.modalOpen}
                                    onClose={this.toggleModal}
                                />
                            </>
                        )}
                    </>
                </GridItem>
            </GridContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        inboundLoads: state.loadsReducer.inbound,
        completedLoads: state.loadsReducer.completed,
        company: state.companiesReducer.companyWithBasicData,
    };
};
const mapDispatchToProps = {
    getAllLoads,
    changeInboundEditLoad,
    updateBol,
};
export default connect(mapStateToProps, mapDispatchToProps)(Inbound);
