import React from 'react';
import {connect} from 'react-redux'; 


export class InputError extends React.Component{
    render() {
        let divStyle = {
            display: this.props.visible ? 'inline-block': 'none'
        }
        return (
            <div style={divStyle}>{this.props.errorMessage}</div>
        )
    }
}
export const mapStateToProps = state => {
    return{
        username:state.dataReducer.username,
        firstName:state.dataReducer.firstName,
        lastName:state.dataReducer.lastName,
        password:state.dataReducer.password,
        confirmPassword:state.dataReducer.confirmPassword,
        phone:state.dataReducerphone,
        errorMessage:state.dataReducer.errorMessage
    }
}
export default connect(mapStateToProps)(InputError)