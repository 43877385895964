import React, { Component } from 'react';
import { connect } from 'react-redux';

export class ImgProp extends Component {
    state = {
        img: null,
    };

    render() {
        let moveImg = img => {
            this.setState({ img: img });
            this.props.moveImg2(this.state.img);
        };
        return <div className="img-box"></div>;
    }
}

export const mapStateToProps = state => {
    return {
        jwt: state.dataReducer.jwt,
        driver: state.fourReducer.mySelectedDriver,
    };
};
export default connect(mapStateToProps)(ImgProp);
