import React from 'react';
import {connect} from 'react-redux';
import {deleteDriverFromCarrier} from '../../../actions/index15';
import './driver.css'

export class DriverDeleteAccount extends React.Component{
    state = {
        showDeleteBOLs:'none',
        showModal:'none',
    }

    
    render() {
        let x = this.props;
        let y = this.state;
        const modalStyle = {
            display: `${y.showModal}`, /* Hidden by default */
            position: 'fixed', /* Stay in place */
            zIndex: '1', /* Sit on top */
            paddingTop: '100px', /* Location of the box */
            left: '0',
            top: '0',
            width: '100%', /* Full width */
            height: '100%', /* Full height */
            overflow: 'auto', /* Enable scroll if needed */
            backgroundColor: 'red' /* Black w/ opacity */
        }
        
        const modalContent = {
            margin: 'auto',
            display: 'block',
            color: '#f1f1f1', 
            textAlign: 'center'
        }

        return (
            <div className='delete-account'>
                <p id='delete-driver' className='link' style={{color:'red'}}onClick={() => {
                    this.setState({showModal:'block'})
                }}>Delete Account</p>
                <div style={modalStyle}>
                    <p style={modalContent}>Warning: The action you are about to take is
                        irrevocable. All data associated with this account will be permanently erased.
                        
                        Are you sure you want to continue?
                    </p>

                    <p className='link' onClick={() => {
                        this.setState({showModal:'none'})
                        x.dispatch(deleteDriverFromCarrier(x.jwt,x.info.carrierId._id,
                        x.info._id,x.info.carrierId.drivers,x.user._id))
                    }} style={modalContent}>Yes</p>

                    <p className='link' onClick={() => {
                        this.setState({showModal:'none'}) 
                    }} style={modalContent}>No</p>
                </div>
            </div>
        )       
    }
}
export const mapStateToProps = state => {
    return { 
        info:state.fourReducer.mySelectedDriver,
        jwt:state.dataReducer.jwt,
        user:state.dataReducer.user
    
    }
}
export default connect(mapStateToProps)(DriverDeleteAccount)

