import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    updateModalContent,
    toggleModalDisplay,
} from '../../actions/user-actions';
import styles from './Shipper.module.css';

export class ShipperSignUp extends Component {
    render() {
        return (
            <div className={styles['outter-box']}>
                <div className={styles.instructions}>
                    As a Connex shipper you will be connected to your customers,
                    Connex carriers, and their drivers. You will be able to
                    sssign BOLs to drivers and also share them with your
                    customers. For this to take place your customer must also
                    have a Connex shipper profile. This will enable you to be
                    notified when the BOLs are signed by the receiving party.
                </div>
                <div className={styles.instructions}>
                    <img
                        src="https://connex-imgs.s3.amazonaws.com/connexapp-images/connex-dashboard.png"
                        alt="dashboard"
                        className={styles.link}
                        onClick={() => {
                            this.props.dispatch(toggleModalDisplay());
                            this.props.dispatch(
                                updateModalContent(
                                    <img
                                        src="https://connex-imgs.s3.amazonaws.com/connexapp-images/connex-dashboard.png"
                                        alt="create-shipper"
                                    />
                                )
                            );
                        }}
                    />
                </div>
                <div className={styles.instructions}>
                    Your BOLs are only visible to those Connex shippers who you
                    share them with. Shippers can sign BOLs and everyone will be
                    able to access them whenever! So long lost BOLs or missing
                    pages where recevier signatures should be! Save on toner and
                    paper by having a digital record of every BOL every moment
                    of the day.
                    <p> Want to learn more? </p>
                    <Link to="/shipper-signup-two">
                        <p style={{ marginLeft: 0 }}>Continue</p>
                    </Link>
                </div>
            </div>
        );
    }
}

export default connect()(ShipperSignUp);
