import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { H1, H3 } from '../helpers/Headings';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import UsersAutocompleteInput from '../AutocompleteInput/UsersAutoCompleteInput';

import styles from './DriverCheckin.module.css';
import ImageUpload from '../Settings/ImageUpload/ImageUpload';
import handleSelectDriver from './handleSelectDriver';

export default function DriverCheckin() {
    const dispatch = useDispatch();
    const outbound = useSelector(state => state.loadsReducer.outbound);
    const inbound = useSelector(state => state.loadsReducer.inbound);
    const [deliveries, setDeliveries] = useState([]);
    const [pickUp, setPickUp] = useState([]);
    const [driver, setDriver] = useState(null);

    useEffect(() => {
        handleSelectDriver({
            setDeliveries,
            setPickUp,
            dispatch,
            inbound,
            outbound,
            driver,
        });
    }, [driver, dispatch, inbound, outbound]);

    const showDelivery = driver && deliveries.length > 0;
    const showPickUp = driver && pickUp.length > 0;

    return (
        <div className={styles['driver-checkin']}>
            <H1 className={styles.h1} heading={'driver checkin'} />
            <GridContainer justify={'center'}>
                <GridItem xs={12} md={6} align={'center'}>
                    {driver && (
                        <ImageUpload
                            driverImage={driver.img}
                            hideUpload={true}
                        />
                    )}
                    <UsersAutocompleteInput
                        role={3}
                        label={'Drivers'}
                        disabled={false}
                        id={'carrier-driver-users'}
                        onUserSelected={setDriver}
                        searchAll={true}
                    />
                </GridItem>
                <GridItem xs={12} md={6} align={'center'}>
                    {showDelivery && (
                        <>
                            <H3 heading={'delivering'} />
                            <ol>{deliveries}</ol>
                        </>
                    )}
                    {showPickUp && (
                        <>
                            <H3 heading={'pick-up'} />
                            <ol>{pickUp}</ol>
                        </>
                    )}
                </GridItem>
            </GridContainer>
        </div>
    );
}
