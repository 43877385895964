import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {togglePicModal} from '../../actions/index16';
import './carrier.css';
import styles from '../Shipper/Shipper.module.css'

export class CarrierSignUp2 extends React.Component {
    
    state = {
        content:''
    }

    render(){
        
        return (
            <div className={styles['outter-box']}>
                <div className={styles.instructions}>
                    <p>Once inside you will see options to either "Join a team",
                        "Create a carrier profile", "Create a driver profile", or "
                        Create a shipper profile".
                    </p>
                </div>
                <div className={styles.instructions}>
                    <p>Choose the Create carrier profile.</p>
                     <img src='https://connex-imgs.s3.amazonaws.com/connexapp-images/connex-dashboard.png'
                    className='link'
                    onClick={() => {
                        this.props.dispatch(togglePicModal())
                        this.setState({content:<img src='https://connex-imgs.s3.amazonaws.com/connexapp-images/connex-dashboard.png' alt='add-driver' />})
                    }}
                    alt='add driver'/>
                </div>
                
                <div className={styles.instructions}>
                    <p>Your User information will automatically be linked to 
                        this profile and you will be added as it's administrator.
                        You will also be able to add other users as 'associates' 
                        to this Carrier profile which will grant them permission 
                        to update your drivers, certain driver info, and view 
                        BOLs associated with your drivers. Being an Admin 
                        on your Carrier profile would also mean that that person 
                        may be contacted concerning your Connex account. So please,
                        choose carefully.
                    </p>
                    <p>Keep going!? </p>
                    <Link to='/carriersignup2'><p>Back</p></Link>
                
                    <Link to='/carriersignup3'><p>Continue</p></Link>
                </div>
            </div>
        )
    }
}

export default connect()(CarrierSignUp2) 