import request from "superagent";


export const SHIPPER_NAME = 'SHIPPER_NAME';
export const shipperName = name => ({
    type:SHIPPER_NAME,
    name
})

export const SHIPPER_EMAIL = 'SHIPPER_EMAIL';
export const shipperEmail = email => ({
    type:SHIPPER_EMAIL,
    email
})

export const SHIPPER_PHONE = 'SHIPPER_PHONE';
export const shipperPhone = phone => ({
    type:SHIPPER_PHONE,
    phone
})

export const SHIPPER_ADDRESS = 'SHIPPER_ADDRESS';
export const shipperAddress = address => ({
    type:SHIPPER_ADDRESS,
    address
})

export const SHIPPER_ACCT = 'SHIPPER_ACCT';
export const shipperAcct = shipper => ({
    type:SHIPPER_ACCT,
    shipper
})


export const findMyShipperAccounts = (userId,jwt) => dispatch => {
    return new Promise ((resolve,reject) => {
        return request 
            .get(`/api/connex/shippers/${userId}`)
             
            .then(res => {
                if(res.ok) {
                    dispatch(shipperAcct(res.body))
                    resolve()
                }                
                else {
                    reject(res );
                }
            })
            .then(res => {
                if (res) {
                    console.log(`Response from server: ${res}`)
                }
                else {
                    console.log('Lookin\' good! 👨🏾‍')
                }
            })
            .catch((err) => {
                console.log(err)
            })
    })
}
 

export const createShipperProfile = (admin, compName,phone,address,email,jwt) => dispatch => {
    
    let newShipper = {
        admin:admin,
        companyName:compName,
        phone:phone,
        address:address,
        email:email
    }

    return new Promise ((resolve,reject) => {
        return request
            .post('/api/connex/shippers')
             
            .set( 'Accept','application/json')
            .set('Content-type', 'application/json')
            .send(JSON.stringify(newShipper))
            .then(res =>{
                if(res.ok){
                    dispatch(findMyShipperAccounts(admin,jwt)) 
                    window.location =  `/home/${admin}`
                    resolve()
                }
                else {
                    reject(res)
                }
            })
            .then(res => {
                if (res) {
                    console.log(`Response from server: ${res}`)
                }
                else {
                    console.log('Lookin\' good! 👨🏾‍')
                }
            })
            .catch((err) => {
                console.log(err)
            })
    })
}
export const SET_INDEX = 'SET_INDEX';
export const setIndex = index => ({
    type:SET_INDEX,
    index
})
