import React, { Component } from 'react';
import { connect } from 'react-redux';

import Dashboard from '../Dashboard/Dashboard';
import { logoutDisplayChange } from '../../actions/shared-actions';
import { refreshUser } from '../../actions/user-actions';
import { loadBeingEdited } from '../../actions/loads-actions';
import { aggregateCompanyUser } from '../../actions/companies-actions';

import styles from './Home.module.css';
export class Home extends Component {
    state = { body: '' };
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(logoutDisplayChange('initial'));
        dispatch(aggregateCompanyUser());
        loadBeingEdited.next({
            pUNum: '',
            pUDate: '',
            bols: [],
            carrierId: null,
        });
    }

    render() {
        const { dispatch, user, company } = this.props;

        //todo: find out why this is being logged twice
        // console.log('SEE USER ', user);
        !user.firstName && dispatch(refreshUser());
        const isCarrier = company.type === 0;
        const notDriver = user.role < 3;
        const carrierDashboard = isCarrier && notDriver;
        const driverDashboard = isCarrier && user.role === 3;
        const showShipperReceiverDashboard = company.type === 1 && notDriver;
        return (
            <div className={styles['main-box']}>
                <div className={styles['home-message']}>
                    <h1>{this.props.company.name}</h1>
                    <h2>Welcome Home {user ? user.firstName : ''}!</h2>
                    <p>
                        Your Connex User Id:{' '}
                        {user ? user._id.substring(0, 6) : ''}
                    </p>
                </div>
                {showShipperReceiverDashboard && <Dashboard />}
                {carrierDashboard && <Dashboard carrierView={true} />}
                {driverDashboard && <Dashboard driverView={true} />}
            </div>
        );
    }
}
export const mapStateToProps = state => {
    return {
        user: state.userReducer.user,
        driver: state.thirdReducer.driverAcct,
        display: state.thirdReducer.displayCreateAProfile,
        company: state.companiesReducer.companyWithBasicData,
    };
};
export default connect(mapStateToProps)(Home);
