import React, {Component} from 'react';
import './login-page.css';

export default class FAQs extends Component {
    render() {
        return (
            <div className='faqs'>
                <div className='faqs-sidenav'>
                    <ul className='questions'>          
                        <li> <a href='#who' className='link'>Who are we?</a></li>
                        <li><a href='#experience' className='link'>Our Experience</a></li>
                        <li><a href='#getting-started' className='link'>How did we get started?</a></li>
                        <li><a href='#cost' className='link'>What does it cost?</a></li>
                    </ul>
                </div>
                <h1>FAQs</h1>
                <div className='actual-faqs'> 
                    <div id='who' className='spacer'></div>
                    <h3 >Who are we?</h3>
                    <p >We are a small tech start-up in Atlanta, GA that strives to provide our customers with the best in BOL management.</p>
                    <div id='experience'className='spacer'></div>
                    <h3 >Experience</h3>
                    <p >
                        Between our founder and business partners Connex has a combined 
                        30 years of experience in the trucking/transportation/logistics industry
                    </p>
                    <div id='getting-started' className='spacer'></div>
                    <h3 >How did we get started?</h3>
                    <p > 
                        Our founder J. Naeem Gitonga is a former owner/operator of a small logistics company. 
                        He found that while on runs throughout the southeast, multi-stop trips were
                        causing more and more paperwork to pile up, with the potential of being lost or misplaced. Not only that,
                        while managing the truck, compromised BOLs could cause delays in revenue which is vital to small business owners.
                        <br/>
                        <br/>
                        Then came the idea of a way to have readily available BOLs for all parties involved. Not 
                        only that these BOLs should be updateable and everyone should be notified upon successful delivery.
                        This means that there is less paperwork, therby decreasing the chances of mishaps, and everyone being 
                        current all of the time. Thus, no more waiting for BOL processing as they are all time-stamped and 
                        available within the very instance that they are submitted. 
                        <br/>
                        <br/>
                        After ending his career as an owner/operator he decided to work to create the software that would resolve the 
                        problems that paper BOLs can bring. This meant that he had to learn how to engineer software. So after about six months
                        of intensive study for minimum 10 hours per day, Gitonga began roughly sketching what would soon be this web 
                        application.
                        <br/>
                        <br/>
                        Connex signifies: current BOLs, availability, accurate in/out times, accurate and uncontestable detention if any is due, 
                        yearly savings in paper and toner, fast processing of tens, hundreds, or thousands of BOLs per day or as your 
                        business needs demand! 

                    </p>
                    
                    <div id='cost' className='spacer'></div>
                    <h3 >What does it cost?</h3>
                    <p >
                        The cost of Connex pales in comparison to the yearly savings and time that our
                        e-BOL software returns to users. The cost of Connex is directly correlated with the 
                        number of BOLs processed through the Connex app which will vary from company to company.
                    </p>
                </div>
                

            </div>
        )
    }
}