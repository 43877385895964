import request from 'superagent';
import * as a from './index5';
import * as b from './index';
// import * as c from './index3';

export const LOADING = 'LOADING';
export const loading = () => ({
    type: LOADING,
});

export const updateDriverAndUserProfiles = (
    driverId,
    userId,
    jwt,
    phone,
    lastName,
    firstName,
    username,
    companyId,
    driverLicense,
    truckNum,
    trlNum,
    driverLicensePic,
    carrierId
) => dispatch => {
    let userObj = {
        phone: phone,
        lastName: lastName,
        firstName: firstName,
        username: username,
        companyID: companyId,
    };

    let driverObj = {
        driverLicense: driverLicense,
        truckNum: truckNum,
        trlNum: trlNum,
        driverLicensePic: driverLicensePic,
        carrierId: carrierId,
        accountName: `${firstName} ${lastName}`,
    };

    request
        .put(`/api/connex/userUpdate/${userId}`)

        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .send(JSON.stringify(userObj))
        .then(res => {
            if (res.ok) {
                console.log('Look res.body USERUPDATE ', res.body);
                dispatch(b.setUser(res.body));
            }
        });

    request
        .put(`/api/connex/driverUpdate/${driverId}`)
        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .send(JSON.stringify(driverObj))
        .then(res => {
            if (res.ok) {
                console.log('Look res.body DRVUPDATE ', res.body);
                // dispatch(c.driversAcct(res.body))
                dispatch(a.findDriverPopulate(driverId, jwt));
                window.location = `/driverAcct/${res.body.accountName}`;
            }
        });
};

export const SHOW_ADD_ANOTHER_BOL = 'ADD_ANOTHER_BOL';
export const showAddAnotherBOL = () => ({
    type: SHOW_ADD_ANOTHER_BOL,
});

export const clearOutboundInboundCompleted = jwt => dispatch => {
    request
        .put(
            '/api/connex/clearOutboundInboundCompleted/59cd4c0c8c25a93904f6d402'
        )

        .set('Accept', 'application/json')
        //59cd4c0c8c25a93904f6d402  animalcookie sender
        //59cd4c298c25a93904f6d403   apple
        //59e147ebc8ccd52e60268a59   bbb
        //             59e14a85c8ccd52e60268a5b

        //59c28a6b8fea98085475fc26  driver

        .set('Content-type', 'application/json')
        .then(res => {
            if (res.ok) {
                console.log('Look res.body shiper ', res.body);
            }
        });
};

export const clearDriversCurrentLoad = jwt => dispatch => {
    request
        .put('/api/connex/clearDriversCurrentLoad/59cd50151dea1115b4628da6')

        .set('Accept', 'application/json')

        //59cd50151dea1115b4628da6  driver

        .set('Content-type', 'application/json')
        .then(res => {
            if (res.ok) {
                console.log('Look res.body shiper ', res.body);
            }
        });
};
