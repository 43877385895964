import {
    COMPANY_FETCHED_All_DATA,
    COMPANY_FETCHED_BASIC_DATA,
    COMPANY_FETCHED_ADMIN,
    SHIPPER_OF_LOAD,
} from '../actions/companies-actions';
const initialState = {
    companyWithBasicData: {},
    companyWithAllData: {},
    admin: {},
    shipperOfLoad: {}, //* company info for shipper of your inbound load
    receiverOfLoad: {}, //* companyInfo for receiver of your outbound load
};

export default function companiesRducer(state = initialState, action) {
    switch (action.type) {
        case COMPANY_FETCHED_All_DATA:
            return { ...state, companyWithAllData: action.payload };
        case COMPANY_FETCHED_BASIC_DATA:
            return { ...state, companyWithBasicData: action.payload };
        case COMPANY_FETCHED_ADMIN:
            return { ...state, admin: action.payload };
        case SHIPPER_OF_LOAD:
            return { ...state, shipperOfLoad: action.payload };
        default:
            return state;
    }
}
