import moment from 'moment';

//todo: move these to the back-end (all that fit)
export function isOnTime(expectedTime, actualTime) {
    if (actualTime === null) return null;
    return new Date(expectedTime).getTime() >= new Date(actualTime).getTime();
}

export function formatDate(date) {
    const dt = new Date(date);
    const formattedDate = `${(dt.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${dt
        .getDate()
        .toString()
        .padStart(2, '0')}/${dt
        .getFullYear()
        .toString()
        .padStart(4, '0')} ${dt
        .getHours()
        .toString()
        .padStart(2, '0')}:${dt
        .getMinutes()
        .toString()
        .padStart(
            2,
            '0'
        )}`; /*/:${dt.getSeconds().toString().padStart(2, '0')}`*/
    return formattedDate;
}

export function lateDiff(expectedTime, actualTime) {
    if (actualTime === null) return null;
    const pUDiff = moment(actualTime).utc() - moment(expectedTime).utc();
    return moment.duration(pUDiff).humanize();
}

export function getOnTimeMessage(onTime, late) {
    if (onTime === null) {
        return 'No Info For ';
    }
    return onTime ? 'On-Time ' : `Late ${late} For `;
}

export function getLoadPickupData(load) {
    return {
        checkin: load.checkin ? formatDate(load.checkin) : '',
        checkout: load.checkout ? formatDate(load.checkout) : '',
        onTime: isOnTime(load.pUDate, load.checkin),
        late: lateDiff(load.pUDate, load.checkin),
    };
}
