import { Subject } from 'rxjs';
import request from 'superagent';
import {
    getAllLoads,
    fetchedPaginationLoads,
    paginatedLoads,
    getCarrierLoads,
} from './loads-actions';
import { errorHandler, loaderDisplayChange } from './shared-actions';

export const viewUploadedBol = new Subject();

export const UPDATE_BOL = 'bol/update';
export const updateBolInStore = (update, bolId, prop) => ({
    type: UPDATE_BOL,
    payload: { update, bolId, prop },
});

export const findAndUpdateBol = (inboundLoads, bolId, update, prop) => {
    // * inbound loads are really bols 🙃
    const inbound = inboundLoads.map(bol => {
        const match = bol._id === bolId;
        if (match && prop) {
            bol[prop] = update;
        } else if (match && !prop) {
            return { ...bol, ...update };
        }
        return bol;
    });

    return inbound;
};

export const updateBol = (update, bolId, prop) => async dispatch => {
    dispatch(loaderDisplayChange('block'));
    const { loadId, companyName } = update;
    delete update.loadId;
    delete update.companyName;
    const payload = prop ? { [prop]: update } : update;
    try {
        const res = await request
            .put(`/api/bols/${bolId}`)
            .set('Accept', 'application/json')
            .set('Content-type', 'application/json')
            .send(payload);

        if (res.body.ok) {
            if (loadId && companyName) {
                dispatch(getAllLoads({ companyName, loadId }));
                return;
            }

            if (loadId && !companyName) {
                dispatch(getAllLoads());
                return;
            }
            dispatch(updateBolInStore(update, bolId, prop));
            dispatch(loaderDisplayChange('none'));
        }
    } catch (e) {
        console.log(e);
        dispatch(errorHandler(e));
    }
};

export const bolsPagination = page => dispatch => {
    dispatch(loaderDisplayChange('block'));
    request
        .get(`/api/bols/pagination?page=${page}`)
        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .then(res => {
            const { completed, completedTotal } = res.body;
            dispatch(
                fetchedPaginationLoads(completed, completedTotal, 'completed')
            );
            paginatedLoads.next('completed');
            dispatch(loaderDisplayChange('none'));
        })
        .catch(e => dispatch(errorHandler(e)));
};

export const assignDriverToAll = (ids, update, companyId) => dispatch => {
    dispatch(loaderDisplayChange('block'));
    request
        .put(`/api/bols/driver?objectIds=${ids}`)
        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .send(update)
        .then(res => {
            console.log(res);
            dispatch(getCarrierLoads(companyId));
            dispatch(loaderDisplayChange('none'));
        })
        .catch(e => dispatch(errorHandler(e)));
};
