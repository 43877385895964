import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    getAllCompanies,
    companiesReceived,
} from '../../actions/companies-actions';

import { clearInput } from '../../actions/shared-actions';

class CompaniesAutocmpleteInput extends Component {
    state = {
        open: false,
        options: [],
        searchableText: '',
        loading: false,
    };

    clearInputSubscription = clearInput.subscribe({
        next: () => {
            const selector =
                'div.MuiAutocomplete-endAdornment > button.MuiAutocomplete-clearIndicatorDirty';
            const element = document.querySelector(selector);
            element && element.click();
        },
    });

    companiesReceivedSubscription = companiesReceived.subscribe({
        next: options =>
            this.setState({
                //! I am sure there is a more elegant solution
                //todo: find a more elegant solution than filtering.
                // * what's happening is that since this is a subscription
                // * we end up seeing results that aren't supposed to be in
                // * our options.
                options: options.filter(o => o.type === this.props.type),
                loading: false,
            }),
    });

    componentDidMount() {
        const input = fromEvent(
            document.getElementById('async-input'),
            'keyup'
        );
        const searchText = input.pipe(debounceTime(500));
        searchText.subscribe(e => {
            this.setState({
                open: true,
                loading: true,
                searchableText: e.target.value,
                options: [],
            });
            this.fetchCompanies();
        });
    }

    fetchCompanies = () => {
        const searchText = this.state.searchableText;
        if (!searchText) return this.setState({ options: [], loading: false });
        this.props.dispatch(getAllCompanies(searchText, this.props.type));
    };

    componentWillUnmount() {
        this.props.onCompanySelected.next(null);
        this.clearInputSubscription.unsubscribe();
        this.companiesReceivedSubscription.unsubscribe();
    }

    render() {
        const { open, options, loading } = this.state;
        return (
            <Autocomplete
                id={'async-input'}
                disabled={this.props.disabled}
                // * we should NOT be using inline styling
                style={{ margin: 'auto' }}
                open={open}
                onOpen={() => {
                    this.setState({ open: true });
                }}
                onChange={(e, value) => {
                    e.stopPropagation();
                    this.props.onCompanySelected.next(value);
                }}
                onClose={() => {
                    this.setState({ open: false });
                }}
                getOptionSelected={(option, value) =>
                    option.name === value.name
                }
                getOptionLabel={option => option.name}
                options={options}
                loading={loading}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={'Companies'}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <Fragment>
                                    {loading ? (
                                        <CircularProgress
                                            color={'inherit'}
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </Fragment>
                            ),
                        }}
                    />
                )}
            />
        );
    }
}

export default connect()(CompaniesAutocmpleteInput);
