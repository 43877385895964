import request from 'superagent';
import { Subject } from 'rxjs';

import {
    loaderDisplayChange,
    logoutDisplayChange,
    errorHandler,
    showSnackbar,
} from './shared-actions';
import { getCompany } from './companies-actions';
import history from '../history';

export const FETCH_USER = 'users/fetchedUser';
export const fetchedUser = user => ({
    type: FETCH_USER,
    payload: user,
});

export const LOGIN_ERROR_DISPLAY = 'login/loginErrorDisplay';
export const loginErrorDisplay = display => ({
    type: LOGIN_ERROR_DISPLAY,
    payload: display,
});

export const loginSubject = new Subject();
export const login = (username, password) => dispatch => {
    const obj = {
        username: username,
        password: password,
    };
    request
        .post(`/api/auth/login`)
        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .withCredentials()
        .send(obj)
        .then(res => {
            if (res.status === 200) {
                window.sessionStorage.setItem('companyId', res.body.companyId);

                dispatch(logoutDisplayChange('block'));
                dispatch(fetchedUser(res.body));
                dispatch(getCompany());
                loginSubject.next();
                history.push('/home');
            }
        })
        .catch(err => {
            dispatch(loaderDisplayChange('none'));
            dispatch(loginErrorDisplay('block'));
            setTimeout(function () {
                dispatch(loginErrorDisplay('none'));
            }, 5000);
            console.log('You have a login Error', err);
        });
};

export const getUser = id => dispatch => {
    dispatch(loaderDisplayChange('block'));
    request
        .get(`/api/users/${id}`)
        .set(`Content-type`, 'application/json')
        .then(res => {
            dispatch(fetchedUser(res.body));
            dispatch(loaderDisplayChange('none'));
        })
        .catch(err => console.log(err));
};

export const refreshUser = () => dispatch => {
    dispatch(loaderDisplayChange('block'));
    request
        .get(`/api/users`)
        .set(`Content-type`, 'application/json')
        .then(res => {
            dispatch(fetchedUser(res.body));
            dispatch(loaderDisplayChange('none'));
        })
        .catch(err => console.log(err));
};

//todo: refactor
export const createUser = (
    username,
    password,
    fName,
    lName,
    phone
) => dispatch => {
    let newUser = {
        username: username,
        firstName: fName,
        lastName: lName,
        phone: phone,
        password: password,
        role: 'guest',
        permissions: '',
        companyID: null,
    };
    return fetch('/api/connex/users/signup', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
        },
        body: JSON.stringify(newUser),
    })
        .then(res => {
            if (res.ok) {
                return request
                    .post('/api/connex/auth/login')
                    .set('Accept', 'application/json')
                    .set('Content-type', 'application/json')
                    .send(
                        JSON.stringify({
                            username: username,
                            password: password,
                        })
                    )
                    .then(res => {
                        dispatch(fetchedUser(res.body.user));
                    })
                    .then(() => history.push(`/home`))
                    .catch(err => console.log('error from login', err));
            } else {
                console.log(res.message);
            }
        })
        .catch(err => {
            alert(`${err} is your error!`);
            // dispatch(createUserError(err));
        });
};

export const UPDATE_MODAL_CONTENT = 'modal/updateModalContent';
export const updateModalContent = content => ({
    type: UPDATE_MODAL_CONTENT,
    payload: content,
});

export const TOGGLE_MODAL_DISPLAY = 'user/modalDisplay';
export const toggleModalDisplay = () => ({
    type: TOGGLE_MODAL_DISPLAY,
});

export const VIEW_USER = 'user/viewUser';
export const viewUser = user => ({
    type: VIEW_USER,
    payload: user,
});

export const FETCHED_DRIVERS = 'users/fetchedDrivers';
export const fetchedDrivers = drivers => ({
    type: FETCHED_DRIVERS,
    payload: drivers,
});

export const usersReceived = new Subject();
export const fetchUsers = (searchTerm, role, searchAll) => dispatch => {
    request
        .get(
            `/api/users/options?role=${role}&searchTerm=${searchTerm.toLowerCase()}${
                searchAll ? '&searchAll=true' : ''
            }`
        )

        .then(res => {
            if (res.ok) {
                dispatch(fetchedDrivers(res.body));
                usersReceived.next(res.body);
            } else {
            }
        })
        .catch(err => dispatch(errorHandler(err)));
};

export const updateUser = data => dispatch => {
    dispatch(loaderDisplayChange('block'));
    const { id, update } = data;
    return request
        .put(`/api/users/${id}`)
        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .send(update)
        .then(res => {
            if (res.ok) {
                dispatch(fetchedUser(res.body));
                dispatch(loaderDisplayChange('none'));
            }
        })
        .catch(e => dispatch(errorHandler(e)));
};

export const getPasswordResetEmail = email => dispatch => {
    dispatch(loaderDisplayChange('block'));
    request
        .get(`/api/auth/password/email?email=${email}`)
        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .then(res => {
            if (res.ok) {
                dispatch(loaderDisplayChange('none'));
                dispatch(
                    showSnackbar({
                        open: true,
                        severity: 'success',
                        message: res.body,
                    })
                );
            }
        })
        .catch(e => {
            dispatch(errorHandler(e));
        });
};

export const changePassword = update => dispatch => {
    dispatch(loaderDisplayChange('block'));
    const { id } = update;
    delete update._id;
    request
        .put(`/api/auth/password/${id}`)
        .set('Accept', 'application/json')
        .set('Content-type', 'application/json')
        .send(update)
        .then(res => {
            if (res.ok) {
                dispatch(loaderDisplayChange('none'));
                history.push('/login');
                dispatch(
                    showSnackbar({
                        open: true,
                        severity: 'success',
                        message: res.body,
                    })
                );
            }
        })
        .catch(e => {
            dispatch(errorHandler(e));
        });
};

export const imageChanged = new Subject();
