import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setUsername, firstName, lastName, phone } from '../../actions/index';
import { markCheckBox } from '../../actions/index3';
import PasswordBoxes from './password-boxes';
import './becomeMemberForm.css';

export class BecomeMemberForm extends Component {
    state = {
        display: 'none',
        showModal: () => this.setState({ display: 'block' }),
        showError: () => this.setState({ errorMessage: 'block' }),
        errorMessage: 'none',
    };

    render() {
        const modalStyle = {
            display: `${this.state.display}` /* Hidden by default */,
            position: 'fixed' /* Stay in place */,
            zIndex: '1' /* Sit on top */,
            paddingTop: '100px' /* Location of the box */,
            left: '0',
            top: '0',
            width: '100%' /* Full width */,
            height: '100%' /* Full height */,
            overflow: 'auto' /* Enable scroll if needed */,
            backgroundColor: 'rgba(0,0,0,0.9)' /* Black w/ opacity */,
        };
        const modalContent = {
            margin: 'auto',
            display: 'block',
            color: '#f1f1f1',
            textAlign: 'center',
        };
        const closeStyle = {
            position: 'absolute',
            top: '15px',
            right: '35px',
            color: '#f1f1f1',
            fontSize: '40px',
            fontWeight: 'bold',
        };

        let x = this.props;

        /*
        
            BRING BACK SUBMIT AND LINES 67 AND 71 WHEN READY TO GO LIVE
            FOR TESTING
            WILL ALSO HAVE TO IMPORT createUser()
        */
        // let submit = (a,b,c,d,e) => {
        // 	for ( let key in x){
        // 		console.log(key)
        // 		if(x[key] === null){
        // 		   return alert('Please fill in all fields.')
        // 		}
        // 	}
        // 	return x.dispatch(createUser(a,b,c,d,e))
        // }
        return (
            <div className="outter-box form">
                <form
                    className="sign-up-form"
                    onSubmit={e => {
                        // e.preventDefault()
                        const confirmed = window.confirm(
                            'Call 404-670-0059 if you would like to signup for our product!'
                        );
                        // submit(x.username,x.password,x.firstName,x.lastName,x.phone)
                        if (confirmed) {
                            window.location = '/';
                        }
                    }}>
                    <div className="pure-control-group row">
                        <div className="col-25">
                            <label htmlFor="email">Email</label>
                        </div>

                        <div className="col-75">
                            <input
                                type="text"
                                placeholder="abc@company.com"
                                className="email"
                                onChange={e => {
                                    x.dispatch(setUsername(e.target.value));
                                }}
                            />
                        </div>

                        <p>This will be your username.</p>
                    </div>

                    <div className="pure-control-group row">
                        <div className="col-25">
                            <label htmlFor="password">Password</label>
                        </div>
                        <div className="col-75">
                            <PasswordBoxes />
                        </div>
                    </div>

                    <div className="pure-control-group row">
                        <div className="col-25">
                            <label htmlFor="first-name">First Name</label>
                        </div>
                        <div className="col-75">
                            <input
                                id="first-name"
                                type="first-name"
                                placeholder="Juan"
                                onChange={e =>
                                    x.dispatch(firstName(e.target.value))
                                }
                            />
                        </div>
                    </div>

                    <div className="pure-control-group row">
                        <div className="col-25">
                            <label htmlFor="last-name">Last Name</label>
                        </div>
                        <input
                            id="last-name"
                            type="last-name"
                            placeholder="Johnson"
                            onChange={e => x.dispatch(lastName(e.target.value))}
                        />
                    </div>

                    <div className="pure-control-group row">
                        <div className="col-25">
                            <label htmlFor="phone">Phone</label>
                        </div>
                        <input
                            id="phone"
                            type="text"
                            placeholder="910-555-7683"
                            onChange={e => x.dispatch(phone(e.target.value))}
                        />
                    </div>

                    <div className="pure-controls  checkbox">
                        <input
                            id="cb"
                            type="checkbox"
                            onChange={() => x.dispatch(markCheckBox())}
                        />{' '}
                        <button onClick={() => this.state.showModal()}>
                            I've read the terms and conditions
                        </button>
                    </div>
                    <div className="submit-btn">
                        <button type="submit" className="all-buttons">
                            Submit
                        </button>
                    </div>
                </form>
                <div className="modal" style={modalStyle}>
                    <span>
                        <button
                            style={closeStyle}
                            className="close"
                            onClick={() => this.setState({ display: 'none' })}>
                            x
                        </button>
                    </span>
                    <h1 className="modal-content" style={modalContent}>
                        Terms and Conditions
                    </h1>
                    <h2 style={modalContent}>
                        You are now about to submit your personal information to
                        Connex. Some of it will be shared with other Connex
                        members. If you agree to these terms please close this
                        window and indicate that you have read these terms and
                        conditions and click submit.
                    </h2>
                </div>
            </div>
        );
    }
}

export const mapStateToProps = state => {
    return {
        username: state.dataReducer.username,
        firstName: state.dataReducer.firstName,
        lastName: state.dataReducer.lastName,
        password: state.dataReducer.password,
        confirmPassword: state.dataReducer.confirmPassword,
        phone: state.dataReducer.phone,
        checkbox: state.oneReducer.checkbox,
    };
};

export default connect(mapStateToProps)(BecomeMemberForm);
