import React, { Component } from 'react';
import PDF from 'react-pdf-js';
import { connect } from 'react-redux';

import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { viewUploadedBol } from '../../actions/bol-actions';
import BolsPreviewModal from './BolsPreviewModal';
import styles from './AddBols.module.css';
import '../welcome.css';

export class AddBOLs extends Component {
    state = {
        numPages: null,
        pageNumber: 1,
        file: {
            file: null,
            imagePreviewUrl: null,
        },
        modalOpen: false,
    };

    subscription = viewUploadedBol.subscribe({
        next: bol => {
            if (bol) {
                this.setState({ file: bol });
                return;
            }
            this.setState({
                numPages: null,
                pageNumber: 1,
                file: {
                    file: null,
                    imagePreviewUrl: null,
                },
                modalOpen: false,
            });
        },
    });

    _handleImageChange = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            let serializedFile = {
                lastModified: file.lastModified,
                lastModifiedDate: file.lastModifiedDate,
                name: `${this.props.numOfBols}-${file.name}`,
                size: file.size,
                type: file.type,
                webkitRelativePath: file.webkitRelativePath,
            };

            this.setState({
                file: {
                    file: serializedFile,
                    imagePreviewUrl: reader.result,
                },
            });
        };
        reader.readAsDataURL(file);
        document.querySelector('#file-input').value = '';
    };

    onDocumentComplete = numPages => {
        this.setState({ pageNumber: 1, numPages });
    };

    onPageComplete = pageNumber => {
        this.setState({ pageNumber });
    };

    handlePrevious = () => {
        this.setState({ pageNumber: this.state.pageNumber - 1 });
    };

    handleNext = () => {
        this.setState({ pageNumber: this.state.pageNumber + 1 });
    };

    renderPagination = (pageNumber, numPages) => {
        let previousButton = (
            <p
                className={'previous'}
                onClick={() =>
                    this.setState({ pageNumber: this.state.pageNumber - 1 })
                }>
                <i className={'fa fa-arrow-left'}></i> Previous
            </p>
        );
        if (this.state.pageNumber === 1) {
            previousButton = (
                <p className={'previous disabled'}>
                    <i className={'fa fa-arrow-left'}></i> Previous
                </p>
            );
        }
        let nextButton = (
            <p
                onClick={() =>
                    this.setState({ pageNumber: this.state.pageNumber + 1 })
                }
                className={'next'}>
                Next <i className={'fa fa-arrow-right'}></i>
            </p>
        );
        if (this.state.pageNumber === numPages) {
            nextButton = (
                <p className={'next disabled'}>
                    Next <i className="fa fa-arrow-right"></i>
                </p>
            );
        }
        return (
            <div className={styles['prev-next-btn-wrapper']}>
                {previousButton}
                {nextButton}
            </div>
        );
    };

    closeModal = () => this.setState({ modalOpen: false });

    onDocumentLoad({ numPages }) {
        this.setState({ numPages });
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    render() {
        const { viewOnly } = this.props;
        let pdf;
        let pagination = null;
        let pdfViewer;
        const input = document.getElementById('file-input');
        const buttonsClasses =
            'MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-106';
        if (this.state.file.imagePreviewUrl) {
            pdf = this.state.file.imagePreviewUrl;
            pagination = this.renderPagination(
                this.state.numPages,
                this.state.numPages
            );
            pdfViewer = (
                <PDF
                    file={pdf}
                    onDocumentComplete={this.onDocumentComplete}
                    onPageComplete={this.onPageComplete}
                    page={this.state.pageNumber}
                />
            );
        }
        return (
            <div className={'bol-box-form'}>
                <BolsPreviewModal
                    modalOpen={this.state.modalOpen}
                    closeModal={this.closeModal}
                    pdfViewer={
                        <div className={styles['modal-pdf-viewer']}>
                            {pdfViewer}
                            {pagination}
                        </div>
                    }
                />
                {!viewOnly && (
                    <>
                        <>
                            <Input
                                inputProps={{
                                    id: 'file-input',
                                    type: 'file',
                                    accept: '.pdf',
                                }}
                                onChange={this._handleImageChange}
                            />
                            <p>{this.state.numPages} pages</p>
                        </>
                        <div className={styles['submit-cancel-wrapper']}>
                            <Button
                                variant={'contained'}
                                disabled={!this.state.file.file}
                                className={`${buttonsClasses}`}
                                onClick={() => {
                                    //Todo: research the following.
                                    //todo: on the Step2 component, I had used
                                    //todo: it to remove the currentBolName...
                                    //todo: I noticed that there might be a bug...
                                    //todo: because a button was visible when I thought it
                                    //todo: should not be.
                                    input.value = '';
                                    this.props.closeBol();
                                    this.setState({
                                        file: {
                                            file: null,
                                            imagePreviewUrl: null,
                                        },
                                    });
                                }}>
                                Close
                            </Button>
                            <Button
                                variant={'contained'}
                                className={`${buttonsClasses}`}
                                disabled={!this.state.file.file}
                                onClick={() => {
                                    this.props.addBol(this.state.file);
                                    this.setState({
                                        numPages: null,
                                        pageNumber: 1,
                                        file: {
                                            file: null,
                                            imagePreviewUrl: null,
                                        },
                                        modalOpen: false,
                                    });
                                }}
                                startIcon={<CloudUploadIcon />}>
                                {`Add ${
                                    this.props.addAnother ? 'Another' : ''
                                }`}
                            </Button>
                        </div>
                    </>
                )}
                <h6>PDF Preview</h6>
                <div
                    className={'pdf-box'}
                    onClick={() => {
                        this.setState({ modalOpen: true });
                    }}>
                    {pdfViewer}
                </div>
            </div>
        );
    }
}
export const mapStateToProps = state => {
    return {
        img: state.oneReducer.img,
        user: state.dataReducer.user,
    };
};
export default connect(mapStateToProps)(AddBOLs);
