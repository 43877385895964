import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import styles from './Steps.module.css';
import { viewUploadedBol } from '../../../actions/bol-actions';
import { clearInput } from '../../../actions/shared-actions';
//todo: the location of this file should be outside of the WizardSteps dir on the level with the Wizard.js or moved into its own dir
export default function BolList({ bols, removeBol, classes, title }) {
    const styling = classes ? classes : '';
    const emptyBol = {
        file: null,
        imagePreviewUrl: null,
    };
    return (
        <div className={styling}>
            {title ? <h6>{title}</h6> : ''}
            {bols.map((bol, i) => {
                return (
                    <div
                        key={`${bol.file.name}-${i}`}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            const b = bols.find(
                                b => b.file.name === bol.file.name
                            );
                            viewUploadedBol.next(b);
                        }}
                        className={styles['bol-list-element']}>
                        <div>
                            <p className={styles['list-number']}>
                                {i + 1 + '.  '}
                            </p>
                        </div>
                        <div>
                            <p className={styles['bol-name']}>
                                {bol.file.name}
                            </p>
                        </div>
                        <IconButton
                            aria-label={'delete'}
                            onClick={e => {
                                e.stopPropagation();
                                removeBol(bol.file.name);
                                viewUploadedBol.next(emptyBol);
                                clearInput.next();
                            }}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                );
            })}
        </div>
    );
}
