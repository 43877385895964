import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DeliveryInfo from '../InfoComponents/DeliveryInfo';
import ReceiverInfo from '../InfoComponents/ReceiverInfo';
import PickUpInfo from '../InfoComponents/PickUpInfo';
import ShipperInfo from '../InfoComponents/ShipperInfo';
import UserInfo from '../InfoComponents/UserInfo';
import GridContainer from '../Grid/GridContainer';
import OSDView from '../Inbound/OSDView/OSDView';
import BolItem from '../BolListAndItem/BolItem';
import AddBols from '../PDFComponents/AddBols';
import AssignDriver from './AssignDriver';
import GridItem from '../Grid/GridItem';

import getLoadIdFromUrl from '../helpers/getLoadIdFromUrl';
import getLoadTypeFromUrl from '../helpers/getLoadTypeFromUrl';
import { H1, H2 } from '../helpers/Headings';

import { loaderDisplayChange } from '../../actions/shared-actions';
import { viewUploadedBol } from '../../actions/bol-actions';

import appStyles from '.././App.module.css';
import styles from './CarrierLoad.module.css';
import inboundStyle from './CarrierLoad.module.css';

function CarrierLoad(_props) {
    const [index, setIndex] = useState(-1);
    const [isShipping, setIsShipping] = useState('outbound');
    const [showOneBol, setShowOneBol] = useState(false);
    const [loads, setLoads] = useState([]);
    const [load, setLoad] = useState({});
    const [bols, setBols] = useState([]);
    const [driver, setDriver] = useState(null);
    const [pickUpCheckoutInfo, setPickUpCheckoutInfo] = useState('');
    const dispatch = useDispatch();

    const outbound = useSelector(state => state.loadsReducer.outbound);
    const completed = useSelector(state => state.loadsReducer.completed);
    const showAssignDriver = getLoadTypeFromUrl() !== 'completed';

    useEffect(() => {
        const loadType = getLoadTypeFromUrl();
        const loadId = getLoadIdFromUrl();
        setIsShipping(loadType === 'shipping');
        if (isShipping) {
            setLoads(outbound);
            setIndex(outbound.findIndex(l => l._id === loadId));
        } else {
            setLoads(completed);
            setIndex(completed.findIndex(l => l._id === loadId));
        }

        const loadBeingViewed = isShipping ? outbound[index] : completed[index];
        setLoad(loadBeingViewed);
        const hasDriver =
            loadBeingViewed &&
            loadBeingViewed.bols.every(b => b.driver.name != null);
        if (hasDriver) {
            const aDriver = loadBeingViewed.bols[0].driver;
            loadBeingViewed.bols.every(b => b.driver.name === aDriver.name);
            setDriver(aDriver);
        }
        setBols(loads[index]?.bols);
        dispatch(loaderDisplayChange('none'));
        // * added outbound and completed so that when we assign a driver to all it will update the state of this component otherwise it wont
    }, [loads, outbound, completed, driver]);

    useEffect(() => {
        if (load && load.bols && load.bols.length === 1) {
            setShowOneBol(true);
        }
    }, [load]);

    useEffect(() => {
        if (load && load.bols) {
            viewUploadedBol.next(load.bols[0]);
            setBols(load.bols);
            setPickUpCheckoutInfo(
                <PickUpCheckoutInfo bol={bols[0]} load={load} />
            );
        }
    }, [showOneBol]);

    return (
        <>
            <GridItem xs={12} align={'center'}>
                <H1
                    className={styles['no-margin-bottom']}
                    heading={`Load: ${
                        load && load._id && load._id.substring(18, 24)
                    }`}
                />
            </GridItem>
            <GridContainer wrap={'wrap-reverse'}>
                {!showOneBol && (
                    <GridItem
                        xs={12}
                        md={6}
                        align={'center'}
                        className={`${inboundStyle['grid-item']} ${inboundStyle['pdf-grid']}`}>
                        <BolItem bols={bols} />
                        {driver && (
                            <UserInfo user={driver} heading={'driver info'} />
                        )}
                        {showAssignDriver && (
                            <AssignDriver
                                bols={bols}
                                all={true}
                                changeDriver={driver !== null}
                                single={showOneBol}
                            />
                        )}
                    </GridItem>
                )}
                {showOneBol && (
                    <GridItem xs={12} md={6} align={'center'}>
                        <AddBols viewOnly={true} />
                        {showAssignDriver && (
                            <AssignDriver
                                bols={bols}
                                all={false}
                                changeDriver={driver !== null}
                                single={showOneBol}
                            />
                        )}
                    </GridItem>
                )}
                <GridItem
                    xs={12}
                    md={6}
                    align={'center'}
                    className={`${inboundStyle['grid-item']} ${inboundStyle['pdf-grid']}`}>
                    {showOneBol && <ReceiverInfo receiver={bols[0].receiver} />}
                    <ShipperInfo
                        shipper={loads[index] && loads[index].shipper}
                        creator={loads[index] && loads[index].creator}
                    />
                    {showOneBol && (
                        <>
                            {pickUpCheckoutInfo}
                            <UserInfo
                                user={bols[0].driver}
                                heading={'driver info'}
                            />
                            <OSDView
                                osd={
                                    bols[0].osd || {
                                        overages: [],
                                        shortages: [],
                                        damages: [],
                                    }
                                }
                                hideAdd={true}
                                open={true}
                                hideContentText={true}
                            />
                        </>
                    )}
                    {!showOneBol && load && (
                        <>
                            <H2
                                className={styles['no-margin-bottom']}
                                heading={'pick-up info'}
                            />
                            <hr className={appStyles.rule} />
                            <PickUpInfo load={load} />
                        </>
                    )}
                </GridItem>
            </GridContainer>
        </>
    );
}

function PickUpCheckoutInfo({ bol, load }) {
    return (
        <>
            <H2 heading={'bol info'} />
            <hr className={appStyles.rule} />
            {load.pUDate && <PickUpInfo load={load} />}
            <hr className={appStyles.rule} />
            {bol && <DeliveryInfo bol={bol} />}
        </>
    );
}

export default CarrierLoad;
