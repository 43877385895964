export default function getTableColumns() {
    return [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: 'Company Name',
            accessor: 'companyName',
        },
        {
            Header: 'Drop Date',
            accessor: 'dropDate',
        },
        {
            Header: 'P/U Number',
            accessor: 'pUNum',
        },
        {
            Header: 'P/U Date',
            accessor: 'pUDate',
        },
    ];
}
