import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import history from '../../history';
import CompanyProfile from './Tabs/CompanyProfile/CompanyProfile';
import Directory from './Tabs/Directory/Directory';
import Profile from './Tabs/Profile/Profile';

import styles from './Settings.module.css';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: -20,
    },
}));

const useAppBarStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#161f2c',
        alignItems: 'flex-end',
        marginBottom: 50,
    },
}));

export default function Settings() {
    const classes = useStyles();
    const appBarStyles = useAppBarStyles();
    const [value, setValue] = useState(0);
    const tabList = ['directory', 'my-profile', 'company-profile'];
    const handleChange = (_event, newValue) => {
        setValue(newValue);
        history.push(`/settings/${tabList[newValue]}`);
    };

    useEffect(() => {
        const { pathname } = window.location;
        tabList.forEach((tName, i) => {
            if (pathname.includes(tName)) {
                setValue(i);
            }
        });
    }, [value, tabList]);

    return (
        <div className={`${classes.root} ${styles.wrapper}`}>
            <AppBar className={appBarStyles.root} position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example">
                    <Tab label="directory" {...a11yProps(1)} />
                    <Tab label="my profile" {...a11yProps(0)} />
                    <Tab label="company profile" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            {value === 0 && <Directory />}
            {value === 1 && <Profile />}
            {value === 2 && <CompanyProfile />}
        </div>
    );
}
