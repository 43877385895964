import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import OSDInput from './OSDInput';
import OSDTable from './OSDTable';

import styles from './OSDView.module.css';

function OSDView(props) {
    const { open, closeOsdView, osd, getOsd, hideAdd, hideContentText } = props;
    const [overages, setOverages] = useState([]);
    const [shortages, setShortages] = useState([]);
    const [damages, setDamages] = useState([]);

    useEffect(() => {
        setOverages(osd.overages);
        setShortages(osd.shortages);
        setDamages(osd.damages);
    }, [osd]); //todo: investigate fix to remove warning associated with this code

    return (
        <div
            className={`${styles['content-wrapper']} ${
                !open ? styles.hide : ''
            }`}>
            <DialogContent>
                {!hideContentText && (
                    <StyledDialogContentText>
                        If there are any overages, shortages, or damages
                        associated with this load, please indicate that here.
                    </StyledDialogContentText>
                )}
                <div className={styles['input-wrapper']}>
                    <div>
                        <h4>Overages</h4>
                        <OSDInput
                            hideAdd={hideAdd}
                            type={'overages'}
                            update={o => setOverages([...overages, o])}
                        />
                        <OSDTable
                            showDelete={!hideAdd}
                            rowData={overages}
                            deleteRow={o =>
                                setOverages(
                                    overages.filter(itm => itm.sku !== o.sku)
                                )
                            }
                        />
                    </div>
                    <div>
                        <h4>Shortages</h4>
                        <OSDInput
                            hideAdd={hideAdd}
                            type={'shortages'}
                            update={s => setShortages([...shortages, s])}
                        />
                        <OSDTable
                            showDelete={!hideAdd}
                            rowData={shortages}
                            deleteRow={s =>
                                setShortages(
                                    shortages.filter(itm => itm.sku !== s.sku)
                                )
                            }
                        />
                    </div>
                    <div>
                        <h4>Damages</h4>
                        <OSDInput
                            hideAdd={hideAdd}
                            type={'damages'}
                            update={d => setDamages([...damages, d])}
                        />
                        <OSDTable
                            showDelete={!hideAdd}
                            rowData={damages}
                            deleteRow={d =>
                                setDamages(
                                    damages.filter(itm => itm.sku !== d.sku)
                                )
                            }
                        />
                    </div>
                </div>
            </DialogContent>
            {!hideAdd && (
                <StyledDialogAction>
                    <Button onClick={closeOsdView} color={'primary'}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            getOsd({ shortages, overages, damages });
                            closeOsdView();
                        }}
                        color={'primary'}>
                        Confirm OSD
                    </Button>
                </StyledDialogAction>
            )}
        </div>
    );
}

const StyledDialogContentText = withStyles({
    root: {
        marginLeft: '25px',
    },
})(DialogContentText);

const StyledDialogAction = withStyles({
    root: {
        marginRight: '35px',
    },
})(DialogActions);

const styledComponent = withStyles({
    paperWidthSm: {
        maxWidth: 'unset',
    },
})(OSDView);
export default styledComponent;
