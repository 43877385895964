import React from 'react';

import formatPhoneNumber from '../helpers/formatPhoneNumber';
import { H2, H3, H4 } from '../helpers/Headings';

import appStyles from '.././App.module.css';
import styles from './InfoComponents.module.css';

export default function ShipperInfo({ shipper, creator }) {
    return (
        <>
            <H2
                className={styles['no-margin-bottom']}
                heading={'shipper info'}
            />
            <hr className={appStyles.rule} />
            <H3 heading={shipper?.name} />
            <p>{formatPhoneNumber(shipper?.phone)}</p>
            <p>{shipper?.address.line1}</p>
            {shipper?.address.line2 && <p>shipper?.address.line2</p>}
            {shipper?.address.line3 && <p>shipper?.address.line3</p>}
            <p>
                {`${shipper?.address.city}, ${shipper?.address.state} ${shipper?.address.zip}`}
            </p>
            <H4 className={styles['no-margin-bottom']} heading={'contact'} />
            <p>{creator?.name}</p>
            <p>{creator?.email}</p>
        </>
    );
}
