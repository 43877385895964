import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { showSnackbar } from '../../actions/shared-actions';

function Alert(props) {
    return <MuiAlert elevation={6} variant={'filled'} {...props} />;
}

export default function SnackBar() {
    const dispatch = useDispatch();
    const { open, message, severity } = useSelector(
        state => state.sharedActionReducer.showSnackbar
    );
    const handleClose = () =>
        dispatch(showSnackbar({ open: false, message: '' }));
    return (
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert severity={severity}>{message}</Alert>
        </Snackbar>
    );
}
