import React from 'react';
import styles from './Footer.module.css';
import { Link } from 'react-router-dom';

const Footer = () => (
    <footer id="footer" className={styles.footer}>
        <div className={`${styles.child} ${styles['company-info']}`}>
            <h3 className={styles['child-header']}>Contact</h3>
            <p>
                Atlanta, GA
                <br />
                404-670-0059
                <br />
                <a className={styles.link} href="mailto:support@connexapp.com">
                    Email
                </a>
            </p>
        </div>
        <div className={`${styles.child} ${styles['social-media']}`}>
            <h3 className={styles['child-header']}>About</h3>
            <Link className={styles.link} to={'/FAQs'}>
                FAQs
            </Link>
        </div>
        <div className={styles.child}>
            <h3 className={styles['child-header']}>Resources</h3>
            <p>Coming Soon!</p>
        </div>
    </footer>
);

export default Footer;
