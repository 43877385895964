import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { enterPass, enterName } from './../actions';
import { login } from './../actions/user-actions';
import { loaderDisplayChange } from './../actions/shared-actions';
import './login-page.css';

class Login extends React.Component {
    state = {
        showChangePassword: false,
    };
    toggleShowChangePassword = () =>
        this.setState({ showChangePassword: !this.state.showChangePassword });
    render() {
        let x = this.props;
        const { dispatch } = this.props;
        let errStyle = {
            display: x.loginErrorDisplay,
            color: 'red',
        };
        return (
            <div className="login-box">
                <form
                    className="form"
                    onSubmit={e => {
                        e.preventDefault();
                        dispatch(loaderDisplayChange('block'));
                        dispatch(login(x.enterUS, x.enterPass));
                    }}>
                    <div className="form-group row">
                        <div className="col-25">
                            <label htmlFor="email" className="login-label">
                                Email:{' '}
                            </label>
                        </div>

                        <div className="col-75">
                            <input
                                type="email"
                                className="login-input"
                                id="email"
                                placeholder="Enter email"
                                onChange={e => {
                                    x.dispatch(enterName(e.target.value));
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-25">
                            <label htmlFor="pwd" className="login-label">
                                Password:{' '}
                            </label>
                        </div>
                        <div className="col-75">
                            <input
                                type="password"
                                className="login-input"
                                id="pwd"
                                placeholder="Enter password"
                                onChange={e =>
                                    x.dispatch(enterPass(e.target.value))
                                }
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="btn btn-default all-buttons">
                            Submit
                        </button>
                    </div>
                </form>
                <Link className={'forgot-password'} to={'/forgot-password'}>
                    Forgot Username/Passowrd
                </Link>
                <p className="login-err" style={errStyle}>
                    Please enter a valid username/password
                </p>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        enterPass: state.dataReducer.enterPass,
        enterUS: state.dataReducer.enterUS,
        user: state.dataReducer.user,
        jwt: state.dataReducer.jwt,
        loginErrorDisplay: state.userReducer.loginErrorDisplay,
    };
};
export default connect(mapStateToProps)(Login);
