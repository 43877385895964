import React from 'react';
import DateAndTime from './DateAndTime';
import {
    formatDate,
    isOnTime,
    lateDiff,
    getOnTimeMessage,
} from './infoHelpers';

import styles from './InfoComponents.module.css';

export default function DeliveryInfo({ bol }) {
    let checkin, checkout, late, onTime, dropDate;
    if (bol) {
        checkin = bol.checkin ? formatDate(bol.checkin) : '';
        checkout = bol.checkout ? formatDate(bol.checkout) : '';

        onTime = isOnTime(bol.dropDate, bol.checkin);
        late = lateDiff(bol.dropDate, bol.checkin);
        dropDate = bol.dropDate ? formatDate(bol.dropDate) : '';
    } else {
        checkin = '';
        checkout = '';
        dropDate = '';
    }

    //TODO: Add detention, should be calculated on backend
    return (
        <>
            <DateAndTime text={'Delivery Date -- Time'} time={dropDate} />
            <p className={onTime ? styles['on-time'] : styles.late}>
                {getOnTimeMessage(onTime, late)}
                Delivery
            </p>
            <DateAndTime text={'Delivery Check-In'} time={checkin} />
            <DateAndTime text={'Delivery Check Out'} time={checkout} />
        </>
    );
}
