import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import customSelectStyle from '../../customSelectStyle';
import stepsStyles from './Steps.module.css';
import CompanyDetailView from '../../CompanyDetailView';
import {
    loadBeingEdited,
    setBolsAndCarrierSelected,
} from '../../../actions/loads-actions';
import {
    getAllCompanies,
    companiesReceived,
    getShprRcvrCrrsByIds,
} from '../../../actions/companies-actions';

class Step3 extends Component {
    state = {
        // * Part of this component was created by creative tim
        //todo: investigate why some of this state is here
        simpleSelect: '',
        desgin: false,
        code: false,
        develop: false,
        open: false,
        selectedCompany: null,
        options: [],
        searchableText: '',
        loading: false,
    };

    subscription1 = companiesReceived.subscribe({
        next: options =>
            this.setState({
                //! I am sure there is a more elegant solution
                //todo: find a more elegant solution than filtering.
                // * what's happening is that since this is a subscription
                // * we end up seeing results that aren't supposed to be in
                // * our options.
                options: options.filter(o => o.type === 0),
                loading: false,
            }),
    });

    subscription2 = loadBeingEdited.subscribe({
        next: async load => {
            if (load.carrierId == null) {
                this.setState({ selectedCompany: null });
                this.props.dispatch(setBolsAndCarrierSelected(null, null));
                return;
            }
            await getShprRcvrCrrsByIds(load.carrierId).then(carrier => {
                this.setState({ selectedCompany: carrier[0] });
                this.props.dispatch(
                    setBolsAndCarrierSelected(null, carrier[0])
                );
            });
        },
    });

    sendState() {
        return this.state;
    }

    isValidated() {
        return true;
    }

    fetchCompanies = () => {
        const searchText = this.state.searchableText;
        if (!searchText) return this.setState({ options: [], loading: false });
        this.props.dispatch(getAllCompanies(searchText, 0));
    };

    componentDidMount() {
        const input = fromEvent(
            document.getElementById('async-input-2'),
            'keyup'
        );
        const searchText = input.pipe(debounceTime(500));
        searchText.subscribe(e => {
            this.setState({
                open: true,
                loading: true,
                searchableText: e.target.value,
                options: [],
            });
            this.fetchCompanies();
        });
    }

    componentWillUnmount() {
        this.subscription1.unsubscribe();
        this.subscription2.unsubscribe();
    }

    render() {
        const { open, options, loading } = this.state;
        const { classes } = this.props;
        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                    <h4 className={classes.infoText}>
                        Who is carrying this load?
                    </h4>
                </GridItem>
                <GridItem xs={12} sm={7} className={stepsStyles['text-center']}>
                    <Autocomplete
                        id={'async-input-2'}
                        style={{ width: 300, margin: 'auto' }}
                        open={open}
                        onOpen={() => {
                            this.setState({ open: true });
                        }}
                        onChange={(e, selectedCompany) => {
                            e.stopPropagation();
                            this.setState({ selectedCompany });
                            this.props.dispatch(
                                setBolsAndCarrierSelected(null, selectedCompany)
                            );
                        }}
                        onClose={() => {
                            this.setState({ open: false });
                        }}
                        getOptionSelected={(option, value) =>
                            option.name === value.name
                        }
                        getOptionLabel={option => option.name}
                        options={options}
                        loading={loading}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={'Carriers'}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {loading ? (
                                                <CircularProgress
                                                    color={'inherit'}
                                                    size={20}
                                                />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />

                    <CompanyDetailView
                        companySelected={this.state.selectedCompany}
                    />
                </GridItem>
            </GridContainer>
        );
    }
}
const style = {
    infoText: {
        fontWeight: '300',
        margin: '10px 0 30px',
        textAlign: 'center',
    },
    ...customSelectStyle,
};

Step3.propTypes = {
    classes: PropTypes.object,
};
const styledStep3 = withStyles(style)(Step3);
export default connect()(styledStep3);
