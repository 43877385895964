import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { togglePicModal } from '../../actions/index16';
import './carrier.css';

export class CarrierSignUp extends Component {
    state = {
        content: '',
    };

    render() {
        return (
            <div className="outter-box">
                <div className="instructions">
                    <p>
                        As a Connex carrier you will be connected to your Connex
                        drivers, Connex shippers and your drivers' BOLs in real
                        time. You are granted read privelages to all of the BOLs
                        shared with your drivers by their respective Connex
                        shippers. This means that when your driver gets the BOLs
                        you do too! Plus, they are always safe from loss and
                        readly accessable anytime you need them! Never loose a
                        BOL again. You will also be able to update your list of
                        drivers, i.e., adding newly hired drivers to your team
                        as well as updating select information about them such
                        as their truck and trailer number.
                    </p>
                </div>
                <div className="instructions">
                    <img
                        src='https://connex-imgs.s3.amazonaws.com/connexapp-images/create-carrier.png'
                        className="link"
                        alt="create carrier pic"
                        onClick={() => {
                            this.props.dispatch(togglePicModal());
                            this.setState({
                                content: (
                                    <img src='https://connex-imgs.s3.amazonaws.com/connexapp-images/create-carrier.png' alt="create carrier pic" />
                                ),
                            });
                        }}
                    />
                </div>
                <div className="instructions">
                    <p>
                        To be a Connex carrier you must first be a valid Connex
                        user. if you are not already a valid user we'll walk you
                        through that later but for now let's talk about what to
                        expect on the inside.
                    </p>
                    <Link to="/carriersignup2">
                        <p style={{ marginLeft: 0 }}>Continue</p>
                    </Link>
                </div>
            </div>
        );
    }
}

export default connect()(CarrierSignUp);
