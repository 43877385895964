import React, { Component } from 'react';
import PDF from 'react-pdf-js';
import { connect } from 'react-redux';

import BolsPreviewModal from './BolsPreviewModal';
import styles from './AddBols.module.css';
import '../welcome.css';

export class InboundViewer extends Component {
    state = {
        numPages: null,
        pageNumber: 1,
        file: {
            file: null,
            imagePreviewUrl: null,
        },
        modalOpen: false,
    };

    componentDidMount() {
        const { load } = this.props;
        this.setState({
            file: {
                file: load && load.file,
                imagePreviewUrl: load && load.imagePreviewUrl,
            },
        });
    }

    onDocumentComplete = numPages => {
        this.setState({ pageNumber: 1, numPages });
    };

    onPageComplete = pageNumber => {
        this.setState({ pageNumber });
    };

    handlePrevious = () => {
        this.setState({ pageNumber: this.state.pageNumber - 1 });
    };

    handleNext = () => {
        this.setState({ pageNumber: this.state.pageNumber + 1 });
    };

    renderPagination = (f, numPages) => {
        let previousButton = (
            <p
                className={'previous'}
                onClick={e => {
                    e.stopPropagation();
                    this.setState({ pageNumber: this.state.pageNumber - 1 });
                }}>
                <i className={'fa fa-arrow-left'}></i> Previous
            </p>
        );
        if (this.state.pageNumber === 1) {
            previousButton = (
                <p className={'previous disabled'}>
                    <i className={'fa fa-arrow-left'}></i> Previous
                </p>
            );
        }
        let nextButton = (
            <p
                onClick={e => {
                    e.stopPropagation();
                    this.setState({ pageNumber: this.state.pageNumber + 1 });
                }}
                className={'next'}>
                Next <i className={'fa fa-arrow-right'}></i>
            </p>
        );
        if (this.state.pageNumber === numPages) {
            nextButton = (
                <p className={'next disabled'}>
                    Next <i className="fa fa-arrow-right"></i>
                </p>
            );
        }
        return (
            <div className={styles['prev-next-btn-wrapper']}>
                {previousButton}
                {nextButton}
            </div>
        );
    };

    closeModal = () => this.setState({ modalOpen: false });

    onDocumentLoad({ numPages }) {
        this.setState({ numPages });
    }

    render() {
        let pdf;
        let pagination = null;
        let pdfViewer;
        const { load } = this.props;
        if (load && load.imagePreviewUrl) {
            pdf = load && load.imagePreviewUrl;
            pagination = this.renderPagination(
                this.state.pageNumber,
                this.state.numPages
            );
            pdfViewer = (
                <div
                    onClick={() => {
                        this.setState({ modalOpen: true });
                    }}>
                    <PDF
                        file={pdf}
                        onDocumentComplete={this.onDocumentComplete}
                        onPageComplete={this.onPageComplete}
                        page={this.state.pageNumber}
                    />
                </div>
            );
        }
        return (
            <div className={'bol-box-form'}>
                <BolsPreviewModal
                    modalOpen={this.state.modalOpen}
                    closeModal={this.closeModal}
                    pdfViewer={
                        <div className={styles['modal-pdf-viewer']}>
                            {pdfViewer}
                            {pagination}
                        </div>
                    }
                />
                <div className={'pdf-box'}>
                    {pdfViewer}
                    {pagination}
                    <p>
                        {this.state.pageNumber} of {this.state.numPages} pages
                    </p>
                </div>
            </div>
        );
    }
}

export const mapStateToProps = state => {
    return {
        jwt: state.dataReducer.jwt,
        img: state.oneReducer.img,
        user: state.dataReducer.user,
    };
};
export default connect(mapStateToProps)(InboundViewer);
