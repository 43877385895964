import React from 'react';
import { connect } from 'react-redux';
import styles from './GlobalModal.module.css';
import { toggleModalDisplay } from '../../actions/user-actions';

export class GlobalModal extends React.Component {
    render() {
        let x = this.props;
        const modalStyle = {
            display: `${x.togglePicModal}`,
        };

        return (
            <div className={styles['all-use-modal']} style={modalStyle}>
                <p
                    onClick={() => {
                        x.dispatch(toggleModalDisplay());
                    }}
                    className={styles['modal-content']}>
                    close
                </p>

                <div className={styles['content-wrapper']}>{x.content}</div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        togglePicModal: state.userReducer.modalDisplay,
        content: state.userReducer.content,
    };
};

export default connect(mapStateToProps)(GlobalModal);
