import React from 'react';
import {connect} from 'react-redux'; 
// import DriverLoadInfo from './driverLoadInfo';
// import BOLsInfo from './bolsInfo';
// import {Link} from 'react-router-dom';
import './driver.css'
import avatar from './linkedin-default.png'

export class DriverInfo extends React.Component{
    
    render() {
        let x = this.props;
        let data;
        let truckCompany;
        let driverInfo;
        let imagePreviewUrl;
        if (x.info){
            if  ( x.info.driverLicensePic ) {
                imagePreviewUrl =  x.info.driverLicensePic.imagePreviewUrl;
            }else{imagePreviewUrl = avatar}

            
            driverInfo =
            <div>
            <h2>{x.info.accountName}</h2>
            <h3>Driver Info</h3>
            <ul>
                <li>Connex Driver Id: {x.info._id}</li>
                <li>Phone: {x.info.userId.phone}</li>
                <li>Email: {x.info.userId.username}</li>
                <li>Truck #: {x.info.truckNum}</li>
                <li>Trailer #: {x.info.trlNum}</li>
            </ul>
            </div>
            if (x.info.carrierId) {
               truckCompany = 
                <div className='truck-company-info'>
                    <h3>Carrier Info</h3>
                    <ul>
                        <li>{x.info.carrierId.carrierName}</li>
                        <li>{x.info.carrierId.phone}</li>
                        <li>{x.info.carrierId.email}</li>
                    </ul>
                </div> 
            }
            else{  truckCompany = <h5>No Carrier Info Available</h5>}
        }
        
        let $imagePreview = null;
        if (imagePreviewUrl) {
          $imagePreview = (<img alt='file to upload' src={avatar} />);
        
            data = 
            <div className='driver-info'>
                
                {$imagePreview} 
            </div>
        }
        return (
            <div className='driver-info-box'>
                {driverInfo}
                {data}
                {truckCompany}
                
            </div>
        )    
    }

   

}
export const mapStateToProps = state => {
    return { 
        info:state.fourReducer.mySelectedDriver
    }
}
export default connect(mapStateToProps)(DriverInfo)
