export const SET_PASSWORD = 'SET_PASSWORD';
export const setPassword = password => ({
    type: SET_PASSWORD,
    password,
});

export const SET_CONFIRM_PASSWORD = 'SET_CONFIRM_PASSWORD';
export const setConfirmPass = confirmPass => ({
    type: SET_CONFIRM_PASSWORD,
    confirmPass,
});

export const VALIDATE = 'VALIDATE';
export const validate = val => ({
    type: VALIDATE,
    val,
});

export const VALUE = 'VALUE';
export const value = num => ({
    type: VALUE,
    num,
});

export const VALID = 'VALID';
export const valid = (q, b) => ({
    type: VALID,
    q,
    b,
});

export const SET_USERNAME = 'SET_USERNAME';
export const setUsername = username => ({
    type: SET_USERNAME,
    username,
});

export const FIRSTNAME = 'FIRSTNAME';
export const firstName = fName => ({
    type: FIRSTNAME,
    fName,
});

export const LASTNAME = 'LASTNAME';
export const lastName = lName => ({
    type: LASTNAME,
    lName,
});

export const PHONE = 'PHONE';
export const phone = phone => ({
    type: PHONE,
    phone,
});

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const createUserRequest = () => ({
    type: CREATE_USER_REQUEST,
});

export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const createUserSuccess = user => ({
    type: CREATE_USER_SUCCESS,
    user,
});

export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const createUserError = error => ({
    type: CREATE_USER_ERROR,
    error,
});

export const ENTER_PASS = 'ENTER_PASS';
export const enterPass = pass => ({
    type: ENTER_PASS,
    pass,
});

export const SET_USER = 'SET_USER';
export const setUser = user => ({
    type: SET_USER,
    user,
});

export const ENTER_NAME = 'ENTER_NAME';
export const enterName = name => ({
    type: ENTER_NAME,
    name,
});
