import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { Provider } from 'react-redux';
import { store, presistor } from './store';
import Loader from './components/loader';

ReactDOM.render(
    <Provider store={store}>
        <PersistGate
            loading={<Loader isPresisting={true} />}
            persistor={presistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
