import React from 'react';

export default function DateAndTime({ text, time }) {
    return (
        <p>
            <strong>{text}: </strong>
            {time}
        </p>
    );
}
