import React, { Component } from 'react';
import { connect } from 'react-redux';

import ImageUpload from '../../ImageUpload/ImageUpload';
import CompanyInfo from '../../../InfoComponents/CompanyInfo';
class CompanyProfile extends Component {
    render() {
        const { company, user } = this.props;
        return (
            <>
                <ImageUpload hideUpload={user.role !== 1} />
                <CompanyInfo
                    type={'company'}
                    company={company}
                    canEdit={user.role === 1}
                />
            </>
        );
    }
}

const mapStateToProps = state => ({
    company: state.companiesReducer.companyWithAllData,
    user: state.userReducer.user,
});
export default connect(mapStateToProps)(CompanyProfile);
